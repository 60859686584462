import { CreateBookInsightsHeader, CreateBookInsightsLayout } from "../../../components/BookInsights";
import { CreateCheatsBody } from "../../../components/Cheats/CreateCheatsBody";

export const CreateCheats = () => {
    return (
        <CreateBookInsightsLayout>
            <CreateBookInsightsHeader title='Create Cheat' />

            <CreateCheatsBody
                defaultContent={ {} }
                defaultSidebarValues={ {} }
                purpose='add'
            />
        </CreateBookInsightsLayout>
    )
}