export const convertUsersDateString = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const convertedDate: string = date.toLocaleDateString('ru-RU', options);
  return convertedDate;
};

export const convertNewsDateString = (dateString: string): string => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  return formattedDate.replaceAll(',', '').replaceAll('/', '.');
};

export const convertDateToDottedValue = (dateString: string): string => {
  const date = new Date(dateString);
  const formattedDate = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}`;
  return formattedDate;
};

export const isDateInPast = (dateString: string): boolean => {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  return inputDate < currentDate;
};

export const isDateInFuture = (dateString: string): boolean => {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  return inputDate > currentDate;
};

export const calculateDaysBetweenDates = (
  start_date?: string,
  end_date?: string,
): number => {
  if (!start_date || !end_date) return 0;
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
};

export const getDateAfterDays = (
  date: string,
  numberOfDays: number,
): string => {
  if (!date) return '';
  // returns next day. ex: 2023-08-05 -> 2023-08-06
  const dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() + numberOfDays);
  const nextDay = dateObj.toISOString().slice(0, 10);
  return nextDay;
};

export const prepareNewsDateFormat = (dateString: string): string => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  const convertedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return convertedDate;
};
