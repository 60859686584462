import { CreateBookInsightsHeader, CreateBookInsightsLayout } from "../../../components/BookInsights";
import { CreateBookInsightsBody } from "../../../components/BookInsights/CreateBookInsightsBody";
import { CreateCheatsBody } from "../../../components/Cheats/CreateCheatsBody";
import { useLocation } from "react-router-dom";
import { useGetCheat } from "../../../hooks";
import { useMemo } from "react";

export const EditCheats = () => {
    const location = useLocation()
    const match = location.pathname.match(/\d+/);
    const getCheatId = match ? parseInt(match[0], 10) : 0;
    const {data: cheatItem} = useGetCheat(getCheatId)

    const defaultSidebarValues = useMemo(() => {
        return {
            id: cheatItem?.id,
            image: cheatItem?.image,
            title: cheatItem?.title,
            workspace_id: cheatItem?.workspace_id,
            workspace: cheatItem?.workspace
        };
    }, [cheatItem]);

    return (
        <CreateBookInsightsLayout>

            <CreateBookInsightsHeader title={'Edit Cheats'}/>

            <CreateCheatsBody
                defaultContent={[]}
                defaultSidebarValues={defaultSidebarValues}
                purpose={'edit'}
                id={getCheatId}
            />

        </CreateBookInsightsLayout>
    )
}