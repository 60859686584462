import { CSSProperties, FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledFormSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  height: '92%',
  position: 'relative',
  // height: 'calc(100vh - 11rem)',
  overflowY: 'auto',
  color: '#ffffff',
  '& h2': {
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    fontWeight: 600,
    margin: '0 0 -0.5rem 0',
  },
  '& .MuiTextField-root': {
    width: '100%',
  },
});

export const StyledFlexBox = styled(Box)({
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',

  '& > input': {
    color: 'red',
    background: 'red'
  },
  '&.fullDivWidth': {
    '& > div': {
      width: '100%',
    },
  },
  '& > svg': {
    cursor: 'pointer',
  },
  '& .switch': {
    // marginTop: '-0.5rem',
  },
  '&.buttons': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    // width: 'calc(100% - 3.75rem)',
    bottom: '0',
  },
  '& > button': {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  '& .plusIcon': {
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
    borderRadius: '0.25rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 128, 255, 0.1)',
    },
  },
});

interface IDrawerFormBodyProps {
  children: React.ReactNode;
  style?: CSSProperties;
}

export const DrawerFormBody: FC<IDrawerFormBodyProps> = ({
  children,
  style,
}) => {
  return <StyledFormSection style={style}>{children}</StyledFormSection>;
};
