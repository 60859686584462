import { StyledSidebarSelect } from "./styledComponents";
import { FilterSelectArrowIcon } from "../../../assets";
import { useEffect, useRef, useState } from "react";
import { useLocales } from "../../../hooks/useLocales";
import { LocalesProps } from "../../../types";
import { useDispatch } from "react-redux";
import { changeLocale } from "../../../redux";
import { useQueryClient } from "react-query";
export const SideBarSelect = () => {
    const ref: any = useRef();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isOpen, setIsOpen] = useState(false)
    const [langId, setLangId] = useState()
    const [selectedLang, setSelectedLang] = useState<any>('');

    const {data: locales} = useLocales();

    const handleChangeLang = (item: any) => {
        setSelectedLang(item)

        localStorage.setItem('lang', item.id)
        localStorage.setItem('slug', item.slug)

        dispatch(changeLocale({locale: item.id, slug: item.slug}))
        setIsOpen(false)
        queryClient.invalidateQueries('workspace_details')
        queryClient.invalidateQueries('insights')
    }

    useEffect(() => {
        const lang: any =  localStorage.getItem('lang')
        setLangId(lang)
    }, [])

    useEffect(() => {
        const getLocale = locales && locales.find((item: any) => item.id == langId)

        setSelectedLang(getLocale)
    }, [langId, locales])

    const handleOpenDropdown = (event: any) => {
        event.stopPropagation();
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref]);

    return (
        <StyledSidebarSelect>
            <div className={`content ${isOpen ? 'active' : ''}`}
                 onClick={handleOpenDropdown}
            >
                <div className='leftSide'>
                    {selectedLang?.image &&  <img src={selectedLang?.image} alt={'avatar'} className='image'/>}
                    {!selectedLang?.image && selectedLang?.name && <div className={'noneImage'}>{selectedLang?.name[0]}</div>}

                    <p className='text'>{selectedLang ? selectedLang?.name : ''}</p>
                </div>

                <FilterSelectArrowIcon/>
            </div>

            {isOpen && (
                <div ref={ref} className='dropdown'>
                    {locales && locales.map((item: LocalesProps, idx) => (
                        <div key={idx} className={`dropdown__block ${item.id === selectedLang?.id ? 'active' : ''}`} onClick={() => handleChangeLang(item)}>
                            {item.image &&  <img src={item?.image} className='image' alt={'avatar'}/>}
                            {!item.image && selectedLang.name && <div className={'noneImage'}>{item.name[0]}</div>}

                            <p className='text'>{item.name}</p>
                        </div>
                    ))}
                </div>
            )}
        </StyledSidebarSelect>
    )
}