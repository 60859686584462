import {
  StyledNewsName,
  StyledPaper,
  StyledTable,
  StyledTableContainer, StyledTableRow
} from "../../BookInsights/BookInsightsTable/styledComponents";
import { LocalesTableHead } from "./LocalesTableHead";
import { TableBody, TableCell } from "@mui/material";
import { useDeleteLocale, useLocales, useUpdateLocale } from "../../../hooks/useLocales";
import { StyledSwitch, TableCardSkeleton } from "../../../UI";
import { EditIcon, DeleteIcon } from "../../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalesProps } from "../../../types";
import { FC, useEffect, useState } from "react";
import { StyledAvatar, StyledNewsInfo } from "../../Admins/AdminsTable/styledComponents";
import { DeleteModal } from "../../DeleteModal";

interface ILocalesTable {
  locales: LocalesProps[],
  isLoading: boolean,
}

export const LocalesTable: FC<ILocalesTable> = ({
  locales,
  isLoading
}) => {
  const location: any = useLocation();
  const navigate = useNavigate();

  const [ selectedItem, setSelectedItem ] = useState<any>({})
  const [ openDeleteModal, setOpenDeleteModal ] = useState<boolean>(false);

  const {mutateAsync: deleteLocale} = useDeleteLocale();
  const {mutateAsync: updateLocale} = useUpdateLocale();

  const [ isActiveList, setIsActiveList ] = useState<any[]>([])

  const openEditDrawer = (id: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    navigate(`?${ searchParams.toString() }`);
  };

  const handleDeleteWorkspace = (id: any) => {
    deleteLocale(id)
      .then(() => {

      })
      .catch(() => {
      });
  };

  const deleteButtonHandler = (row: any) => {
    setOpenDeleteModal(true);
    setSelectedItem(row)
  };

  const changeActiveStatus = (id: number) => {
    const updatedList = isActiveList?.map((
        item,
        idx
      ) =>
        idx == id ? {...item, is_active: item.is_active === 1 ? 0 : 1} : item
    )

    const requestData: any = locales?.map((item, idx) =>
      idx == id ? {...item, is_active: item.is_active === 1 ? 0 : 1 } : item
    )

    setIsActiveList(updatedList)

    updateLocale(requestData[id])
      .then(() => {
      })
      .catch(() => {
      });
  }

  useEffect(() => {
    const updatedList = locales?.map((
      item,
      idx
    ) => {
      return {
        number: idx,
        is_active: item?.is_active
      }
    })

    setIsActiveList(updatedList)
  }, [ locales ])

  useEffect(() => {
    const updatedList: any = locales?.map((
      item,
      idx
    ) => {
      const is_active_value = isActiveList?.find((item) => item.number === idx)?.is_active

      return {
        ...item,
        is_active: is_active_value
      }
    })


  }, [ isActiveList ])


  return (
    <StyledPaper>
      <DeleteModal
        open={ openDeleteModal }
        title='Delete Locale'
        description='Are you sure to delete locale?'
        onConfirm={ () => {
          handleDeleteWorkspace(selectedItem?.id);
          setOpenDeleteModal(false)
        } }
        onClose={ () => setOpenDeleteModal(false) }
      >
      </DeleteModal>

      <StyledTableContainer>
        <StyledTable aria-labelledby='newsTable' size='medium' stickyHeader>
          <LocalesTableHead />

          <TableBody>

            { isLoading && [ 1, 2, 3, 5, 6, 7, 8, 9, 10 ].map((
              item,
              idx
            ) => (
              <StyledTableRow hover key={ idx }>
                <TableCell style={ {width: '100%'} }>
                  <TableCardSkeleton />
                </TableCell>

                <TableCell>
                  <TableCardSkeleton />
                </TableCell>
              </StyledTableRow>
            )) }

            { locales && locales.map((
              item,
              idx
            ) => {
              const is_active_value = isActiveList?.find((item) => item.number == idx)?.is_active

              return (
                <StyledTableRow hover key={ idx }>
                  <TableCell
                    style={ {width: '100%'} }
                    component='th'
                    id={ item.id ? item.id.toString() : '0' }
                    scope='row'
                  >
                    <StyledNewsInfo>
                      <StyledAvatar
                        onClick={ () =>
                          ''
                        }
                      >
                        { !item.image ?
                          (
                            <div className={ 'noneImageLocales' } style={ {} }>
                              { item.name[0] }
                            </div>) :
                          (
                            <div className={ 'image' }>
                              <img src={ item.image } alt="" style={ {
                                width: '2.188rem',
                                height: '2.188rem',
                                minWidth: '2.188rem',
                                minHeight: '2.188rem',
                              } } />
                            </div>
                          ) }
                      </StyledAvatar>
                      <StyledNewsName
                        onClick={ () => '' }
                      >
                        { item.name }
                      </StyledNewsName>
                    </StyledNewsInfo>
                  </TableCell>

                  <TableCell>
                    <div className='editDeleteIcons'>
                      <div className={ 'toggle' }>
                        <StyledSwitch
                          checked={ is_active_value === 1 }
                          onChange={ () => changeActiveStatus(idx) }
                        />
                      </div>

                      <EditIcon
                        style={ {marginRight: '12px'} }
                        color={ '#ffffff' }
                        onClick={ () =>
                          openEditDrawer(item.id ? item.id : 0)
                        }
                      />
                      <DeleteIcon
                        color={ '#ffffff' }
                        onClick={ () => deleteButtonHandler(item) }
                      />
                    </div>
                  </TableCell>
                </StyledTableRow>
              )
            }) }
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </StyledPaper>
  )
}