import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { localesReducer, workspacesReducer } from "./reducers";

export * from './reducers'
export * from './store'

export const store = configureStore({
    reducer: {
        locales: localesReducer,
        workspaces: workspacesReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector