import { createTheme } from '@mui/material/styles';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#2CB5B4',
      dark: '#2CB5B4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#EB5858',
      dark: '#a43d3d',
      light: '#ef7979',
      contrastText: '#ffffff',
    },
    customDarkBlue: {
      main: '#283239',
      dark: 'rgba(255, 255, 255, 0.2)',
      contrastText: '#ffffff',
    },
    customGray: {
      main: '#F5F5F5',
      dark: '#d5d5d5',
      light: '#f7f7f7',
      contrastText: '#000000',
    },
    customBlack: {
      main: '#000000',
      contrastText: '#ffffff',
      light: '#333333',
    },
    customWhite: {
      main: '#ffffff',
      contrastText: '#000000',
      dark: 'red',
    },
    customDarkerGray: {
      main: 'rgba(255, 255, 255, 0.05)',
      dark: 'rgba(255, 255, 255, 0.07)',
      contrastText: '#ffffff',
    },
    customLightBlue: {
      main: '#2CB5B4',
      dark: 'red',
      contrastText: 'black',
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif',
  },
});
