import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { COLORS } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../../UI";
import { FC } from "react";
import { LeftArrowIcon } from "../../../assets";


const StyledLayout = styled(Box)({
    width: '100%',
    display: 'flex',
    gap: '1.25rem',
    alignItems: 'center',
    marginBottom: '1.8125rem',
});

const StyledButton = styled(Box)({
    width: '3.125rem',
    height: '3.125rem',
    borderRadius: '50%',
    border: `1px solid ${COLORS.SKY_BLUE}`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

interface ICreateBookInsightsHeaderProps {
    title?: string;
}
export const CreateBookInsightsHeader:FC <ICreateBookInsightsHeaderProps> = ({
    title= 'Create Insight',
}) => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <StyledLayout>
            <StyledButton onClick={handleBack}>
                <LeftArrowIcon color={COLORS.SKY_BLUE}/>
            </StyledButton>
            <PageTitle title={title}/>
        </StyledLayout>
    )
}