import { FC, useMemo, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { SearchSelectInput } from '../../../UI';
import { COLORS } from '../../../utils';
import { useGetSpaces } from "../../../hooks";
import { useSelector } from "react-redux";

const StyledBox = styled(Box)({
    '& .MuiAutocomplete-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        background: COLORS.GRAY,
        height: '100%',
        borderRadius: '0.2rem',
        input: {
            color: '#ffffff',
            opacity: 'unset'
        },
        '.MuiFormControl-root': {
            height: '100%'
        },
        '.MuiAutocomplete-input': {
            textAlign: 'center'
        }
    },

    '& .MuiInputBase-root': {
        height: '100%',
        fontSize: '0.875rem',
        borderRadius: '0.5rem',
        border: 'none',
        '& fieldset': {
            border: 'none',
            // border: `1px solid ${COLORS.WHITE}`,
        },
        '&:hover fieldset': {
            // border: `1px solid ${COLORS.WHITE}`,
        },
        '&.Mui-focused fieldset': {
            border: `1px solid ${ COLORS.SKY_BLUE }`,
        },
    },
});

interface IFilterCategoryProps {
    placeholder?: string,
    size?: 'small' | 'medium';
    className?: string;
}

export const FilterCategory: FC<IFilterCategoryProps> = ({
    size = 'small',
    className,
    placeholder,
}) => {
    const {slug} = useSelector((state: any) => state.locales)
    const [ workspaceId, setWorkspaceId ] = useState<number>(0);
    const {data: workspacesList, isLoading: isLoadingWorkspaces}: any = useGetSpaces(slug);

    const options = useMemo(() => {
        const getUpdatedWorkspaceList = workspacesList?.filter((item: any) => item.name.length > 0)

        return (
            getUpdatedWorkspaceList?.map((workspace: any) => ({
                label: workspace.name,
                value: workspace.id,
            })) || []
        );
    }, [workspacesList, isLoadingWorkspaces]);

    const changeCategoryHandler = (newValue: number) => {
        setWorkspaceId(newValue);
    };

    // useEffect(() => {
    //     setParams({category_id: workspaceId.toString(), page: 1});
    // }, [ workspaceId ]);

    useEffect(() => {
        if (!workspaceId && workspacesList?.length) {
            setWorkspaceId(workspacesList[0].id);
        }
    }, [ workspacesList, workspaceId ]);


    return (
        <StyledBox className={ className }>
            <SearchSelectInput
                options={ options }
                onChange={ newValue => changeCategoryHandler(newValue as number) }
                value={ workspaceId }
                size={ size }
                placeholder={placeholder || 'All Categories'}
                variant='outlined'
                disableClearable
            />
        </StyledBox>
    );
};
