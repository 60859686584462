import { FC } from "react";

interface IClockIcon {
    style?: any
}
export const ClockIcon:FC<IClockIcon> = ({style}) => {
    return (
        <svg style={style} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_699_1017)">
                <path d="M10 5V10H15" stroke="white" stroke-opacity="0.5" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.99996 18.3332C14.6025 18.3332 18.3333 14.6023 18.3333 9.99984C18.3333 5.39734 14.6025 1.6665 9.99996 1.6665C5.39746 1.6665 1.66663 5.39734 1.66663 9.99984C1.66663 14.6023 5.39746 18.3332 9.99996 18.3332Z" stroke="white" stroke-opacity="0.5" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_699_1017">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}