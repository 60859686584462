import { useQuery, useQueryClient, useMutation } from "react-query";
import {
    createWorkspace, createWorkspaceDetails,
    deleteWorkspace, deleteWorkspaceDetails,
    getWorkspaceDetails,
    getWorkspaces,
    updateWorkspace,
    updateWorkspaceDetail
} from "../api";
import { ISpacesRes } from "../types";

export const useManageWorkspaces = (locale: any) => {
    const createWorkspaceMutation = useCreateWorkspace();
    const deleteWorkspaceMutation = useDeleteWorkspace();
    const getSpacesQuery = useGetSpaces(locale);

    // Combine loading states
    const isLoading = createWorkspaceMutation.isLoading || getSpacesQuery.isFetching;
    const isDeleteLoading = deleteWorkspaceMutation.isLoading || getSpacesQuery.isFetching

    return {
        deleteWorkspace: deleteWorkspaceMutation.mutate,
        createWorkspace: createWorkspaceMutation.mutate,
        isLoading,
        isDeleteLoading,
        workspaces: getSpacesQuery.data,
        error: getSpacesQuery.error,
    };
};


export const useGetSpaces = (locale: any) => {
    return useQuery<ISpacesRes | null, unknown>(
        ['workspace_details', locale], // Include locale in the query key to make it unique per locale
        async () => {
            if (!locale) {
                return Promise.resolve(null); // Return null if locale is not provided
            }
            const res = await getWorkspaces(locale);
            return res;
        },
        {
            staleTime: 60 * 60 * 1000,
            retry: false,
        }
    );
};

export const useCreateWorkspace = () => {
    const queryClient = useQueryClient();
    return useMutation(createWorkspace, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['workspace_details'] });
        },
        onSettled: () => {console.log("BBB")},
        onError: () => {},
    });
};

export const useCreateWorkspaceDetails = () => {
    const queryClient = useQueryClient();
    return useMutation(createWorkspaceDetails, {
        onSuccess: async () => {
           await queryClient.invalidateQueries({ queryKey: ['workspace_details'] });
        },
        onError: () => {},
    });
};

export const useUpdateWorkspace = () => {
    const queryClient = useQueryClient();
    return useMutation(updateWorkspace, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['workspace_details'] });
        },
        onError: () => {},
    });
};

export const useUpdateWorkspaceDetails = () => {
    const queryClient = useQueryClient();
    return useMutation(updateWorkspaceDetail, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['workspace_details'] });
        },
        onError: () => {},
    });
};

export const useDeleteWorkspace = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteWorkspace, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['workspace_details'] });
        },
        onError: () => {},
    });
};

export const useDeleteWorkspaceDetails = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteWorkspaceDetails, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['workspace_details'] });
        },
        onError: () => {},
    });
};
