import { FC, useEffect, useState } from "react";
import { CheatsCardWrapper } from "./styledComponents";
import { Button, StyledSwitch } from "../../../UI";
import { StyledHr } from "../../Workspaces/WorkspaceCard/styledComponents";
import { EditIcon } from "../../../assets";
import { createLink } from "../../../utils";
import { ROUTES } from "../../../app";
import { useNavigate } from "react-router-dom";
import { useUpdateCheat } from "../../../hooks";

interface ICheatsCard {
  cheat: any
}

export const CheatsCard: FC<ICheatsCard> = ({
  cheat
}) => {
  const navigate = useNavigate()

  const {
    title,
    is_active,
    id,
    image,
    workspace_id,
    locale_id,
  } = cheat

  const {mutateAsync: updateCheat} = useUpdateCheat();

  const [ isActive, setIsActive ] = useState(is_active)

  const changeActiveStatus = () => {
    setIsActive(!isActive)

  };

  useEffect(() => {
    updateCheat({
      id,
      title,
      image,
      workspace_id,
      locale_id,
      is_active: isActive,
    }).catch(() => {});
  }, [isActive])


  const openEditDrawer = (
    id: string | number,
  ) => {
    navigate(
      createLink(ROUTES.editCheats, id, 'id',),
    );
  };

  return (
    <CheatsCardWrapper>
      <div className='logoBox'>
        <img src={ image } className='logo' />

        <div className={ 'toggle' }>
          <StyledSwitch
            checked={ isActive }
            onChange={ changeActiveStatus }
          />
        </div>
      </div>

      <h2>{ title }</h2>

      <StyledHr />


      <div className={ 'separator' }>

      </div>

      <Button
        startIcon={ <EditIcon color={ 'white' } /> }
        className='button'
        size='large'
        onClick={ () => openEditDrawer(id) }
      >
        Edit
      </Button>
    </CheatsCardWrapper>
  )
}