import { FC, useState, useMemo, MouseEvent, useContext } from 'react';
import { getComparator } from "../../../utils/tableUtils";
import { TableBody, TableCell } from '@mui/material';
import { EditIcon, DeleteIcon } from '../../../assets';
import { DeleteModal } from "../../DeleteModal";
import {
    StyledBox,
    StyledPaper,
    StyledTableContainer,
    StyledTable,
    StyledTableRow,
    StyledNewsInfo,
    StyledAvatar,
    StyledNewsName,
    StyledNewsInfoAtModal,
} from './styledComponents';
import { AdminsTableHead } from "./AdminsTableHead";
import { IBookInsights } from "../../../types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteAdmin, useGetAdminsList } from "../../../hooks";
import { TableCardSkeleton } from "../../../UI";

export const AdminsTable: FC = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [ order, setOrder ] = useState<any>('asc');
    const [ orderBy, setOrderBy ] = useState<keyof any>('name');
    const [ showDeleteModal, setShowDeleteModal ] = useState<boolean>(false);
    const [ idToDelete, setIdToDelete ] = useState(0)
    const {data: adminsList, isLoading}: any = useGetAdminsList();
    const {mutateAsync: deleteAdmin} = useDeleteAdmin();

    const openEditDrawer = (id: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('edit', id.toString());
        navigate(`?${ searchParams.toString() }`);
    };

    const openDeleteModal = (id: number) => {
        setShowDeleteModal(true);
        setIdToDelete(id)
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleRequestSort = (
        _event: MouseEvent<unknown>,
        property: keyof IBookInsights,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(() => {
        return adminsList?.sort(getComparator(order, orderBy));
    }, [ adminsList, order, orderBy ]);

    const getTime = (time: any) => {
        if (Number.isInteger(time / 60)) {
            return `${ time / 60 } min`
        } else if (time < 60) {
            return `${ time } seconds`
        } else {
            return `${ parseInt((time / 60).toString()) } min ${ time % 60 } sek`
        }
    }

    const handleDeleteWorkspace = () => {
        deleteAdmin(idToDelete)
            .then(() => {
                closeDeleteModal();

            })
            .catch(() => {
            });
    };

    const getFormattedDate = (item: any) => {
        const date = new Date(item);

        const formattedDate = date.toISOString().slice(0, 10);
        const formattedTime = date.toISOString().slice(11, 19);

        return `${formattedDate}, ${formattedTime}`
    }

    return (
        <StyledBox>
            <DeleteModal
                open={ showDeleteModal }
                onConfirm={ handleDeleteWorkspace }
                onClose={ closeDeleteModal }
                title='Delete Admin'
                description='Are you sure to delete admin'
            />

            <StyledPaper>
                <StyledTableContainer>
                    <StyledTable aria-labelledby='newsTable' size='medium' stickyHeader>
                        <AdminsTableHead
                            order={ order }
                            orderBy={ orderBy }
                            onRequestSort={ handleRequestSort }
                        />
                        <TableBody
                        >
                            {isLoading && [1,2,3, 5, 6, 7, 8, 9, 10].map((item, idx) => (
                              <StyledTableRow  hover key={ idx }>
                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>
                              </StyledTableRow>
                            ))}

                            { visibleRows && visibleRows?.map((row: any) => {
                                return (
                                    <StyledTableRow
                                        hover key={ row.id }
                                    >
                                        <TableCell
                                            component='th'
                                            id={ row.id.toString() }
                                            scope='row'
                                        >
                                            <StyledNewsInfo>
                                                <StyledAvatar
                                                    onClick={ () =>
                                                        ''
                                                    }
                                                >
                                                    { !row.avatar ?
                                                        (
                                                            <div className={ 'noneImage' }>
                                                                { row.username[0] }
                                                            </div> ):
                                                        (
                                                            <div className={ 'image' }>
                                                                <img src={ row.avatar } alt="" />
                                                            </div>
                                                        ) }
                                                </StyledAvatar>
                                                <StyledNewsName
                                                    onClick={ () => '' }
                                                >
                                                    { row.username }
                                                </StyledNewsName>
                                            </StyledNewsInfo>
                                        </TableCell>
                                        <TableCell>
                                            <p>{ row.role }</p>
                                        </TableCell>
                                        <TableCell>
                                            {/*<StatusBadge is_active={ true } />*/ }
                                            <p>{ row.email }</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{getFormattedDate(row.created_at)}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{getFormattedDate(row.last_activity)} </p>
                                        </TableCell>
                                        <TableCell>
                                            <div className='editDeleteIcons'>
                                                {/*<StyledSwitch*/ }
                                                {/*    variant={ 'customGray' }*/ }
                                                {/*    checked={ true }*/ }
                                                {/*    onChange={ () => ('') }*/ }
                                                {/*/>*/ }

                                                <EditIcon
                                                    style={ {marginRight: '12px'} }
                                                    color={ '#ffffff' }
                                                    onClick={ () =>
                                                        openEditDrawer(row.id)
                                                    }
                                                />
                                                <DeleteIcon
                                                    color={ '#ffffff' }
                                                    onClick={ () => openDeleteModal(row.id) }
                                                />
                                            </div>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            }) }
                        </TableBody>
                    </StyledTable>
                </StyledTableContainer>

               {/*<Pagination count={adminsList.from}  />*/}
            </StyledPaper>
        </StyledBox>
    );
};
