import { MainFormItems, StyledLayout } from "../../BookInsights/CreateBookInsightSidebar/styledComponents";
import { BookInsightsImageUpload, Button, FormItem, SearchSelectInput, StyledFlexBox } from "../../../UI";
import { TextField } from "@mui/material";
import { FC, useMemo, useState } from "react";
import {
    useCreateAdmins,
    useCreateCheat,
    useDeleteCheat, useGetCheatSections,
    useGetCheatsList,
    useGetSpaces,
    useUpdateCheat
} from "../../../hooks";
import { ChaptersEditIcon } from "../../../assets";
import { ROUTES } from "../../../app";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface ICreateCheatsSidebar {
    isChapterBlock: any
    parameters: any
    setParameters: any
    setIsChapterBlock: any
    isEditChapterBlock: any,
    setIsEditChapterBlock: any
    purpose: string,
    id?: string | number,
    setRawData: any,
}

export const CreateCheatsSidebar: FC<ICreateCheatsSidebar> = ({
    parameters,
    isChapterBlock,
    setParameters,
    setIsChapterBlock,
    isEditChapterBlock,
    setIsEditChapterBlock,
    purpose,
    setRawData,
    id,
}) => {
    const {locale, slug} = useSelector((state: any) => state.locales);
    const navigate = useNavigate()
    const {data: cheatSections} = useGetCheatSections()
    const { mutateAsync: createCheat } = useCreateCheat();
    const { mutateAsync: updateCheat } = useUpdateCheat();
    const { mutateAsync: deleteCheat } = useDeleteCheat();

    const {data: workspaces}: any= useGetSpaces(slug);
    
    const workspacesList: any = useMemo(() => {
        const getUpdatedWorkspaceList = workspaces?.filter((item: any) => item.name.length > 0)
        return (
            getUpdatedWorkspaceList?.map((workspace: any) => ({
                label: workspace.name,
                value: workspace.id,
            })) || []
        );
    }, [ workspaces ]);

    const cheatList = cheatSections?.filter((item: any) => item.cheat_id === id)

    const handleFieldChange = (
        fieldName: any,
        newValue: any
    ) => {
        setParameters((prevInfo: any) => ({
            ...prevInfo,
            [fieldName]: newValue,
        }));
    };


    const addChapterBlock = (
        purpose: string,
        item?: any
    ) => {
        setIsChapterBlock(purpose)
        setIsEditChapterBlock(item)

        purpose !== 'add' && (
            setTimeout(() => {
                setRawData(item.id)
            }, 1000)
        )

    }

    const saveChanges = () => {
        if (purpose === 'add') {
            createCheat({...parameters, locale_id:locale })
                .then(() => {
                    setParameters([])
                    navigate(ROUTES.cheats);
                })
                .catch(() => {});
        }
        else {
            updateCheat({...parameters, locale_id:locale })
                .then(() => {
                    setParameters([])
                    navigate(ROUTES.cheats);
                })
                .catch(() => {});
        }
    };

    const handleDeleteCheat = () => {
        deleteCheat(id)
            .then(() => {
                setParameters([])
                navigate(ROUTES.cheats);
            })
            .catch(() => {
            });
    };

    const handleCancel = () => {
        setIsChapterBlock(false)
        setIsEditChapterBlock({})
    }

    return (
        <StyledLayout>
            <h2>Options</h2>

            <MainFormItems>
                <BookInsightsImageUpload
                    image={ parameters.image }
                    setImage={ newPhoto => handleFieldChange('image', newPhoto) }
                    inputId='newsCover'
                    imageSize={ 512 * 513 }
                />

                <FormItem label='Title'>
                    <TextField
                        variant='standard'
                        color='primary'
                        placeholder='Enter the text'
                        value={ parameters.title }
                        className={ 'textField' }
                        onChange={ e => handleFieldChange('title', e.target.value) }
                    />
                </FormItem>

                <FormItem className={ 'label' } label='Workspaces'>
                    <SearchSelectInput
                        options={ workspacesList }
                        value={ parameters.workspace?.id }
                        onChange={ newValue => handleFieldChange('workspace_id', newValue) }
                        placeholder='Select the workspace'
                        variant='standard'
                    />
                </FormItem>

                { cheatList  && purpose === 'edit' && (
                    <FormItem label='Chapters'>
                        <div className="chapters">
                            { cheatList?.map((item: any) => (
                                <div
                                    className={ `${ item === isEditChapterBlock ? 'chapters__block__active' : '' } chapters__block` }>
                                    <p>
                                        { item.title }
                                    </p>

                                    <div onClick={ () => addChapterBlock('edit', item) }>
                                        <ChaptersEditIcon />
                                    </div>
                                </div>
                            )) }
                        </div>
                    </FormItem>
                ) }


                { purpose === 'edit' && (
                    <Button
                        onClick={ () => addChapterBlock('add') }
                        className={ 'chapter__button' }
                    >
                        + Add Chapter
                    </Button>
                ) }

                <StyledFlexBox>
                    {purpose === 'edit' ? (
                        <>
                            <Button
                                onClick={handleDeleteCheat}
                                variant='contained'
                                size='large'
                                color='secondary'
                            >
                                Delete
                            </Button>
                            <Button
                                variant='contained'
                                size='large'
                                color={isChapterBlock ? 'customDarkerGray' : 'primary'}
                                disabled={isChapterBlock}
                                onClick={saveChanges}
                            >
                                Update
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={() => ''}
                                variant='contained'
                                size='large'
                                color='customGray'
                            >
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                size='large'
                                color={isChapterBlock ? 'customDarkerGray' : 'primary'}
                                disabled={isChapterBlock}
                                onClick={saveChanges}
                            >
                                Publish
                            </Button>
                        </>
                    )}

                </StyledFlexBox>

            </MainFormItems>
        </StyledLayout>
    )
}