import { CardsContainer, CardSkeleton } from "../../../UI";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CheatsCard } from "../CheatsCard";
import { useGetCheatsList } from "../../../hooks";
import { useSelector } from "react-redux";

export const CheatsBody = () => {
    const {locale, slug} = useSelector((state: any) => state.locales);

    const [cheatsData, setCheatsData] = useState([])
    const {data: cheatsList, isLoading} = useGetCheatsList(slug)
    const [items, setItems] = useState([
        {
            id: 1,
            icon: '',
            title: 'Basic',
            is_active: true,
        },
        {
            id: 2,
            icon: '',
            title: 'Basic',
            is_active: true,
        }
    ])

    useEffect(() => {
        const cheatData = cheatsList?.filter((item: any) => item.locale_id == locale)

        setCheatsData(cheatData)
    }, [locale, cheatsList])


    return (
        <CardsContainer container spacing={3}>
            {!isLoading && cheatsData && cheatsData.map((item: any) => (
                <Grid item xs={4} key={item.id}>
                    <CheatsCard cheat={item}/>
                </Grid>
            ))}

            {isLoading && [1,2,3].map((skeleton, idx) => (
              <Grid item xs={4} key={idx}>
                  <CardSkeleton/>
              </Grid>
            ))}
        </CardsContainer>
    )
}