import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export const initialStateReducer: any = {
    locale: Number(localStorage.getItem('lang')),
    slug: localStorage.getItem('slug')
}

const localesReducer = createSlice({
    name: 'locales',
    initialState: initialStateReducer,
    reducers: {
        changeLocale: (state, action: PayloadAction<any>) => {
            state.locale = action.payload.locale
            state.slug = action.payload.slug
        },
    },
})

export const { changeLocale } = localesReducer.actions

export default localesReducer.reducer