import { useState, useMemo, useEffect } from "react";
import { CreateBookInsightsSidebar } from "../CreateBookInsightSidebar";
import { Box } from "@mui/material";
import { generateBlockHtml } from "./utils";
import { Editor } from "./Editor";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../app";

import {
  StyledBody,
  StyledEditorWrapper,
  EditorHeader,
} from './styledComponents';
import {
  useCreateBookInsightsItem,
  useDeleteBookInsightsItem,
  useUpdateBookInsightsItem,
  useCreateInsightsContent,
} from "../../../hooks/useBookInsights";
import { prepareNewsDateFormat } from "../../../utils";
import { useSelector } from "react-redux";
import { useGetSpaces } from "../../../hooks";

const DEFAULT_SIDEBAR_VALUES = {
  publication_at: new Date().toISOString(),
}

export const CreateBookInsightsBody = ({
  defaultContent,
  defaultSidebarValues,
  purpose,
}) => {
  const {slug} = useSelector((state) => state.locales)
  const locale_id = localStorage.getItem('lang')
  const navigate = useNavigate();
  const [isRawDataVisible, setIsRawDataVisible] = useState(false)
  const [rawData, setRawData] = useState({time: 1717173492061})
  const [parameters, setParameters] = useState(DEFAULT_SIDEBAR_VALUES)

  const [chapters] = useState([
    {
      id: 0,
      title: '',
      setValue: (
        title,
        id,
        list,
      ) => onChangeChapterValue(
        title,
        id,
        list,
      ),
    },
  ])
  const [duplicatedChapters, setDuplicatedChapters] = useState([...chapters])
  const { mutateAsync: createInsightsContent, } = useCreateInsightsContent()
  const { mutateAsync: createBookInsights } = useCreateBookInsightsItem();
  const { mutateAsync: updateBookInsights } = useUpdateBookInsightsItem();
  const { mutateAsync: deleteBookInsights } = useDeleteBookInsightsItem();

  const {data: workspaces} = useGetSpaces(slug);

  const workspacesList = useMemo(() => {
    const getUpdatedWorkspaceList = workspaces?.filter((item) => item.name.length > 0)
    return (
      getUpdatedWorkspaceList?.map((workspace) => ({
        label: workspace.name,
        value: workspace.id,
      })) || []
    );
  }, [workspaces]);

  useEffect(() => {
    if (defaultSidebarValues?.id && purpose === 'edit') {
      setParameters(prevInfo => ({
        ...prevInfo,
        ...defaultSidebarValues,
      }));

      setDuplicatedChapters(defaultSidebarValues.chapters)
    } else {
      setParameters(prevInfo => ({
        ...prevInfo,
      }));
    }
  }, [defaultSidebarValues]);

  useEffect(() => {
    if (defaultContent) {
      setRawData({time: rawData.time, blocks: defaultContent?.editor ? defaultContent?.editor?.blocks : [], version: '2.82.2'})
      setIsRawDataVisible(true)
    }
  }, [defaultContent]);

  const handleFieldChange = (fieldName, newValue) => {
    setParameters(prevInfo => ({
      ...prevInfo,
      [fieldName]: newValue,
    }));
  };

  const onChangeChapterValue = (
    title,
    id,
    list
  ) => {
    const listUpdates = [...list].map(i => {
      if (i.id === id) i.title = title;

      return i;
    });

    setDuplicatedChapters(listUpdates)
  }

  const submitInsights = () => {
    if (purpose === 'edit') {
      updateBookInsights({
        locale_id: locale_id,
        ...parameters,
      })
        .then(() => {
        })
        .catch(() => {
        });

      createInsightsContent({
        id: parameters.id, items: {
          content: rawData?.blocks?.map((raw, idx) => ({
            id: idx,
            language: raw.type === 'codeBox' ? raw.data.language : null,
            data: generateBlockHtml(raw),
            type: raw.type
          })),

          editor: rawData
        }
      })
        .then(() => {
          goToDefault();
          navigate(ROUTES.bookInsights);
        })
        .catch(() => {
        });

      return;
    }

    createBookInsights({
      ...parameters,
      locale_id: locale_id,
      publication_at: prepareNewsDateFormat(parameters.publication_at)
    })
      .then(() => {
        goToDefault();
        navigate(ROUTES.bookInsights);
      })
      .catch(() => {
      });
  };

  const cancelInsights = () => {
    goToDefault();
    navigate(ROUTES.bookInsights);
  };

  const deleteInsights = () => {
    deleteBookInsights(parameters?.id)
      .then(() => {
        goToDefault();
        navigate(ROUTES.bookInsights);
      })
      .catch(() => {
      });
  };

  const goToDefault = () => {
    setRawData({});
    setParameters([]);
  };

  const saveRawData = data => {
    setRawData(data);
  };


  return (
    <StyledBody>
      <CreateBookInsightsSidebar
        purpose={purpose}
        chapters={chapters}
        duplicatedChapters={duplicatedChapters}
        setDuplicatedChapters={setDuplicatedChapters}
        workspaceOptions={workspacesList}
        parameters={parameters}
        handleFieldChange={handleFieldChange}
        onSubmit={submitInsights}
        onCancel={cancelInsights}
        onDelete={deleteInsights}
      />

      {purpose === 'edit' && (
        <StyledEditorWrapper>
          <EditorHeader>
            <Box className='title'>Editor</Box>
          </EditorHeader>

          {isRawDataVisible && (
            <Editor rawData={rawData} saveRawData={saveRawData} />
          )}
        </StyledEditorWrapper>
      )}
    </StyledBody>
  )
}