import { FC, ReactNode } from 'react';
import { styled } from '@mui/system';
import { Box, TextField } from '@mui/material';
import { FormItem, Modal, ModalFooter, ModalTitle } from '../../../UI';

const StyledModalContent = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
    width: '100%',

    '& .textField': {
        width: '100%',
    },

    '& .info': {
        display: 'flex',
        alignItems: 'center',
        gap: '0.625rem',
        '& img': {
            width: '3.125rem',
            height: '3.125rem',
            borderRadius: '50%'
        },

        '& .email': {
            opacity: '0.5',
            fontWeight: 400
        }
    }
});

const StyledTextBox = styled(Box)({
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    marginTop: '-1rem',
    textAlign: 'center',
    opacity: 0.5,
});

interface IDeleteModalProps {
    open: boolean;
    title: string;
    description?: string;
    onClose: () => void;
    onConfirm: () => void;
    children?: ReactNode;
    info: any,
    setNewPassword: any,
    password: string
}

export const PasswordModal: FC<IDeleteModalProps> = ({
    open,
    title,
    description,
    onClose,
    onConfirm,
    children,
    info,
    setNewPassword,
    password
}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <StyledModalContent>
                <ModalTitle title={title} style={{ textAlign: 'center' }} />
                <StyledTextBox>{description}</StyledTextBox>

                <div className={'info'}>
                    <img src={info?.avatar} alt="" />
                    <div>
                        <p>{info?.username}</p>
                        <p className={'email'}>{info?.email}</p>
                    </div>
                </div>
                <FormItem label={'Password'}>
                    <TextField
                        className={'textField'}
                        variant='standard'
                        color={'primary'}
                        placeholder='Enter the password'
                        value={password}
                        onChange={e => setNewPassword(e.target.value)}
                    />
                </FormItem>

                {children}
                <ModalFooter
                    onCancel={onClose}
                    onConfirm={onConfirm}
                    confirmButtonText='Change'
                    cancelButtonText='Отменить'
                    confirmButtonColor='primary'
                    buttonSize='large'
                />
            </StyledModalContent>
        </Modal>
    );
};
