import { FC, useState, ChangeEvent } from 'react';

import { Box} from '@mui/material';
import { styled } from '@mui/system';
import { UsernameInput, Circle, PasswordInput } from "../index";
import { useLogin } from "../../hooks";
import { Button } from "../../UI";
import LoginIcon from '../../assets/images/login_icon.png';
import classes from './Auth.module.scss';

const StyledButtonBox = styled(Box)({
    width: '100%',
    '& button': {
        width: '100%',
    },
});

export const AuthComponent: FC = () => {
    const [ usernameInputValue, setUsernameInputValue ] = useState<string>('');
    const [ passwordInputValue, setPasswordInputValue ] = useState<string>('');

    const {
        mutateAsync: login,
        error: loginError,
        isError: isLoginError,
    } = useLogin();

    const onUsernameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUsernameInputValue(e.target.value);
    };

    const onPasswordInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPasswordInputValue(e.target.value);
    };

    const sendConfirmationCode = () => {
        login({
            username: usernameInputValue,
            password: passwordInputValue,
        }).catch(() => {
        });
    };

    return (
        <div className={ classes.auth }>
            <Circle />
            <div className={ classes.auth__card }>
                <img
                    className={ classes.auth__icon }
                    src={ LoginIcon }
                    alt="loginIcon"
                />

                <h1 className={ classes.auth__card_title }>
                    Welcome Back
                </h1>

                <UsernameInput
                    // isError={ isSmsError }
                    // errorMessage={ getErrorMessage(smsError) }
                    onChange={ onUsernameInputChange }
                    value={ usernameInputValue }
                />
                <PasswordInput
                    onChange={ onPasswordInputChange }
                    value={ passwordInputValue }
                />

                <StyledButtonBox>
                    <Button size='large' onClick={ sendConfirmationCode }>
                        Login
                    </Button>
                </StyledButtonBox>
            </div>
        </div>
    );
};
