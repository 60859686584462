import * as Pages from '../../../pages'
import { IRoutes } from '../types';

export const Locales: IRoutes = {
    locales: {
        path: '/locales',
        title: 'locales',
        element: <Pages.Locales />,
    }
}
