import { http, methods } from './config';

/* --------------------------------

 Types

 -------------------------------- */

interface LoginData {
    username: string;
    password: string;
}

/* --------------------------------

 API methods

 -------------------------------- */

export const login = (data: LoginData) => {
    const config = {
        method: methods.post,
        url: `/login`,
        data,
    };
    return http(config);
};

export const logout = () => {
    const config = {
        method: methods.post,
        url: `/login`,
    };
    return http(config);
};