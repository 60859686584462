import { http, methods } from "./config";
import { IBookInsightsReq } from "../types";
export const getBookInsights = (locale: string) => {

    const config = {
        method: methods.get,
        url: `/insights`,
        headers: {
            'Content-Language': locale
        }
    };
    return http(config);
}

export const createBookInsightsItem = (data: any) => {
    const config = {
        method: methods.post,
        url: '/insights',
        data,
    };
    return http(config);
};

export const updateBookInsightsItem = (data: any) => {
    const config = {
        method: methods.put,
        url: `/insights/${data.id}`,
        data,
    };
    return http(config);
};

export const deleteBookInsightsItem = (data: any) => {
    const config = {
        method: methods.delete,
        url: `/insights/${data}`,
        data,
    };
    return http(config);
};

export const getBookInsightCovers = () => {
    const config = {
        method: methods.get,
        url: `/insights/covers`,
    };
    return http(config);
}

export const getBookInsightContent = (id: any) => {
    const config = {
        method: methods.get,
        url: `/insights/single/${id}/content`,
    };
    return http(config);
}

export const createBookInsightContent = (data: any) => {
    const config = {
        method: methods.post,
        url: `/insights/single/${data.id}/content`,
        data: data.items,
    };
    return http(config);
};

