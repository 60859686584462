import { useQuery, useQueryClient, useMutation } from "react-query";
import { IBookInsights, IBookInsightsCovers, IBookInsightsReq, IBookInsightsRes } from "../types";
import {
    createBookInsightContent,
    createBookInsightsItem,
    deleteBookInsightsItem, getBookInsightContent,
    getBookInsightCovers,
    getBookInsights,
    updateBookInsightsItem
} from "../api";

export const useGetCovers = () => {
    return useQuery<IBookInsightsCovers, unknown>(
        [ 'covers' ],
        async () => {
            const res = await getBookInsightCovers();
            return res;
        },
        {
            staleTime: Infinity,
            retry: false
        }
    )
}
export const useGetBookInsights = (locale: string) => {
    return useQuery<IBookInsights, unknown>(
        [ 'insights', locale ],
        async () => {
            if (!locale) {
                return Promise.resolve(null);
            }

            const res = await getBookInsights(locale);
            return res;
        },
        {
            staleTime: 60 * 60 * 1000,
            retry: false,
        },
    );
};

export const useCreateBookInsightsItem = () => {
    const queryClient = useQueryClient();
    return useMutation(createBookInsightsItem, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['insights'] });
        },
        onError: () => {},
    });
};

export const useUpdateBookInsightsItem = () => {
    const queryClient = useQueryClient();
    return useMutation(updateBookInsightsItem, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['insights'] });
        },
        onError: () => {},
    });
};


export const useDeleteBookInsightsItem = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteBookInsightsItem, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['insights'] });
        },
        onError: () => {},
    });
};

export const useCreateInsightsContent = () => {
    const queryClient = useQueryClient();
    return useMutation( createBookInsightContent, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['insights-content'] });
        },
        onError: () => {},
    });

};

export const useGetInsightsContent = (id: any) => {
    return useQuery<any, unknown>(
        ['insight-content', id],
        async () => {
            const res = await getBookInsightContent(id);
            return res;
        },
        {
            retry: false,
            cacheTime: 0
        },
    );
};