import { useRef, useContext, useMemo } from "react";
import { UserContext, UserContextType } from "../../app";
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";

import { Button } from "../../UI";
import { UserInfo } from "./UseInfo";
import { MenuItems } from "./MenuItems";
import { useLocation, useNavigate } from 'react-router-dom';

import { COLORS, removeItem } from "../../utils";

import {
    StyledSidebarBox,
    StyledMenuBox,
    StyledLogoutBox,
} from "./styledComponents";
import { useGetUser } from "../../hooks/useUser";
import { SideBarSelect } from "./select";
import { useLocales } from "../../hooks/useLocales";

export const Sidebar = () => {
    const location = useLocation()
    const menuRef = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const {
        userData,
        space_name: companyName,
        space,
        role_name: userRole = '',
    } = useContext(UserContext) as UserContextType;

    const {data: user} = useGetUser()

    const userInfo = useMemo(() => {
        return {
            userImageUrl: user?.avatar || undefined,
            userRole: user?.role,
            username: user?.username,
        };
    }, [user]);

    const redirectUser = (path: string) => {
        const menuElement = menuRef.current;
        if (menuElement) {
            sessionStorage.setItem(
                'menuScrollPosition',
                menuElement.scrollTop.toString(),
            );
        }
        navigate(path);
    };

    const logOutUser = (): any => {
        removeItem('userData');
        removeItem('token')
        window.location.reload();
    }

    return (
        <StyledSidebarBox>
            <UserInfo {...userInfo}/>

            <SideBarSelect/>

            <StyledMenuBox>
                {MenuItems.map(item => (
                    <ListItem
                        key={item.path}
                        disablePadding
                        onClick={() => redirectUser(item.path)}
                    >
                        <ListItemButton>
                            <ListItemIcon>
                                <item.icon
                                    activeColor={COLORS.SKY_BLUE}
                                    isActive={pathname.startsWith(item.path)}
                                />
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </StyledMenuBox>
            <StyledLogoutBox>
                <Button color={'customDarkBlue'} size='large' onClick={() => (logOutUser())}>
                    Log Out
                </Button>
            </StyledLogoutBox>
        </StyledSidebarBox>
    )
}