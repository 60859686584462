import * as Pages from '../../../pages'

import { IRoutes } from '../types';
import { Workspaces } from "./workspaces";
import { Analytics } from "./analytics";
import { Auth } from "./auth";
import { BookInsights } from "./book-insights";
import { Cheats } from "./cheats";
import { Admins } from "./admins";
import { Locales } from "./locales";

export const combinedSegments: any = {
    initialRoute: {
        path: '/',
        title: 'initialRoute',
        element: <div>User must not see this page</div>,
    },
    //   home: {
    //     path: '/home',
    //     title: 'home',
    //     element: <Pages.Auth />,
    //   },
    // * - * - * - * - * - * - * - *

    ...Auth,
    ...Workspaces,
    // ...Analytics,
    ...BookInsights,
    ...Cheats,
    ...Admins,
    ...Locales,

    // * - * - * - * - * - * - * - *
    noMatch: {
        path: '*',
        title: 'noMatch',
        element: <Pages.NotFound404 />,
    },
};

