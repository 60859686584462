import { FC, useEffect, useState } from "react";
import classes from './background-selector.module.scss'
import { NextIcon } from "../../assets";
import { IBookInsightsCovers } from "../../types";

interface IBackgroundSelector {
    activeCover: any,
    setActiveCover: (_image: string) => void;
    covers: any,
    background: any
}
export const BackgroundSelector: FC <IBackgroundSelector> = ({
    activeCover,
    setActiveCover,
    covers,
    background
}) => {
    const [imgIndex, setImgIndex] = useState<any>(0)


    useEffect(() => {
        const getImg = covers[imgIndex]

        setActiveCover(getImg.path)

    },[imgIndex])


    const handleNextImg = () => {
        if (imgIndex < covers.length - 1) {
            setImgIndex(imgIndex + 1)
        }
        else {
            setImgIndex(0)
        }
    }
    const handlePrevImg = () => {
        if (imgIndex > 0) {
            setImgIndex(imgIndex - 1)
        }
        else {
            setImgIndex(covers.length - 1)
        }
    }

    return (
        <div className={classes.wrapper}>
            <img className={classes.image} src={activeCover} alt="" />
            <span onClick={handlePrevImg} className={classes.prev}>
                <NextIcon/>
            </span>

            <div style={{background: background}} className={classes.font}>
                <div className={classes.desc}>
                    <h1>Preview</h1>
                    <p>Choose a background theme</p>
                </div>
            </div>

            <span className={classes.next} onClick={handleNextImg}>
                <NextIcon/>
            </span>
        </div>
    )
}