import { Button, PageHeader, SearchSelectInput } from "../../../UI";
import { PlusIcon } from "../../../assets";
import { FilterCategory } from "../../BookInsights/BookInsightsTable/FilterCategory";
import { ROUTES } from "../../../app";

export const CheatsHeader = () => {
    return (
        <PageHeader title={'Cheats'}>
            <Button
                variant='contained'
                href={ROUTES.createCheats}
                startIcon={<PlusIcon />}
                className='withFixedWidth'
            >
                Add
            </Button>

            {/*<SearchSelectInput*/}
            {/*    placeholder='Select the workspaces'*/}
            {/*    variant='standard'*/}
            {/*    options={[]} onChange={() => ('')}*/}
            {/*/>*/}
        </PageHeader>
    )
}