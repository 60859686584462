import { FC, ChangeEvent, useState , CSSProperties} from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Button } from '../../UI';
import { notify } from '../../utils';
import AvatarPlaceholder from '../../assets/images/avatar_input_placeholder.png';
import { MediaType } from "../../types";
import { useUploadMedia } from "../../hooks";

const StyledContainer = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  color: '#ffffff',
  '& img': {
    width: '4rem',
    height: '4rem',
    borderRadius: '0.5rem',
    objectFit: 'contain',
    objectPosition: 'center',
    cursor: 'pointer',
  },
});

const StyledContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  width: '100%',
  justifyContent: 'center',
  '& .title': {
    fontWeight: 600,
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  '& .description': {
    fontFamily: '"SF Pro Display", sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    fontWeight: 400,
    opacity: 0.5,
  },
});

const StyledButtonControlBox = styled(Box)({
  display: 'flex',
  gap: '0.25rem',
  width: '100%',
  '& > div': {
    width: '100%',
    display: 'flex',
    gap: '0.625rem',
  },
  '& button': {
    width: '100%',
  },
});

export interface ImageInputProps {
  image: string;
  setImage: (_image: string) => void;
  inputId: string;
  title?: string;
  style?: CSSProperties;
  descriptionWithImage?: string;
  descriptionWithoutImage?: string;
  withDescription?: boolean;
  withButtonControls?: boolean;
  imageSize?: number;
  imageFixedWidth?: number;
  imageFixedHeight?: number;
  mediaType: MediaType;
}

export const ImageInput: FC<ImageInputProps> = ({
  image,
  setImage,
  inputId,
  title,
  style,
  descriptionWithoutImage,
  descriptionWithImage = descriptionWithoutImage,
  withDescription = false,
  withButtonControls = false,
  imageSize = 1 * 1024 * 1024,
  imageFixedWidth = 512,
  imageFixedHeight = 512,
  mediaType,
}) => {
  const { mutateAsync: uploadMedia } = useUploadMedia();
  const [imageName, setImageName] = useState<string>('');

  const handleOpenFileUploadModal = () => {
    document.getElementById(inputId)?.click();
  };

  const convertImageSizeToText = (): string => {
    if (imageSize >= 1000000) {
      const sizeInMB = imageSize / 1000000;
      return `${sizeInMB.toFixed(1)} МБ`;
    }
    const sizeInKB = imageSize / 1000;
    return `${sizeInKB.toFixed(0)} КБ`;
  };

  const handleUploadAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    // max limit 1mb
    if (file.size > imageSize) {
      notify(
          `Размер файла превышает максимальный предел в ${convertImageSizeToText()}.`,
          'error',
      );
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result as string;

      img.onload = () => {
        // Check the image dimensions
        // if (img.width !== imageFixedWidth || img.height !== imageFixedHeight) {
        //   notify(
        //       `Высота или ширина картинки не соответствуют требуемому стандарту (${imageFixedWidth}x${imageFixedHeight})`,
        //       'error',
        //   );
        //   return;
        // }
        const formData = new FormData();
        formData.append('image', file, file.name);

        uploadMedia(formData)
            .then(res => {
              setImage(res);
              setImageName(file.name);
            })
            .catch(() => {});
      };
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveAvatar = () => {
    setImage('');
    setImageName('');
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  };

  return (
      <>
        <StyledContainer style={style}>
          {image ? (
              <img
                  src={image}
                  alt={imageName}
                  onClick={handleOpenFileUploadModal}
              />
          ) : (
              <img
                  src={AvatarPlaceholder}
                  alt='avatar placeholder'
                  onClick={handleOpenFileUploadModal}
              />
          )}
          <StyledContentBox>
            <p className='title'>{title}</p>
            {withDescription && (
                <div className='description'>
                  {imageName ? descriptionWithImage : descriptionWithoutImage}
                </div>
            )}
            {withButtonControls && (
                <StyledButtonControlBox>
                  {image ? (
                      <Box>
                        <Button onClick={handleOpenFileUploadModal}>Заменить</Button>
                        <Button onClick={handleRemoveAvatar} color='customGray'>
                          Удалить
                        </Button>
                      </Box>
                  ) : (
                      <Button onClick={handleOpenFileUploadModal}>Загрузить</Button>
                  )}
                </StyledButtonControlBox>
            )}
          </StyledContentBox>
        </StyledContainer>
        <input
            id={inputId}
            type='file'
            style={{ display: 'none' }}
            onChange={e => handleUploadAvatar(e)}
        />
      </>
  );
};
