import { FC } from "react";
import { Layout } from "../../components";
import { AdminsHeader } from "../../components/Admins/AdminsHeader";
import { useDrawer } from "../../hooks/useDrawer";

import { AdminsTable } from "../../components/Admins/AdminsTable";
import { AddEditAdminsDrawer } from "../../components/Admins/AddEditAdminsDrawer";
import { getAdminsList } from "../../api/admins";
import { AdminsResponseData } from "../../types";
import { useGetAdminsList } from "../../hooks";

export const Admins: FC = () => {
    const {data: any} : any = useGetAdminsList();

    const {
        openAddDrawer,
        viewDrawerId,
        showViewDrawer,
        hideViewDrawer,
        editDrawerId,
        purpose,
        hideAddEditDrawer,
        showAddEditDrawer,
    } = useDrawer();

    return (
        <Layout>
            <AddEditAdminsDrawer
                open={showAddEditDrawer}
                onClose={hideAddEditDrawer}
                purpose={purpose}
                id={editDrawerId}
            />

            <AdminsHeader openAddSpaceModal={openAddDrawer}/>

            <AdminsTable/>
        </Layout>
    )
}