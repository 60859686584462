import { FC, MouseEvent, useMemo} from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { COLORS } from '../../../utils';
import { SelectInput } from "../../../UI/SelectInput";

const headCells = [
    {
        id: 'username',
        label: 'Name',
        sortable: true,
    },
    {
        id: 'workspaces',
        label: 'AllWorkspaces',
        sortable: true,
    },
    {
        id: 'email',
        label: 'Email',
        sortable: true,
    },
    {
        id: 'created_at',
        label: 'Created at',
        sortable: true,
    },
    {
        id: 'last_activity',
        label: 'Last Activity',
        sortable: true,
    },
    {
        id: 'actions',
        label: 'Control',
        sortable: false,
    },
];

interface IAdmins {
    username: string,
    email: string,
    created_at: string,
    last_activity: string,
    id: any,
    workspace: any
}

export const AdminsTableHead: FC<any> = ({
    onRequestSort,
    order,
    orderBy,
}) => {
    const createSortHandler: any =
      (property: keyof IAdmins | 'actions') =>
        (event: MouseEvent<unknown>) => {
            if (property === 'actions') return;
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => {
                    if (!headCell.sortable)
                        return (
                            <TableCell key={headCell.id} align='center' padding='normal'>
                                {headCell.label}
                            </TableCell>
                        );
                    // if (headCell.id === 'space')
                    //   return (
                    //     <TableCell
                    //       key={headCell.id}
                    //       align='center'
                    //       padding='normal'
                    //       sortDirection={orderBy === headCell.id ? order : false}
                    //     >
                    //       <FilterSpace className='filterAtTable' />
                    //     </TableCell>
                    //   );
                    if (headCell.id === 'workspaces')
                        return (
                            <TableCell
                                key={headCell.id}
                                align='center'
                                padding='normal'
                                sortDirection={orderBy === headCell.id ? order : false}
                                sx={ {
                                    '& .MuiAutocomplete-hasPopupIcon': {
                                        background: '#FFFFFF0D',
                                        borderRadius: '4px',
                                        textAlign: 'start',
                                    },

                                    '& .MuiFormControl-fullWidth ': {
                                        background: 'none',
                                        width: 'fit-content'
                                        // height: '1.625rem',
                                    }
                                } }
                            >
                                <SelectInput
                                    textFieldStyles={{width: '8.125rem', background: '#FFFFFF0D', border: 'none',
                                        '& .MuiOutlinedInput-notchedOutline': {border: 'none !important'},
                                        }}
                                    options={[
                                        { label: 'All roles', value: 1,},
                                        { label: 'Viewer', value: 2,},
                                        { label: 'Editor', value: 3,},
                                    ]}
                                    value={1}
                                    onChange={() => ''}/>
                            </TableCell>
                        );
                    return (
                        <TableCell
                            key={headCell.id}
                            align='center'
                            padding='normal'
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                sx={{
                                    '&.Mui-active': {
                                        '& .MuiTableSortLabel-icon': {
                                            color: orderBy === headCell.id ? COLORS.SKY_BLUE : undefined,
                                        },
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        opacity: 0.3,
                                    },
                                }}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};
