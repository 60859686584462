import { Layout } from "../../components";
import { CheatsHeader, CheatsBody } from "../../components";

export const Cheats = () => {
    return (
        <Layout>
            <CheatsHeader/>

            <CheatsBody/>
        </Layout>
    )
}