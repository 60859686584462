import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { COLORS } from '../../../utils';

const StatusBadgeComp = styled(Box)(
  ({ is_active }: { is_active: boolean }) => ({
    padding: '0.125rem 0.5rem',
    borderRadius: '1.25rem',
    backgroundColor: is_active
      ? 'rgba(52, 199, 89, 0.10)'
      : 'rgba(255, 142, 0, 0.10)',
    color: is_active ? COLORS.GREEN : COLORS.RED,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontWeight: 500,
    display: 'block',
    marginBottom: '0.25rem',
    width: 'max-content',
  }),
);

interface IStatusBadgeProps {
  is_active: boolean;
  activeText?: string;
  inActiveText?: string;
  style?: any;
}

export const StatusBadge: FC<IStatusBadgeProps> = ({
  is_active,
  activeText = 'Published',
  inActiveText = 'In Process',
  style,
}) => {
  return (
    <StatusBadgeComp is_active={is_active} style={style}>
      {is_active ? activeText : inActiveText}
    </StatusBadgeComp>
  );
};
