import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { muiTheme, setItem, getItem } from '../utils';

import { Router, ROUTES } from './routes';
import { UserProvider } from './UserContext';

export const App: FC = () => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const token = getItem('token')
    const userData = getItem('userData')


    useEffect(() => {
        window.scroll(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (!token || !userData || userData === 'null' || token === 'null') return;
        const lastRoute = getItem('lastRoute') || ROUTES.initialRoute;
        navigate(lastRoute);
    }, []);

    useEffect(() => {
        if (pathname === ROUTES.initialRoute) {
            navigate(ROUTES.home);
        }
    }, [pathname]);

    useEffect(() => {
        setItem('lastRoute', `${pathname}${search}`);
    }, [pathname, search]);


    return (
        <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <UserProvider>
                    <Router />
                </UserProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};
