import { FC } from "react";
import {
    StyledBox,
    StyledUserInfo,
    StyledUserName,
    StyledUserRole
} from "./styledComponents";

interface IUserInfo {
    userImageUrl: any | undefined,
    userRole: string,
    username: string
}
export const UserInfo: FC<IUserInfo> = ({
    userImageUrl,
    userRole,
    username
}) => {
    return (
        <StyledBox>
            <StyledUserInfo>
                {userImageUrl &&  <img src={userImageUrl} alt={'avatar'}/>}
                {!userImageUrl && !username && <div className={'noneImage'}>...</div>}
                {!userImageUrl && username && <div className={'noneImage'}>{username[0]}</div>}
                {!username && !userRole ? (
                    <>
                        <StyledUserName>...</StyledUserName>
                        <StyledUserRole>...</StyledUserRole>
                    </>
                ) : (
                    <>
                        <StyledUserName>{username}</StyledUserName>
                        <StyledUserRole>{userRole}</StyledUserRole>
                    </>
                )}

            </StyledUserInfo>
        </StyledBox>
    )
}
