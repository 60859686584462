import { Box, Paper, Table, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from '../../../utils';

export const StyledBox = styled(Box)({
  width: '100%',
  overflow: 'auto',

  '& .image': {
    position: 'relative',
    width: '50px',
    height: '50px',

    img: {
      position: 'absolute',
      width: '50px',
      height: '50px',
    }
  }
});

export const StyledPaper = styled(Paper)({
  background: COLORS.GRAY,
  width: '100%',
  marginBottom: 2,
  color: '#ffffff',

  '& .MuiTableCell-body': {
    color: '#ffffff',
    border: 'none'
  },

  '& .MuiInputBase-formControl': {
    width: '8.125rem',
    height: '1.625rem !important'
  },

  '& .MuiSelect-outlined': {color: '#fff', fontWeight: '400 !important', fontSize: '0.75rem !important'},

  '& .MuiInputBase-colorPrimary': {backgroundColor: '#FFFFFF0D !important',
    '& svg': {color: '#fff', }},
  '& .MuiTableCell-head.MuiTableCell-stickyHeader': {
    color: '#ffffff',
    background:'#3e4952',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    textAlign: 'start',

    '& .Mui-active': {
      color: '#ffffff'
    }
  },

  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    height: '100%'
  }
});

export const StyledTableContainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 12rem)',
  minHeight: 'calc(100vh - 12rem)',
});

export const StyledTable = styled(Table)({
  minWidth: '1040px',
});

export const StyledTableRow = styled(TableRow)({
  '& p': {
    opacity: 0.5,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1rem',
    fontFamily: '"SF Pro Display", sans-serif',
  },
  '& .editDeleteIcons': {
    // visibility: 'hidden',
    display: 'flex',
    alignItems: 'center',

    // justifyContent: 'space-between',
    width: '100%',
    maxWidth: '8rem',
    '& svg': {
      cursor: 'pointer',
    },
  },
  '&:hover': {
    '& .editDeleteIcons': {
      visibility: 'visible',
    },
  },
});

export const StyledNewsInfoAtModal = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  border: `1px solid ${COLORS.GRAY}`,
  padding: '0.625rem',
  borderRadius: '0.5rem',
});

export const StyledNewsInfo = styled(Box)({
  display: 'flex',
  gap: '0.625rem',
  alignItems: 'center',
});

export const StyledAvatar = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '3.125rem',
  height: '3.125rem',
  borderRadius: '0.5rem',
  overflow: 'hidden',
  cursor: 'pointer',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },

  '& .noneImage': {
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    // borderRadius: '50%',
    background: COLORS.LIGHT_BLUE,
    textTransform: "uppercase",
    fontSize: '1.5rem',
    fontWeight: 700
  },

  '& .noneImageLocales': {
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    justifySelf: 'center',
    marginBottom: '0.5rem',
    background: COLORS.LIGHT_BLUE,
    textTransform: "uppercase",
    fontSize: '1.5rem',
    fontWeight: 700,
    width: '2.188rem',
    height: '2.188rem',
    minWidth: '2.188rem',
    minHeight: '2.188rem',
    borderRadius: '50%'
  }
});

export const StyledNewsName = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  fontSize: '0.875rem',
  fontFamily: '"SF Pro Display", sans-serif',
  fontWeight: 600,
  minWidth: '10rem',
  //   width: 'max-content',
});
