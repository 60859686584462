import { Layout } from "../../../components";
import { CreateBookInsightsHeader, CreateBookInsightsLayout } from "../../../components/BookInsights";
import { CreateBookInsightsBody } from "../../../components/BookInsights/CreateBookInsightsBody";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useGetBookInsights, useGetInsightsContent } from "../../../hooks/useBookInsights";
import { useSelector } from "react-redux";

export const EditBookInsights = () => {
    const {slug} = useSelector((state: any) => state.locales);
    const location = useLocation()
    const match = location.pathname.match(/\d+/);
    const getInsightsId = match ? parseInt(match[0], 10) : null;

    const {data: insights}:any = useGetBookInsights(slug)
    const {data: content} = useGetInsightsContent(getInsightsId)

    const defaultContent = useMemo(() => {
        return content || '';
    }, [content]);

    const getInsightItem = useMemo(() => {
        return insights?.find((item: any) => item.id == getInsightsId);
    }, [insights])


    const defaultSidebarValues = useMemo(() => {
        return {
            id: getInsightItem?.id,
            cover_hex: getInsightItem?.cover_hex,
            cover: getInsightItem?.cover,
            image: getInsightItem?.image,
            title: getInsightItem?.title,
            description: getInsightItem?.description,
            reading_time: getInsightItem?.reading_time,
            rating: getInsightItem?.rating,
            workspace_id: getInsightItem?.workspace.id,
            chapters: getInsightItem?.chapters,
            publication_at: getInsightItem?.publication_at
        };
    }, [getInsightItem]);

    return (
        <CreateBookInsightsLayout>

            <CreateBookInsightsHeader title={'Edit Insight'}/>

            <CreateBookInsightsBody
                defaultContent={defaultContent}
                defaultSidebarValues={defaultSidebarValues}
                purpose='edit'
            />

        </CreateBookInsightsLayout>
    )
}