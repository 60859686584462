import * as Pages from '../../../pages'
import { IRoutes } from '../types';

export const BookInsights: IRoutes = {
    bookInsights: {
        path: '/book-insights',
        title: 'Book Insights',
        element: <Pages.BookInsights />,
    },
    createBookInsights: {
        path: '/book-insights/create',
        title: 'create book-insights',
        element: <Pages.CreateBookInsights />,
    },
    editBookInsights: {
        path: '/book-insights/edit/:id',
        title: 'edit book-insights',
        element: <Pages.EditBookInsights />,
    },
}
