import { useEffect } from "react";
import {
    StyledBody,
    StyledSidebarBody,
    StyledEmptyWrapper,
    StyledEditorWrapper, EditorHeader
} from "../../BookInsights/CreateBookInsightsBody/styledComponents";
import { CreateCheatsSidebar } from "../CreateCheatsSidebar";
import { CreateCheatsChapters } from "../CreateCheatsChapters";
import { FC, useState } from "react";
import { EmptyIcon } from "../../../assets/EmptyIcon";
import { Box } from "@mui/material";
import { Editor } from "../../BookInsights/CreateBookInsightsBody/Editor";
import { useGetCheat, useGetCheatSectionContent } from "../../../hooks";


interface ICreateCheatsBody {
    defaultContent: any
    defaultSidebarValues: any
    purpose: string
    id?: number | string
}
export const CreateCheatsBody: FC<ICreateCheatsBody> = ({
    defaultContent,
    defaultSidebarValues,
    purpose,
    id
}) => {
    const [parameters, setParameters] = useState([])
    const [sections, setSections] = useState([])
    const [isChapterBlock, setIsChapterBlock] = useState('')
    const [isEditChapterBlock, setIsEditChapterBlock] = useState<any>({id: 0})
    const [ rawData, setRawData ] = useState<any>({ time:1717173492061,})
    const [ isRawData, setIsRawData] = useState<any>('')
    const [ isRawDataSecond, setIsRawDataSecond] = useState(false)


    const saveRawData = (data: any) => {
        setRawData(data);
    };

    useEffect(() => {
        if (defaultSidebarValues?.id && purpose === 'edit') {
            setParameters(prevInfo => ({
                ...prevInfo,
                ...defaultSidebarValues,
            }));

        } else {
            setParameters(prevInfo => ({
                ...prevInfo,
            }));
        }
    }, [defaultSidebarValues]);

    return (
        <StyledBody>
            <StyledSidebarBody>
                <CreateCheatsSidebar
                    parameters={parameters}
                    setParameters={setParameters}
                    isChapterBlock={isChapterBlock}
                    setIsChapterBlock={setIsChapterBlock}
                    isEditChapterBlock={isEditChapterBlock}
                    setIsEditChapterBlock={setIsEditChapterBlock}
                    setRawData={setIsRawDataSecond}
                    purpose={purpose}
                    id={id}
                />


                {isChapterBlock === 'add' && (
                    <CreateCheatsChapters
                        parameters={sections}
                        setParameters={setSections}
                        setIsChapterBlock={setIsChapterBlock}
                        isEditChapterBlock={isEditChapterBlock}
                        rawData={rawData}
                        setRawData={setRawData}
                        setRawDataSecond={setIsRawDataSecond}
                        purpose='add'
                        id={id}
                        setIsRawData={setIsRawData}
                    />
                )}

                {isChapterBlock === 'edit' && (
                    <CreateCheatsChapters
                        parameters={sections}
                        setParameters={setSections}
                        setIsChapterBlock={setIsChapterBlock}
                        isEditChapterBlock={isEditChapterBlock}
                        isChapterBlock={isChapterBlock}
                        setIsEditChapterBlock={setIsEditChapterBlock}
                        setRawDataSecond={setIsRawDataSecond}
                        rawData={rawData}
                        setRawData={setRawData}
                        purpose='edit'
                        setIsRawData={setIsRawData}
                    />
                )}
            </StyledSidebarBody>

            {(purpose === 'add' || !isChapterBlock ) && (
                <StyledEmptyWrapper>
                    <EmptyIcon/>

                    <h1>Chapter not selected</h1>
                </StyledEmptyWrapper>
            )}


            {isChapterBlock && isRawData && (isRawDataSecond === isEditChapterBlock?.id) &&  (
                <StyledEditorWrapper>
                    <EditorHeader>
                        <Box className='title'>Editor</Box>
                    </EditorHeader>

                    <Editor rawData={rawData} saveRawData={saveRawData}/>
                </StyledEditorWrapper>
            )}
        </StyledBody>
    )
}