import { Layout } from "../../../components";
import { CreateBookInsightsHeader, CreateBookInsightsLayout } from "../../../components/BookInsights";
import { CreateBookInsightsBody } from "../../../components/BookInsights/CreateBookInsightsBody";

export const CreateBookInsights = () => {
    return (
        <CreateBookInsightsLayout>
            <CreateBookInsightsHeader/>
            <CreateBookInsightsBody
                defaultContent={{}}
                defaultSidebarValues={{}}
                purpose='add'
            />
            {/*<CreateNewsBody*/}
            {/*    defaultSidebarValues={{}}*/}
            {/*    defaultContentJson={{}}*/}
            {/*    defaultContent=''*/}
            {/*    purpose='add'*/}
            {/*/>*/}
        </CreateBookInsightsLayout>
    )
}