import { createContext, useState, useMemo, useEffect, FC } from 'react';
import { IUser, IUserSpace } from '../types';
import { setItem, getItem } from '../utils';

/* --------------------------------

 Types

 -------------------------------- */

export interface UserContextType {
  userData: IUser | null;
  updateUser: (_data: IUser) => void;
  space: IUserSpace | null;
  updateSpace: (_data: IUserSpace) => void;
  space_id?: number | string;
  space_name?: string;
  role_id?: number;
  role_name?: string;
}

interface IUserProviderProps {
  children: React.ReactNode;
}

/* --------------------------------

 Component

 -------------------------------- */

export const UserContext = createContext<UserContextType | undefined>(
    undefined,
);

export const UserProvider: FC<IUserProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<IUser | null>(null);
  const [space, setSpace] = useState<IUserSpace | null>(null);

  const updateUser = (data: IUser) => {
    setUserData(data);
    setItem('userData', JSON.stringify(data));
    window.location.reload();
  };

  const updateSpace = (data: IUserSpace) => {
    setSpace(data);
    setItem('activeSpace', JSON.stringify(data));
    window.location.reload();
  };

  const contextValue = useMemo(() => {
    return {
      userData,
      updateUser,
      space,
      updateSpace,
      space_id: space?.id,
      space_name: space?.name,
      role_id: space?.role?.id,
      role_name: space?.role?.text,
    };
  }, [userData]);

  useEffect(() => {
    // Check if userData exists in localStorage
    const storedUserData = getItem('userData');
    const storedSpace = getItem('activeSpace');

    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }

    if (storedSpace && JSON.parse(storedSpace)?.id !== space?.id) {
      setSpace(JSON.parse(storedSpace));
    }
  }, []);

  return (
      <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
