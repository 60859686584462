import { FC } from 'react';
import { COLORS } from "../utils";

interface ISearchIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const SearchIcon: FC<ISearchIcon> = ({
  className,
  style,
  onClick,
  color = COLORS.BLACK,
}) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={style}
      className={className}
    >
      <path
        d='M7.49935 1.6665C4.28756 1.6665 1.66602 4.28805 1.66602 7.49984C1.66602 10.7116 4.28756 13.3332 7.49935 13.3332C8.95601 13.3332 10.2869 12.7899 11.3112 11.9009L11.666 12.2557V13.3332L16.321 17.9881C16.781 18.4481 17.5276 18.4481 17.9876 17.9881C18.4476 17.5281 18.4476 16.7815 17.9876 16.3215L13.3327 11.6665H12.2552L11.9004 11.3117C12.7894 10.2873 13.3327 8.9565 13.3327 7.49984C13.3327 4.28805 10.7111 1.6665 7.49935 1.6665ZM7.49935 3.33317C9.81041 3.33317 11.666 5.18878 11.666 7.49984C11.666 9.81089 9.81041 11.6665 7.49935 11.6665C5.18829 11.6665 3.33268 9.81089 3.33268 7.49984C3.33268 5.18878 5.18829 3.33317 7.49935 3.33317Z'
        fill={color}
      />
    </svg>
  );
};
