import { http, methods } from "./config";
import { LocalesProps, ISpaceReq } from "../types";

export const getLocales = () => {
    const config = {
        method: methods.get,
        url: `/locales`,
    };
    return http(config);
}

export const createLocale = (data: ISpaceReq) => {
    const config = {
        method: methods.post,
        url: `/locales`,
        data,
    };
    return http(config);
};

export const updateLocale = (data: LocalesProps) => {
    const config = {
        method: methods.put,
        url: `/locales/${data.id}`,
        data,
    };
    return http(config);
};

export const deleteLocale = (localeId: string | number) => {
    const config = {
        method: methods.delete,
        url: `/locales/${localeId}`,
    };
    return http(config);
};