import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const AdminsStyledFlexBox = styled(Box)({
    display: 'flex',
    gap: '1rem',

    '& img': {
        width: '5rem',
        height: '5rem'
    },

    '& > input': {
        color: 'red',
        background: 'red'
    },
    '&.fullDivWidth': {
        '& > div': {
            width: '100%',
        },
    },
    '& > svg': {
        cursor: 'pointer',
    },
    '& .switch': {
        // marginTop: '-0.5rem',
    },
    '&.buttons': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        // width: 'calc(100% - 3.75rem)',
        bottom: '0',
    },
    '& > button': {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
    },
    '& .plusIcon': {
        width: '1.5rem',
        height: '1.5rem',
        cursor: 'pointer',
        borderRadius: '0.25rem',
        '&:hover': {
            backgroundColor: 'rgba(0, 128, 255, 0.1)',
        },
    },
});


export const AdminsStyledFormItems = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',

    '& .MuiBox-root': {
        position: 'relative',
    },
    '& .passwordButton': {
        position: 'absolute',
        right: 0,
        bottom: '10px',
        height: '2.5rem',
        width: '12.125rem',
        background: '#2CB5B4',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0.5rem',
        cursor: 'pointer'
    }
});

export const AdminsStyledCheckboxItem = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
    padding: '0.5rem 0 0.5rem 0',
    borderBottom:' 1px solid #FFFFFF4D',

    '& input[type=checkbox]:checked': {
        borderRadius: '50%',
        width: '1rem',
        height: '1rem',
        display: 'flex',
        border: '2px solid #2CB5B4',
        cursor: 'pointer',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        '&:after': {
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '0.1rem solid #333F49',
            content: '""',
            position: 'absolute',
            background: '#2CB5B4',
            zIndex: '10'
        }
    },

    '& input[type="checkbox"] ': {
        borderRadius: '50%',
        width: '1rem',
        height: '1rem',
        webkitAppearance: 'none',
        display: 'flex',
        '-webkit-appearance': 'none',
        border: '2px solid #2CB5B4',
        cursor: 'pointer',

    },
});