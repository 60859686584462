import { MediaType, MediaTypeKeys } from "../types";
export interface IColorPalette {
    RED: string;
    SKY_BLUE: string;
    LIGHT_BLUE: string;
    DARK_BLUE: string;
    WHITE: string;
    GREEN: string;
    GRAY: string;
    BLACK: string;
    DISABLED_GRAY: string;
}

export const COLORS: IColorPalette = {
    RED: '#EB5858',
    SKY_BLUE: '#2CB5B4',
    LIGHT_BLUE: '#333F49',
    DARK_BLUE: '#283239',
    WHITE: '#ffffff',
    GREEN: '#2ECC71',
    GRAY: '#434E58',
    BLACK: '#000000',
    DISABLED_GRAY: 'rgba(0,0,0,0.2)',
}

export const MEDIA_TYPES = {
    MARATHON: 'marathon',
    NEWS: 'news',
    SPONSOR_LOGO: 'sponsor_logo',
    SPONSOR_COVER: 'sponsor_cover',
    TROPHY_COVER: 'trophy_cover',
    TROPHY_ITEM_COVER: 'trophy_item_cover',
    WORKSPACE_LOGO: 'workspace_logo',
    NEWS_COVER: 'news_cover',
    NEWS_CONTENT: 'news_content',
    GROUP_LOGO: 'group_logo',
    GROUP_COVER: 'group_cover',
    TEAM_LOGO: 'team',
    USER: 'user',
    TRAINING: 'training',
    PROGRAM_ICON: 'program_icon',
    PROGRAM_COVER: 'program_cover',
    ACHIEVEMENT: 'achievement',
    SHOWCASE_COVER_BIG: 'showcase_cover_big',
    SHOWCASE_COVER_MINI: 'showcase_cover_mini',
    SHOWCASE_QR: 'showcase_qr',
    PRODUCT_COVER: 'product_cover',
    PRODUCT_IMAGE: 'product_image',
} as Record<MediaTypeKeys, MediaType>;