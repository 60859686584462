import { FC, ReactNode } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from "../../../utils";

const StyledBookInsightsLayout = styled(Box)({
    width: '100%',
    minHeight: '100vh',
    padding: '1.5rem 1.75rem',
    background: COLORS.DARK_BLUE,
});

interface ICreateBookInsightsLayoutProps {
    children: ReactNode;
}

export const CreateBookInsightsLayout: FC<ICreateBookInsightsLayoutProps> = ({ children }) => {
    return <StyledBookInsightsLayout>{children}</StyledBookInsightsLayout>;
};
