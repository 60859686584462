import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    createCheat,
    createCheatSection,
    deleteCheat,
    getCheat,
    getCheatSectionList,
    getCheatsList,
    updateCheat,
    updateCheatSection,
    deleteCheatSection,
    createCheatSectionContent,
    createBookInsightContent,
    getBookInsightContent,
    getCheatSectionContent
} from "../api";

export const useGetCheatsList = (locale: string) => {
    return useQuery<any, []>(
        [ 'cheats', locale ],
        async () => {
            if (!locale) {
                return Promise.resolve(null);
            }

            const res = await getCheatsList(locale);
            return res;
        },
        {
            staleTime: 60 * 60 * 1000,
            retry: false,
        },
    );
};

export const useGetCheat = (id: string | number) => {
    return useQuery<any, []>(
        [ 'cheats', id],
        async () => {
            const res = await getCheat(id);
            return res;
        },
        {
            staleTime: 60 * 60 * 1000,
            retry: false,
        },
    );
};

export const useCreateCheat = () => {
    const queryClient = useQueryClient();
    return useMutation(createCheat, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cheats'] });
        },
        onError: () => {},
    });
};

export const useUpdateCheat = () => {
    const queryClient = useQueryClient();
    return useMutation(updateCheat, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cheats'] });
        },
        onError: () => {},
    });
};

export const useDeleteCheat = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteCheat, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cheats'] });
        },
        onError: () => {},
    });
};

export const useCreateCheatSection = () => {
    const queryClient = useQueryClient();
    return useMutation(createCheatSection, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cheat_section'] });
        },
        onError: () => {},
    });
};

export const useGetCheatSections = () => {
    return useQuery<any, []>(
        [ 'cheat_section' ],
        async () => {
            const res = await getCheatSectionList();
            return res;
        },
        {
            staleTime: 60 * 60 * 1000,
            retry: false,
        },
    );
};

export const useUpdateCheatSection = () => {
    const queryClient = useQueryClient();
    return useMutation(updateCheatSection, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cheat_section'] });
        },
        onError: () => {},
    });
};

export const useDeleteCheatSection = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteCheatSection, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cheat_section'] });
        },
        onError: () => {},
    });
};

export const useCreateCheatSectionContent = () => {
    const queryClient = useQueryClient();
    return useMutation(createCheatSectionContent, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['cheat_section'] });
        },
        onError: () => {},
    });
};

export const useGetCheatSectionContent = (id: string | number) => {
    return useQuery<any, unknown>(
        ['cheat_section', id],
        async () => {
            const res = await getCheatSectionContent(id);
            return res;
        },
        {
            staleTime: 60 * 60 * 1000,
            retry: false,
            cacheTime: 0
        },
    );
};
