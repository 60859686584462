import { FC } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from "../../utils";

export const StyledLabel = styled(Box)({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  color: COLORS.WHITE,
  display: 'flex',

});

interface IFormItemProps {
  label?: string;
  className?: string;
  children?: React.ReactNode;
}

export const FormItem: FC<IFormItemProps> = ({
  label = ' ',
  className,
  children,
}) => {
  return (
    <Box className={className}>
      <StyledLabel>{label}</StyledLabel>
      {children}

    </Box>
  );
};
