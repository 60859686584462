import { FC, useEffect, useState } from "react";
import {
    Drawer,
    ModalTitle,
    StyledFlexBox,
    DrawerHeader,
    DrawerFormBody,
    ImageInput,
    FormItem,
    Button,
} from "../../../UI";
import { TextField } from "@mui/material";
import {
    useCreateAdmins, useDeleteAdmin,
    useGetAdminItem,
    useGetAdminsList,
    useUpdateAdmin,
} from "../../../hooks";
import { ISpaceReq } from "../../../types";
import { CloseIcon, EyeIcon, DeleteIcon } from "../../../assets";
import { COLORS } from "../../../utils";
import { DeleteModal } from "../../DeleteModal";
import { MEDIA_TYPES } from "../../../utils";
import { AdminsStyledCheckboxItem, AdminsStyledFlexBox, AdminsStyledFormItems } from "./styledComponents";
import { PasswordModal } from "./PasswordModal";

const TRANSLATIONS = {
    add: {
        title: 'Workspace Creation',
    },
    edit: {
        title: 'Workspace Edit',
    },
    preview: {
        title: 'Preview',
    },
};


const DEFAULT_VALUES: ISpaceReq = {
    logo: '',
    name: '',
    description: '',
    is_active: true,
};


interface IAddEditGroupDrawerProps {
    open: boolean;
    onClose: () => void;
    purpose?: 'add' | 'edit';
    id?: number | string;
}

export const AddEditAdminsDrawer: FC<IAddEditGroupDrawerProps> = ({
    open,
    onClose,
    id = '',
    purpose = 'add',
}) => {
    const [workspaceInfo, setWorkspaceInfo] = useState<any>(DEFAULT_VALUES)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState('')
    const { mutateAsync: createAdmin } = useCreateAdmins();
    const { mutateAsync: updateAdmin } = useUpdateAdmin();
    const { mutateAsync: deleteAdmin } = useDeleteAdmin();

    const {data: dataItem} = useGetAdminItem(id)

    const ignoreChanges = () => {
        onClose();
    };

    const handleFieldChange = <T extends keyof any>(
        fieldName: T,
        newValue: any,
    ) => {
        setWorkspaceInfo((prevSpaceInfo : any) => ({
            ...prevSpaceInfo,
            [fieldName]: newValue,
        }));
    };


    useEffect(() => {
        if (dataItem) {
            if (purpose === 'edit' ) {
                setWorkspaceInfo({
                    id: dataItem?.id,
                    username: dataItem?.username || '',
                    email: dataItem?.email || '',
                    full_name: dataItem?.full_name || '',
                    // is_active: getWorkspace?.is_active || true,
                    avatar: dataItem?.avatar || '',
                    role: dataItem?.role,
                })
            }
            else {
                setWorkspaceInfo(DEFAULT_VALUES)
            }
        }
    }, [purpose, dataItem])


    const openDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const openPasswordModal = () => {
        setShowPasswordModal(true);
    };

    const closePasswordModal = () => {
        setShowPasswordModal(false);
    };

    const saveChanges = () => {
        if (purpose === 'add') {
            createAdmin(workspaceInfo)
                .then(() => {
                    onClose();
                    setWorkspaceInfo(DEFAULT_VALUES)
                })
                .catch(() => {});
        }
        else {
            updateAdmin(workspaceInfo)
                .then(() => {
                    setWorkspaceInfo({})
                    onClose();
                })
                .catch(() => {});
        }
    };

    const handleDeleteWorkspace = () => {
        deleteAdmin(workspaceInfo.id!)
            .then(() => {
                closeDeleteModal();
                onClose();
            })
            .catch(() => {});
    };

    const handleChangePassword = () => {
        setWorkspaceInfo({...workspaceInfo, password: newPassword})
        setNewPassword('')
        closePasswordModal();
    }

    return (
        <Drawer open={open} onClose={ignoreChanges}>
            <PasswordModal
                open={showPasswordModal}
                title={'Change Password'}
                onClose={closePasswordModal}
                onConfirm={handleChangePassword}
                description={'Are you sure you want to change the admin’s password?'}
                info={workspaceInfo}
                setNewPassword={setNewPassword}
                password={newPassword}
            />

            <DeleteModal
                open={showDeleteModal}
                onConfirm={handleDeleteWorkspace}
                onClose={closeDeleteModal}
                title='Delete Admin'
                description='Are you sure to delete admin'
            />

            <DrawerHeader>
                <StyledFlexBox>
                    <ModalTitle title={'New Admin'}/>
                </StyledFlexBox>
                <StyledFlexBox>
                    <CloseIcon onClick={ignoreChanges} />
                </StyledFlexBox>
            </DrawerHeader>

            <DrawerFormBody>
                <AdminsStyledFlexBox alignItems={'start'}>
                    <ImageInput
                        inputId='workspaceLogo'
                        image={workspaceInfo?.avatar}
                        setImage={newImg => handleFieldChange('avatar', newImg)}
                        mediaType={MEDIA_TYPES.WORKSPACE_LOGO}
                    />

                    <AdminsStyledFormItems>
                        <h2>Details</h2>
                        <FormItem label='Fullname'>
                            <TextField
                                className={'textField'}
                                variant='standard'
                                color={'primary'}
                                placeholder='Enter the fullname'
                                value={workspaceInfo.full_name}
                                onChange={e => handleFieldChange('full_name', e.target.value)}
                            />
                        </FormItem>

                        <FormItem label={'Username'}>
                            <TextField
                                className={'textField'}
                                variant='standard'
                                color={'primary'}
                                placeholder='Enter the username'
                                value={workspaceInfo.username}
                                onChange={e => handleFieldChange('username', e.target.value)}
                            />
                        </FormItem>

                        <FormItem label={'Email'}>
                            <TextField
                                className={'textField'}
                                variant='standard'
                                color={'primary'}
                                placeholder='Enter the email'
                                value={workspaceInfo.email }
                                onChange={e => handleFieldChange('email', e.target.value)}
                            />
                        </FormItem>

                        <FormItem label={'Role'}>
                            <StyledFlexBox>
                                <AdminsStyledCheckboxItem>
                                    <input
                                        type="checkbox"
                                        checked={workspaceInfo.role === "editor"  && true}
                                        onChange={e => handleFieldChange('role', 'editor')}
                                    />

                                    <p>
                                        Editor
                                    </p>
                                </AdminsStyledCheckboxItem>

                                <AdminsStyledCheckboxItem>
                                    <input
                                        type="checkbox"
                                        checked={workspaceInfo.role === "viewer" && true}
                                        onChange={e => handleFieldChange('role', 'viewer')}
                                    />

                                    <p>Viewer</p>

                                </AdminsStyledCheckboxItem>
                            </StyledFlexBox>
                        </FormItem>


                        <FormItem label={'Password'}>
                            <TextField
                                disabled={purpose === 'edit'}
                                className={'textField'}
                                variant='standard'
                                color={'primary'}
                                placeholder='Enter the password'
                                value={purpose === 'edit' ? '*********' : workspaceInfo.password }
                                onChange={e => handleFieldChange('password', e.target.value)}
                            />

                            {purpose === 'edit' && (
                                <div onClick={openPasswordModal} className={'passwordButton'}>
                                    ChangePassword
                                </div>
                            )}
                        </FormItem>
                    </AdminsStyledFormItems>
                </AdminsStyledFlexBox>

                <StyledFlexBox className='buttons'>
                    <StyledFlexBox>

                    </StyledFlexBox>

                    <StyledFlexBox >
                        {purpose === 'edit' && (
                            <Button
                                onClick={openDeleteModal}
                                variant='contained'
                                size='large'
                                color='secondary'
                                startIcon={<DeleteIcon color={COLORS.WHITE} />}
                                sx={{
                                    color: COLORS.WHITE,
                                }}
                            >
                                Delete
                            </Button>
                        )}
                        {purpose === 'add' && (
                            <Button
                                onClick={ignoreChanges}
                                variant='contained'
                                size='large'
                                color='customDarkerGray'
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            variant='contained'
                            size='large'
                            color='primary'
                            onClick={saveChanges}
                        >
                            {purpose === 'edit' ? 'Save' : 'Create'}
                        </Button>
                    </StyledFlexBox>
                </StyledFlexBox>
            </DrawerFormBody>
        </Drawer>
    )
}