import { FC, useState, useEffect } from 'react';
import { styled } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { FormItem } from "../FormItem";
import { StyledFlexBox } from "../Drawer";
import { COLORS } from '../../utils';
import classes from '../DateInput/DateInput.module.scss';

interface INewsDateTimePickerProps {
    dateLabel: string;
    timeLabel: string;
    defaultValue: string;
    onChange: (_newValue: string) => void;
}

// TODO: remove duplications later
const StyledDatePicker = styled(DatePicker)({
    '& .MuiInputBase-root': {
        marginTop: '0.5rem',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1.375rem',
        padding: '0.5rem 0',
        backgroundColor: COLORS.GRAY,
        flexDirection: 'row-reverse',
    },
    '& fieldset': {
        border: 'none',
    },
    '& input': {
        color: COLORS.WHITE,
        padding: '0 0 0 1rem',
    },
    '& button': {
        padding: 0,
    },
    '& svg': {
        color: COLORS.SKY_BLUE,
    },
});

const StyledTimePicker = styled(TimePicker)({
    '& .MuiInputBase-root': {
        marginTop: '0.5rem',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1.375rem',
        padding: '0.5rem 0',
        backgroundColor: COLORS.GRAY,
        flexDirection: 'row-reverse',
    },
    '& fieldset': {
        border: 'none',
    },
    '& input': {
        color: COLORS.WHITE,
        padding: '0 0 0 1rem',
    },
    '& button': {
        padding: 0,
    },
    '& svg': {
        color: COLORS.SKY_BLUE,
    },
});

export const BookInsightsDateTimePicker: FC<INewsDateTimePickerProps> = ({
    dateLabel,
    timeLabel,
    defaultValue,
    onChange,
}) => {
    const [date, setDate] = useState<Dayjs | null>(dayjs(defaultValue) || null);
    const [time, setTime] = useState<Dayjs | null>(dayjs(defaultValue) || null);

    // TODO: optimize this later
    useEffect(() => {
        setDate(dayjs(defaultValue) || null);
        setTime(dayjs(defaultValue) || null);
    }, [defaultValue]);

    const changeDate = (val: Dayjs | null) => {
        setDate(val);
        const newValue = [
            dayjs(val).format('YYYY-MM-DD'),
            dayjs(time).format('HH:mm:ss'),
        ].join(' ');
        onChange(newValue);
    };

    const changeTime = (val: Dayjs | null) => {
        setTime(val);
        const newValue = [
            dayjs(date).format('YYYY-MM-DD'),
            dayjs(val).format('HH:mm:ss'),
        ].join(' ');
        onChange(newValue);
    };

    return (
        <StyledFlexBox>
            <FormItem label={dateLabel}>
                <StyledDatePicker
                    value={date}
                    format='DD MMMM'
                    onChange={val => changeDate(val as Dayjs | null)}
                    slotProps={{
                        actionBar: {
                            actions: ['cancel', 'accept'],
                        },
                        toolbar: {
                            toolbarFormat: 'DD MMMM YYYY',
                            toolbarPlaceholder: 'Календарь',
                            hidden: false,
                            className: classes.toolbar,
                        },
                    }}
                    localeText={{
                        cancelButtonLabel: 'Закрыть',
                        okButtonLabel: 'Сохранить',
                    }}
                />
            </FormItem>
            <FormItem label={timeLabel}>
                <StyledTimePicker
                    value={time}
                    onChange={val => changeTime(val as Dayjs | null)}
                    format='hh:mm'
                />
            </FormItem>
        </StyledFlexBox>
    );
};
