import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from "./app";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import 'notyf/notyf.min.css';
import './styles/global.scss'
import { Provider } from "react-redux";
import { store } from "./redux";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </QueryClientProvider>
        </ErrorBoundary>
    </Provider>
);
