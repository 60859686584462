import { FC } from 'react';
import { COLORS } from "../utils";

interface IEditIcon {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const EditIcon: FC<IEditIcon> = ({
  className,
  color = COLORS.BLACK,
  onClick,
  ...rest
}) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
    {...rest}
  >
    <path
      d='M14.3638 5.65201L15.8438 4.17201C16.0295 3.98621 16.25 3.83882 16.4927 3.73826C16.7354 3.6377 16.9956 3.58594 17.2583 3.58594C17.521 3.58594 17.7811 3.6377 18.0238 3.73826C18.2665 3.83882 18.4871 3.98621 18.6728 4.17201L20.0868 5.58601C20.4617 5.96107 20.6724 6.46968 20.6724 7.00001C20.6724 7.53034 20.4617 8.03895 20.0868 8.41401L18.6068 9.89401M14.3638 5.65201L4.74779 15.267C4.41577 15.599 4.21115 16.0373 4.16979 16.505L3.92779 19.245C3.91479 19.3907 3.9339 19.5374 3.98376 19.6749C4.03363 19.8123 4.11304 19.9372 4.2164 20.0407C4.31975 20.1441 4.44454 20.2236 4.58197 20.2736C4.7194 20.3236 4.86612 20.3429 5.01179 20.33L7.75179 20.088C8.22021 20.0471 8.65925 19.8425 8.99179 19.51L18.6068 9.89401M14.3638 5.65201L18.6068 9.89401'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
