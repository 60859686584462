import { FC } from 'react';
import { Modal, Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from '../../utils';

interface IModal {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const StyledModalBox = styled(Box)(({ width }: { width?: number }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: width || '26.5rem',
  backgroundColor: COLORS.GRAY,
  zIndex: '999',
  padding: '1.875rem',
  borderRadius: '0.5rem',
}));


export const ModalComponent: FC<IModal> = ({ open, onClose, children }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <StyledModalBox>{children}</StyledModalBox>
    </Modal>
  );
};
