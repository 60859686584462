import { useMutation, useQuery, useQueryClient } from "react-query";
import { LocalesProps } from "../types";
import { createLocale, deleteLocale, getLocales, updateLocale, } from "../api";

export const useLocales = () => {
    return useQuery<LocalesProps[]>(
        [ 'locales' ],
        async () => {
            const res = await getLocales();
            return res;
        },
        {
            staleTime: Infinity,
            retry: false
        }
    )
}

export const useCreateLocale = () => {
    const queryClient = useQueryClient();
    return useMutation(createLocale, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['locales'] });
        },
        onError: () => {},
    });

};

export const useUpdateLocale = () => {
    const queryClient = useQueryClient();
    return useMutation(updateLocale, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['locales'] });
        },
        onError: () => {},
    });
};

export const useDeleteLocale = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteLocale, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['locales'] });
        },
        onError: () => {},
    });
};
