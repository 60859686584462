import { FC } from "react";
import { Button, PageHeader, SearchInput } from "../../../UI";
import { PlusIcon } from "../../../assets";

interface ILocalesHeader {
    openAddSpaceModal: () => void
    setLocalSearch: any,
    localSearchValue: any
}

export const LocalesHeader: FC<ILocalesHeader> = ({
    openAddSpaceModal,
    setLocalSearch,
    localSearchValue
}) => {
    const handleSearch = (value: string) => {
        setLocalSearch(value)
    }

    return (
       <PageHeader title={'Locales'}>
           <SearchInput handleSearch={value=> handleSearch(value)}/>

           <Button
               startIcon={<PlusIcon/>}
               color='primary'
               size='large'
               onClick={openAddSpaceModal}
           >
               Add
           </Button>
       </PageHeader>
    )
}