import { FC } from "react";
import { IIconProps } from "./types";

export const SpaceIcon: FC<IIconProps> = ({isActive, activeColor}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 10V1.6C19 1.44087 18.9368 1.28826 18.8243 1.17574C18.7117 1.06321 18.5591 1 18.4 1H10M19 10H10M19 10V18.4C19 18.5591 18.9368 18.7117 18.8243 18.8243C18.7117 18.9368 18.5591 19 18.4 19H10M10 10V1M10 10V19M10 10H1M10 1H1.6C1.44087 1 1.28826 1.06321 1.17574 1.17574C1.06321 1.28826 1 1.44087 1 1.6V10M10 19H1.6C1.44087 19 1.28826 18.9368 1.17574 18.8243C1.06321 18.7117 1 18.5591 1 18.4V10"  stroke={isActive ? activeColor : '#C4C4C4'} stroke-width="2"/>
        </svg>

    )
}