import { useContext, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { UserContext, UserContextType } from '../index';
import { pageRouting } from './pageRouting';
import { IRoute } from './types';
import { ROUTES } from './routes';
import { combinedSegments } from "./segments/combinedSegments";

export const Router = () => {
  const { userData } = useContext(UserContext) as UserContextType;
  const routesMap = Object.values(pageRouting);
  const isAuthenticated = useMemo(() => !!userData, [userData]);
  const admin = userData?.role?.id === 1;

  const getRouteElement = (
      element: JSX.Element,
      openRoute: boolean,
      isAdminOnly: boolean,
  ) => {
    // if user is not authenticated and route is blocked redirect to auth
    if (!openRoute && !isAuthenticated) {
      return <Navigate replace to={ROUTES.auth} />;
    }
    // if user is authenticated and route is open or user is not admin and route is admin only redirect to home
    if (openRoute && isAuthenticated){
      return <Navigate replace to={ROUTES.home} />;
    }

    return element;
  };

  const generateRoute = ({
    title,
    path,
    element,
    index,
    childRoute,
    openRoute = false,
    isAdminOnly = false,
  }: IRoute) => {
    if (childRoute) {
      return (
          <Route
              key={title}
              path={path}
              element={getRouteElement(element, openRoute, isAdminOnly)}
          >
            {Object.values(childRoute).map(child => generateRoute(child))}
          </Route>
      );
    }
    return (
        <Route
            key={title}
            index={index}
            path={path}
            element={getRouteElement(element, openRoute, isAdminOnly)}
        />
    );
  };

  const pageRoutes = routesMap.map(generateRoute);

  return <Routes>{pageRoutes}</Routes>;
};
