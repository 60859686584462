import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { login, logout } from "../api";
import { UserContext, UserContextType } from '../app';
import { setToken, removeItem, notify } from '../utils';

export const useLogin = () => {
    const {updateUser} = useContext(UserContext) as UserContextType;

    return useMutation(login, {
        mutationKey: 'login',
        onSuccess: res => {
            setToken(res.token);
            localStorage.setItem('lang', '2')
            localStorage.setItem('slug', 'en')
            updateUser({
                    firstname: 'Ulugbek',
                    id: 12,
                    lastname: 'Djalalov',
                    middlename: 'Mansurovich',
                    photo: '',
                    role: {id: 1, text: 'super admin'},
                    token: {
                        type: 'Bearer',
                        expires_at: 131231,
                        body: res.token
                    },
                    state: 1
                }
            );
        },
        onError: (err: any) => {
            const message =
                err?.response?.data?.message ||
                err?.response?.data?.error?.message ||
                'Username not found';

            notify(message, 'error');
        },
    });
};

export const useLogout = () => {
    const queryClient = useQueryClient();

    return useMutation(logout, {
        onSuccess: () => {
            queryClient.invalidateQueries();
            removeItem('token');
            removeItem('userData');
            removeItem('lastRoute');
            removeItem('lang')
            removeItem('slug')
            window.location.reload();
        },
        onError: () => {
        },
    });
};
