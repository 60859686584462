import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { getItem, getToken, notify, removeItem } from '../utils';
import { useLocation } from "react-router-dom";

// Create a new Axios instance
const axiosInstance = axios.create({
    baseURL: 'https://api.codehub.abrusdev.com/admin/v1',
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': '*',
        // 'Access-Control-Allow-Headers': '*',
    },
});


// Request interceptor
axiosInstance.interceptors.request.use(
    // @ts-ignore
    (config: AxiosRequestConfig) => {
        // @ts-ignore
        config.headers.Authorization = `Bearer ${getToken()}`;
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    },
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        // Process response data as needed
        return response.data;
    },
    (error: any) => {
        // Handle error responses
        if (error.response) {
            if (error.response.status === 401) {
                removeItem('token');
                removeItem('userData');
                removeItem('lastRoute');

                if ( error.config.url !== '/login') {
                    window.location.reload();
                }
            }
            // Handle specific error status codes or conditions
        }
        console.error('Request error: ', error);
        return Promise.reject(error);
    },
);

export const http = (config: AxiosRequestConfig): Promise<any> => {
    return axiosInstance(config)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            if (config.method !== methods.get) {
                const message =
                    err?.response?.data?.message ||
                    err?.response?.data?.error?.message ||
                    'Something went wrong';

                if ( err.config.url !== '/login') {
                    notify(message, 'error');
                }
            }
            throw err;
        });
};

type Methods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export const methods: Record<string, Methods> = {
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    patch: 'PATCH',
    delete: 'DELETE',
};
