import { FC, useState, useRef, useEffect, useMemo } from 'react';
import { TextField } from '@mui/material';
import {
    FormItem,
    Button,
    BookInsightsImageUpload,
    BookInsightsDateTimePicker,
    BackgroundSelector,
    CardSkeleton,
    SearchSelectInput,
    StyledFlexBox
} from '../../../UI';
import { MainFormItems, StyledLayout } from "./styledComponents";
import { ClockIcon } from "../../../assets/ClockIcon";
import { StarIcon } from "../../../assets/StarIcon";
import { LampIcon } from "../../../assets/LampIcon";
import { DeleteIcon, SelectColorIcon } from "../../../assets";
import Sketch from '@uiw/react-color-sketch';
import { useGetCovers } from "../../../hooks/useBookInsights";

interface ICreateBookInsightsSidebar {
    purpose: string,
    chapters: any,
    duplicatedChapters: any,
    setDuplicatedChapters: any,
    parameters: any
    handleFieldChange: any
    workspaceOptions: { label: string; value: number }[];
    onSubmit: any;
    onCancel: () => void;
    onDelete: () => void;
}

export const CreateBookInsightsSidebar: FC<ICreateBookInsightsSidebar> = ({
    purpose,
    chapters,
    duplicatedChapters,
    setDuplicatedChapters,
    parameters,
    handleFieldChange,
    workspaceOptions,
    onCancel,
    onSubmit,
    onDelete,
}) => {
    const popupRef = useRef<HTMLDivElement>(null);
    const [ isSelectColor, setIsSelectColor ] = useState(false);
    const {data: covers} = useGetCovers()

    const handleOutsideClick = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            setIsSelectColor(false)
        }
    };

    const getChapters = useMemo(() => {
        return (
            duplicatedChapters?.map((chapter: any) => ({
                id: chapter?.id,
                title: chapter?.title,
            })) || []
        );
    }, [ duplicatedChapters ]);

    const handleReadingTimeChange = (key: any, e: any) => {
        let value = key === 'rating' ? (e.target.value <= 5 && e.target.value) : e.target.value

        if (key === 'rating' && /^\d*\.?\d*$/.test(value) && value.length < 5 && value < 5) {
            value = value.replace(/^0+(?!$)/, '');

            if (value.startsWith('.')) {
                value = '0' + value;
            }

            handleFieldChange(key, value);
        }
        else if (/^\d*$/.test(value)) {
            value = value.replace(/^0+(?!$)/, '');

            handleFieldChange(key, value);
        }
    };


    useEffect(() => {
        handleFieldChange('chapters', getChapters)
    }, [ duplicatedChapters ])

    useEffect(() => {
        if (isSelectColor) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [ isSelectColor ]);

    const addChapters = () => {
        const searchLastId = duplicatedChapters[duplicatedChapters.length - 1]?.id;

        const updatedChapterList = [ ...duplicatedChapters ]

        updatedChapterList.push(...chapters.map((chapter: any) => {
            return {
                ...chapter,
                title: '',
                id: searchLastId + 1
            }
        }))

        setDuplicatedChapters(updatedChapterList)
    }

    const removeChapter = (id: any) => {
        const updatedList = duplicatedChapters.filter((item: any) => item?.id !== id)

        setDuplicatedChapters(updatedList)
    }

    function saveChanges () {
        onSubmit(parameters);
    };

    function ignoreChanges () {
        onCancel();
    };

    function deleteItem () {
        onDelete()
    }

    return (
        <StyledLayout>
            <h2>Options</h2>
            <MainFormItems>
                { covers &&
                  <BackgroundSelector
                    setActiveCover={(e) => handleFieldChange('cover', e)}
                    activeCover={parameters.cover}
                    covers={ covers }
                    background={ parameters?.cover_hex ? parameters.cover_hex : '#912456' }
                  />
                }

                <FormItem className={ 'colorBlock' } label='Color'>
                    <TextField
                        variant='standard'
                        color='primary'
                        placeholder='Enter hex code'
                        value={ parameters.cover_hex }
                        className={ 'textField' }
                        onChange={ e => handleFieldChange('color', e.target.value) }
                        onClick={ () => setIsSelectColor(!isSelectColor) }
                        InputProps={ {
                            startAdornment: (
                                <span style={ {backgroundColor: parameters.cover_hex ? parameters.cover_hex : '#912456'} }
                                      className={ 'color icon' }>
                                    <SelectColorIcon />
                                </span>
                            ),
                        } }
                    />
                    <Sketch
                        ref={ popupRef }
                        className={ 'colorSelector' }
                        style={ {display: isSelectColor ? 'block' : 'none'} }
                        color={ parameters.cover_hex }
                        onChange={ (color) => handleFieldChange('cover_hex', color.hex) }
                    />
                </FormItem>

                <BookInsightsImageUpload
                    imageSize={512 * 513}
                    image={ parameters.image }
                    setImage={ newPhoto => handleFieldChange('image', newPhoto) }
                    inputId='newsCover'
                />

                <FormItem label='Title'>
                    <TextField
                        variant='standard'
                        color='primary'
                        placeholder='Enter the text'
                        value={ parameters.title }
                        className={ 'textField' }
                        onChange={ e => handleFieldChange('title', e.target.value) }
                    />
                </FormItem>
                <FormItem label='Description'>
                    <TextField
                        variant='standard'
                        color='primary'
                        className={ 'textField' }
                        placeholder='Enter the text'
                        value={ parameters.description }
                        onChange={ e => handleFieldChange('description', e.target.value) }
                    />
                </FormItem>
                <FormItem label='Reading Time'>
                    <TextField
                        // type={'number'}
                        variant='standard'
                        color='primary'
                        className={ 'textField' }
                        placeholder='Duration'
                        value={ parameters.reading_time || 0}
                        onChange={ e => handleReadingTimeChange( 'reading_time', e ) }
                        InputProps={ {
                            startAdornment: (
                                <span className={ 'icon' }>
                                        <ClockIcon />
                                </span>
                            ),
                            sx: {},
                            endAdornment: (
                                <span style={ {color: '#ffffff'} }>min</span>
                            )
                        } }
                    />
                </FormItem>
                <FormItem className={ 'label' } label='Rating'>
                    <TextField
                        variant='standard'
                        color='primary'
                        className={ 'textField' }
                        placeholder='Value'
                        value={ parameters.rating || 0}
                        onChange={ e => handleReadingTimeChange('rating', e) }
                        InputProps={ {
                            startAdornment: (
                                <span className={ 'icon' }>
                                    <StarIcon />
                                </span>
                            ),
                            endAdornment: (
                              <span style={ {color: '#ffffff'} }>{parameters.rating || 0}/5</span>
                            )
                        } }
                    />
                </FormItem>

                <FormItem label={ 'Chapters' }>
                    { duplicatedChapters.map((chapter: any) => (
                        <TextField
                            style={ {padding: '5px 0'} }
                            variant='standard'
                            color='primary'
                            className={ 'textField' }
                            placeholder='Enter the text'
                            value={ chapter.title }
                            onChange={ (e) => chapter.setValue(e.target.value, chapter.id, duplicatedChapters) }
                            InputProps={ {
                                startAdornment: (
                                    <span className={ 'icon lamp' }>
                                        <LampIcon />
                                    </span>
                                ),
                                sx: {},
                                endAdornment: (
                                    duplicatedChapters.length > 1 &&
                                    <DeleteIcon className={ 'delete' } onClick={ () => removeChapter(chapter?.id) }
                                                color={ '#ffffff' } />
                                )
                            } }
                        />
                    )) }
                </FormItem>

                <Button
                    onClick={ addChapters }
                    className={ 'chapter__button' }
                >
                    + Add Chapter
                </Button>

                <FormItem className={ 'label' } label='Workspaces'>
                    <SearchSelectInput
                        options={ workspaceOptions }
                        value={parameters.workspace_id}
                        onChange={ newValue => handleFieldChange('workspace_id', newValue) }
                        placeholder='Select the workspace'
                        variant='standard'
                    />
                </FormItem>

                <BookInsightsDateTimePicker
                    dateLabel={ 'Publication Date' }
                    timeLabel={ 'Publication Time' }
                    defaultValue={parameters.publication_at }
                    onChange={ (newValue) => handleFieldChange('publication_at', newValue) }
                />
            </MainFormItems>

            <StyledFlexBox>
                {purpose === 'edit' ? (
                    <>
                        <Button
                            onClick={deleteItem}
                            variant='contained'
                            size='large'
                            color='secondary'
                        >
                            Delete
                        </Button>
                        <Button
                            variant='contained'
                            size='large'
                            color='primary'
                            onClick={saveChanges}
                        >
                            Update
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            onClick={ignoreChanges}
                            variant='contained'
                            size='large'
                            color='customGray'
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            size='large'
                            color='primary'
                            onClick={saveChanges}
                        >
                            Publish
                        </Button>
                    </>
                )}
            </StyledFlexBox>
        </StyledLayout>
    )
}