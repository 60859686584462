import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const StyledSidebarSelect = styled(Box)({
    position: 'relative',
    width: '100%',

    '& .content': {
        width: '100%',
        background: "#3e474D",
        borderRadius: '0.25rem',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '3.125rem',
        padding: '0.438rem 0.625rem',
        boxShadow: '-1px 0px 0px 0px #FFFFFF33 inset',
        border: '1px solid #FFFFFF',
        cursor: 'pointer',

        '& .leftSide': {
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',

            "& .noneImage": {
                width: '1.75rem',
                height: '1.75rem',
                minWidth: '1.75rem',
                minHeight: '1.75rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                background: '#333F49',
            }
        },
    },

    '& .content.active': {
        '& svg': {
            transform: 'rotate(180deg)',
        }
    },


    '& .image ': {
        background: 'red',
        borderRadius: '50%',
        width: '1.75rem',
        height: '1.75rem',
    },

    '& .text': {
        fontWeight: '500',
        fontSize: '0.875rem',
        lineHeight: '22px'
    },

    '& .dropdown': {
        marginTop: '3.5rem',
        background: '#3e474D',
        zIndex: '9',
        borderRadius: '0.25rem',
        position: 'absolute',
        width: '100%',
        top: 0,
        border: '1px solid #FFFFFF80',
        cursor: 'pointer',

        '& .image': {
            width: '1.75rem',
            height: '1.75rem',
            minWidth: '1.75rem',
            minHeight: '1.75rem',
        },

        "& .noneImage": {
            width: '1.75rem',
            height: '1.75rem',
            minWidth: '1.75rem',
            minHeight: '1.75rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            background: '#333F49',
        },

        '&__block': {
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            padding: '0.625rem',

            '&:hover': {
                background: '#FFFFFF0D'
            }
        },

        '&__block.active': {
            margin: '0 0.625rem',
            borderRadius: '0.5rem',
            background: '#2CB5B44D',
        },
    }
})