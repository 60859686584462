import { useState } from "react";
import { Layout } from "../../components";
import { BookInsightHeader, BookInsightsTable } from "../../components/BookInsights";

export const BookInsights = () => {
    const [openCategoriesDrawer, setOpenCategoriesDrawer] =
        useState<boolean>(false);

    const showCategoriesDrawer = () => {
        setOpenCategoriesDrawer(true);
    };

    const hideCategoriesDrawer = () => {
        setOpenCategoriesDrawer(false);
    };
    return (
        <Layout>
            <BookInsightHeader openCategoriesDrawer={showCategoriesDrawer}/>

            <BookInsightsTable/>
        </Layout>
    )
}