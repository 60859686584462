import { IRoutes } from "../types";
import * as Pages from "../../../pages";

export const Cheats: IRoutes = {
    cheats: {
        path: '/cheats',
        title: 'Cheats',
        element: <Pages.Cheats />,
    },
    createCheats: {
        path: '/cheats/create',
        title: 'create cheats',
        element: <Pages.CreateCheats/>
    },
    editCheats: {
        path: '/cheats/edit/:id',
        title: 'edit book-insights',
        element: <Pages.EditCheats />,
    },
}