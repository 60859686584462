import { http, methods } from "./config";
import { ISpaceReq } from "../types";

export const getAdminsList = () => {
    const config = {
        method: methods.get,
        url: `/admins`,
    };
    return http(config);
}

export const createAdmins = (data: any) => {
    const config = {
        method: methods.post,
        url: `/admins`,
        data,
    };
    return http(config);
};

export const updateAdmins = (data: any) => {
    const config = {
        method: methods.put,
        url: `/admins/${data.id}`,
        data,
    };
    return http(config);
};

export const deleteAdmins = (adminId: string | number) => {
    const config = {
        method: methods.delete,
        url: `/admins/${adminId}`,
    };
    return http(config);
};

export const getAdminItem = (adminId: string | number) => {
    const config = {
        method: methods.get,
        url: `/admins/${adminId}`,
    };
    return http(config);
};