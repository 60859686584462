import * as Pages from '../../../pages'
import { IRoutes } from '../types';

export const Admins: IRoutes = {
    admins: {
        path: '/admins',
        title: 'admins',
        element: <Pages.Admins />,
    }
}
