/* eslint-disable default-case */
/* eslint-disable no-loop-func */
/* eslint-disable no-case-declarations */
import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import AttachesTool from '@editorjs/attaches';
import Tooltip from 'editorjs-tooltip';
import Paragraph from '@editorjs/paragraph';
import CodeBox from '@bomdi/codebox';
import { COLORS } from "../../../utils";
import { uploadMedia } from "../../../api/common";
import { MEDIA_TYPES } from "../../../utils";

const CodexEditor = require('@editorjs/editorjs');
const escapeHtml = require('escape-html');

const errorMessageImage =
  'Не удалось загрузить изображение. Максимальный размер картинки 1мб.';

export const localization = {
  messages: {
    ui: {
      popover: {
        Filter: 'Search',
        'Nothing found': 'Nothing found',
      },
      blockTunes: {
        toggler: {
          'Click to tune': 'Click to tune',
          'or drag to move': 'or drag to move',
        },
      },
      inlineToolbar: {
        converter: {
          'Convert to': 'Convert to',
        },
      },
      toolbar: {
        toolbox: {
          Add: 'Add',
        },
      },
    },

    tools: {
      Filter: 'asd',
      filter: {
        Filter: 'asd',
      },
      list: {
        Unordered: 'Маркированный',
        Ordered: 'Нумерованный',
      },
      header: {
        'Heading 1': 'Heading 1',
        'Heading 2': 'Heading 2',
        'Heading 3': 'Heading 3',
        'Heading 4': 'Heading 4',
        'Heading 5': 'Heading 5',
        'Heading 6': 'Heading 6',
      },
      image: {
        'Select an Image': 'Select an Image',
        'With border': 'With border',
        'Stretch image': 'Stretch image',
        'With background': 'With background',
        Caption: 'Подпись',
        'Add Image': 'Add Image',
        'Couldn’t upload image. Please try another.': 'Couldn’t upload image. Please try another.'
      },
      quote: {
        'Align Left': 'Align Left',
        'Align Center': 'Align Center',
        'Align Right': 'Align Right',
      },
      table: {
        'Add column to left': 'Add column to left',
        'Add column to right': 'Add column to righ',
        'Delete column': 'Delete column',
        'Add row above': 'Add row above',
        'Add row below': 'Add row below',
        'Delete row': 'Delete row',
        'With headings': 'With headings',
        'Without headings': 'Without headings',
      },


      gallery: {
        Caption: 'Название',
        'Can not upload an image, try another': 'Can not upload an image, try another', //
        'Add Image': 'Add Imag',
        ' Add Image': 'Add Image',
      },
      toggle: {
        Toggle: 'Toogle',
      },
      'toggle-block': {
        'Toggle block': 'Toggle block',
        Content: 'Content',
        Toggle: 'Toggle',
      },
      link: {
        'Add a link': 'Add a link',
      },
      tooltip: {
        'Add a tooltip': 'Add a tooltip',
      },
      changeCase: {
        'Change Case': 'Change Case',
        titleCase: 'Title sCase',
        'lower case': 'lower scase',
        upperCase: 'UPPER sCASE',
        localeLowerCase: 'localé lower casé',
        localeUpperCase: 'LöCALE UPPER CASE',
        sentenceCase: 'Sentence case',
        toggleCase: 'tOOGLE cASE',
      },
    },

    toolNames: {
      Text: 'Text',
      Heading: 'Heading',
      List: 'List',
      Warning: 'Warning',
      Checklist: 'Checklist',
      Quote: 'Quote',
      Image: 'Image',
      Columns: 'Columns',
      Attachment: 'Attachment',
      Table: 'Table',
      Link: 'Link, click "Enter"',
      Marker: 'Marker',
      Carousel: 'Carousel',
      Bold: 'Bold',
      Italic: 'Italic',
      InlineCode: 'InlineCode',
      Toggle: 'Toggle',
      Color: 'Color',
      'Convert To': 'Convert To',
      Hyperlink: 'Hyperlink',
      Underline: 'Underline',
      ChangeCase: 'change case',
      Tooltip: 'Подсказка, чтобы закрепить нажмите "Enter"',
      Layout: 'Layout',
      'Add Image': 'Add Image',
      Strikethrough: 'Strikethrough',
    },

    blockTunes: {
      delete: {
        Delete: 'Delete',
        'Click to delete': 'Click to delete',
      },
      moveUp: {
        'Move up': 'Move up',
      },
      moveDown: {
        'Move down': 'Move down',
      },
    },
  },
};

const downloadFile = async file => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('type', MEDIA_TYPES.NEWS_CONTENT);
  try {
    const response = await uploadMedia(formData);
    return response;
  } catch (err) {
    return err;
  }
};

export const tools = {
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    placeholder: 'Text',
    preserveBlank: true,
  },
  header: {
    class: Header,
    inlineToolbar: true,
    toolbox: {
      title: 'Add a subtitle',
    },
  },
  list: {
    class: List,
    inlineToolbar: true,
    toolbox: {
      title: 'List',
    },
  },
  codeBox: {
    class: CodeBox,
    config: {
      themeURL: 'https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@9.18.1/build/styles/dracula.min.css', // Optional
      themeName: 'atom-one-dark', // Optional
      useDefaultTheme: COLORS.LIGHT_BLUE // Optional. This also determines the background color of the language select drop-down
    }
  },
  image: {
    class: ImageTool,
    config: {
      field: 'file_content',
      uploader: {
        async uploadByFile(file) {
          return downloadFile(file).then(response => {
            if (!response.startsWith('http')) {
              return { success: 0, file: {} };
            }
            return {
              success: 1,
              file: {
                url: response,
                name: file.name,
                title: file.name,
                size: file.size,
              },
            };
          });
        },
      },
    },
  },
  embed: {
    class: Embed,
    config: {
      services: {
        youtube: true,
        coub: true,
      },
    },
  },
  quote: {
    class: Quote,
    inlineToolbar: true,
    toolbox: {
      title: 'Quote',
    },
    config: {
      quotePlaceholder: 'enter a quote',
      captionPlaceholder: 'enter title',
    },
  },
  // color: {
  //   class: ColorPlugin,
  // },
  // attaches: {
  //   class: AttachesTool,
  //   config: {
  //     field: 'file_content',
  //     buttonText: 'Загрузить файл',
  //     errorMessage: 'Не удалось загрузить файл. Максимальный размер файл 1мб.',
  //     uploader: {
  //       async uploadByFile(file) {
  //         return downloadFile(file).then(response => {
  //           if (!response.file) {
  //             return { success: 0, file: {} };
  //           }
  //           return {
  //             success: 1,
  //             file: {
  //               url: response.file.url,
  //               name: file.name,
  //               title: file.name,
  //               size: file.size,
  //             },
  //           };
  //         });
  //       },
  //     },
  //   },
  // },
  tooltip: {
    class: Tooltip,
  },
};

const extractTextFromHTML = (htmlString) => {
  // Replace <br> tags with newline characters
  const stringWithNewlines = htmlString.replace(/<br\s*\/?>/gi, '\n');

  // Create a new DOM parser
  const parser = new DOMParser();
  // Parse the HTML string into a document
  const doc = parser.parseFromString(stringWithNewlines, 'text/html');
  // Extract and return the text content
  return doc.body.textContent || "";
};

export function generateBlockHtml(block) {
  let html = '';

  switch (block.type) {
    case 'paragraph':
      const alignStyle = block.tunes?.anyTuneName
        ? `style="text-align: ${block.tunes.anyTuneName.alignment}"`
        : '';
      html += `<p ${alignStyle}>${block.data.text}</p>`;
      break;
    case 'codeBox':
      html+= extractTextFromHTML(block.data.code)
      break;
    case 'header':
      html += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
      break;
    case 'list':
      const tag = block.data.style === 'ordered' ? 'ol' : 'ul';
      let itemsHtml = '';
      block.data.items.forEach(item => {
        itemsHtml += `<li>${item}</li>`;const downloadFile = async file => {
          const formData = new FormData();
          formData.append('image', file);
          formData.append('type', MEDIA_TYPES.NEWS_CONTENT);
          try {
            const response = await uploadMedia(formData);
            return response;
          } catch (err) {
            return err;
          }
        };

      });
      html += `<${tag}>${itemsHtml}</${tag}>`;
      break;
    case 'collapse':
      const title = block.data.title || '';
      const content = block.data.content || '';

      return `
        <details>
          <summary>${title}</summary>
          <p>${content}</p>
        </details>
          `;
    case 'ckeditor':
      const itemText = block.data.text.replace(/contenteditable="true"/g, '');

      html += `${itemText}`;
      break;
    case 'table':
      const hasHeading = !!block.data.withHeadings;
      let tableHtml = '<div class="table-container"><table>';
      // добавляем заголовок таблицы, если есть
      if (hasHeading && block.data.content.length > 0) {
        tableHtml += '<thead><tr>';
        block.data.content[0].forEach(header => {
          tableHtml += `<th>${header}</th>`;
        });
        tableHtml += '</tr></thead>';
      }
      // добавляем строки таблицы
      tableHtml += '<tbody>';
      for (let i = 1; i < block.data.content.length; i++) {
        tableHtml += '<tr>';
        block.data.content[i].forEach(cell => {
          tableHtml += `<td>${cell}</td>`;
        });
        tableHtml += '</tr>';
      }
      tableHtml += '</tbody></table></div>';
      html += tableHtml;
      break;
    case 'image':
      const stretchedClass = block.data.stretched ? ' stretched' : '';
      const withBackgroundClass = block.data.withBackground
        ? ' with-background'
        : '';
      const withBorderClass = block.data.withBorder ? ' with-border' : '';
      const bigClass = block.data.big ? ' big' : '';
      const mediumClass = block.data.medium ? ' medium' : '';
      const smallClass = block.data.small ? ' small' : '';
      const imageClass = `image${stretchedClass}${withBackgroundClass}${withBorderClass}${bigClass}${mediumClass}${smallClass}`;

      // html += `
			// <a href="${escapeHtml(
      //   block.data.file.url,
      // )}" target="_blank" data-fancybox="gallery" data-caption="${
      //   block.data.caption || ''
      // }">
			// 	<img src="${escapeHtml(block.data.file.url)}" alt="${escapeHtml(
      //   block.data.caption,
      // )}" class="${imageClass}">
			// </a>
			// `;
      html += block.data.file.url;
      break;
    case 'quote':
      html += `<p>${block.data.text}</p>  ${block.data.caption}`;
      break;
    case 'embed':
      switch (block.data.service) {
        case 'vimeo':
          html += `<iframe src="${block.data.embed}" height="${block.data.height}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
          break;
        case 'youtube':
          html += `<iframe width="${block.data.width}" height="${block.data.height}" src="${block.data.embed}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
          break;
      }
      break;
    case 'attaches':
      const index = block.data.file.title.indexOf('.');
      let result = block.data.file.title;
      if (index !== -1) {
        result = block.data.file.title.substring(index + 1);
      }
      html += `
			<div class="cdx-attaches cdx-attaches--with-file">
				<div class="cdx-attaches__file-icon">
					<p>${result}</p>
				</div>
				<div class="cdx-attaches__file-info">
					<div class="cdx-attaches__title">${
            block.data.title || block.data.file.title
          }</div>
					<div class="cdx-attaches__size" data-size="KiB">${(
            block.data.file.size / 1024
          ).toFixed(1)}</div>
				</div>
				<a class="cdx-attaches__download-button" href="${
          block.data.file.url
        }" target="_blank"></a>
			</div>`;
      break;
    case 'warning':
      html += `<div class="warning">${escapeHtml(
        block.data.title,
      )} ${escapeHtml(block.data.message)}</div>`;
      break;
    case 'checklist':
      let checklistHtml = '<ul>';
      block.data.items.forEach(item => {
        const checked = item.checked ? 'checked' : '';
        checklistHtml += `<li><input type="checkbox" ${checked} disabled>${item.text}</li>`;
      });
      checklistHtml += '</ul>';
      html += checklistHtml;
      break;
    case 'gallery':
      const countItemEachRow =
        block.data.countItemEachRow || block?.data?.files?.length;

      let galleryHtml = '<div class="gallery">';
      block?.data?.files?.forEach((item, fileIndex) => {
        const isFirstInRow = fileIndex % countItemEachRow === 0;
        const isLastInRow =
          (fileIndex + 1) % countItemEachRow === 0 ||
          fileIndex + 1 === block?.data?.files?.length;

        const galleryItemHtml = `
					<a href="${item.url}" target="_blank" data-fancybox="gallery" data-caption="${
          item.title || ''
        }">
						<img src="${item.url}" alt="${item.title || ''}" class="gallery-item">
					</a>
				`;

        if (isFirstInRow) {
          galleryHtml += '<div class="gallery-row row">';
        }

        galleryHtml += `<div class="gallery-col col">${galleryItemHtml}</div>`;

        if (isLastInRow) {
          galleryHtml += '</div>';
        }
      });
      galleryHtml += '</div>';

      html += galleryHtml;
      html += `<span>${block?.data?.caption || ''}</span>`;
      break;
    case 'columns':
      const columnHtml = block.data.cols.reduce((acc, col) => {
        const colHtml = col.blocks.reduce((colAcc, colBlock) => {
          switch (colBlock.type) {
            case 'paragraph':
              const alStyle = colBlock.data.tunes?.anyTuneName
                ? `style="text-align: ${colBlock.data.tunes.anyTuneName.alignment}"`
                : '';
              return `${colAcc}<p ${alStyle}>${colBlock.data.text}</p>`;
            case 'header':
              return `${colAcc}<h${colBlock.data.level}>${colBlock.data.text}</h${colBlock.data.level}>`;
            default:
              console.warn(`Unknown block type: ${colBlock.type}`);
              return colAcc;
          }
        }, '');
        return `${acc}
						<div class="columns__block ce-editorjsColumns_col">
							<div class="ce-block__content">${colHtml}</div>
						</div>
					`;
      }, '');
      html += `<div class="columns ce-editorjsColumns_wrapper">${columnHtml}</div>`;
      break;
    case 'layout':
    case 'twoColumns':
    case 'threeColumns':
    case 'fourColumns':
      const layoutContent = block.data.itemContent;

      const layoutHtml = Object.keys(layoutContent)
        .map(key => {
          const colHtml = EditorJsToHtml({ blocks: layoutContent[key].blocks });

          return `
						<div class="columns__block ce-editorjsColumns_col">
							<div class="ce-block__content">${colHtml}</div>
						</div>
					`;
        })
        .join('');

      const columnClass = `columns-${Object.keys(layoutContent).length}`;
      html += `<div class="columns ${columnClass} ce-editorjsColumns_wrapper">${layoutHtml}</div>`;
      break;
    default:
      console.warn(`Неизвестный тип блока: ${block.type}`);
  }

  return html;
}

export function EditorJsToHtml(data) {
  let html = '';

  const isToggleItems = data?.blocks?.findIndex(i => i.type === 'toggleBlock');

  data?.blocks?.forEach(block => {
    if (block.type === 'toggleBlock') {
      const statusClass =
        block.data.status === 'open' ? 'is-opened' : 'is-closed';

      html += `
				<div class="toggle-block ${statusClass}">
					<div class="toggle-block__title">${escapeHtml(block.data.text)}</div>`;

      if (block?.data?.items) {
        html += '<div class="toggle-block__content">';
        data?.blocks?.forEach((nestedBlock, idx) => {
          if (
            nestedBlock.type === 'toggleBlock' ||
            (isToggleItems && isToggleItems >= idx)
          )
            return;

          html += generateBlockHtml(nestedBlock);
        });
        html += '</div>';
      }
      html += '</div>';
    } else {
      if (isToggleItems !== undefined) {
        const count = data?.blocks[isToggleItems]?.data?.items;

        for (let i = 1; i <= count; i++) {
          if (!!count && block?.id === data?.blocks[isToggleItems + i]?.id)
            return;
        }
      }

      html += generateBlockHtml(block);
    }
  });

  return html;
}

export const sanitizeAndPrepareHtml = htmlString => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  const scripts = div.getElementsByTagName('script');
  const iframes = div.getElementsByTagName('iframe');

  for (let i = 0; i < scripts.length; i++) {
    scripts[i].parentNode.removeChild(scripts[i]);
  }

  for (let i = 0; i < iframes.length; i++) {
    iframes[i].parentNode.removeChild(iframes[i]);
  }

  const string = div.innerHTML;
  return replaceNewlines(string);
};

const replaceNewlines = text => {
  return text.replaceAll('\\n', '<br>');
};
