export const createLink = (
    path: string,
    replaceValue: string | number,
    replaceField: string,
    // params?: Record<string, string | number>,
) => {
    // if (params) {
    //     const queryParams = Object.keys(params)
    //         .map(key => `${key}=${params[key]}`)
    //         .join('&');
    //     return `${path.replace(
    //         `:${replaceField}`,
    //         replaceValue.toString(),
    //     )}?${queryParams}`;
    // }
    return path.replace(`:${replaceField}`, replaceValue.toString());
};

export const getErrorMessage = (error: any) => {
    if (!error) {
        return null;
    }
    if (error.response) {
        return error.response.data?.error?.message;
    }
    return error.message;
};