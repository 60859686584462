import { FC, useEffect, useState } from "react";
import {
    Drawer,
    ModalTitle,
    StyledFlexBox,
    StyledBadge,
    StyledSwitch,
    DrawerHeader,
    DrawerFormBody,
    ImageInput,
    FormItem,
    Button,
} from "../../../UI";
import { TextField } from "@mui/material";
import {
    useCreateWorkspace,
    useCreateWorkspaceDetails, useDeleteWorkspace,
    useDeleteWorkspaceDetails,
    useGetSpaces,
    useUpdateWorkspace, useUpdateWorkspaceDetails
} from "../../../hooks";
import { ISpaceReq } from "../../../types";
import { CloseIcon, EyeIcon, DeleteIcon } from "../../../assets";
import { COLORS } from "../../../utils";
import { DeleteModal } from "../../DeleteModal";
import { MEDIA_TYPES } from "../../../utils";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { deleteWorkspaceDetails, getWorkspaces } from "../../../api";

const TRANSLATIONS = {
    add: {
        title: 'Workspace Creation',
    },
    edit: {
        title: 'Workspace Edit',
    },
    preview: {
        title: 'Preview',
    },
};


const DEFAULT_VALUES: ISpaceReq = {
    logo: '',
    name: '',
    description: '',
    is_active: true,
};


interface IAddEditGroupDrawerProps {
    open: boolean;
    onClose: () => void;
    purpose?: 'add' | 'edit';
    id?: number | string;
}

export const AddEditWorkspaceDrawer: FC<IAddEditGroupDrawerProps> = ({
    open,
    onClose,
    id = '',
    purpose = 'add',
}) => {
    const location = useLocation();
    const isAddDetail = location.search.includes('edit2')

    const [workspaceInfo, setWorkspaceInfo] = useState<any>(DEFAULT_VALUES)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const {locale} = useSelector((state: any) => state.locales)
    const {data: workspaces} = useSelector((state: any) => state.workspaces)

    const { mutateAsync: createWorkspace } = useCreateWorkspace();
    const { mutateAsync: updateWorkspace } = useUpdateWorkspace();

    const { mutateAsync: createWorkspaceDetails } = useCreateWorkspaceDetails();
    const { mutateAsync: updateWorkspaceDetails } = useUpdateWorkspaceDetails();
    const { mutateAsync: deleteWorkspaceDetails } = useDeleteWorkspaceDetails();

    const getWorkspace: any = workspaces && workspaces?.find((workspace: any) => workspace.id == id)

    const ignoreChanges = () => {
        onClose();
    };

    const handleFieldChange = <T extends keyof ISpaceReq>(
        fieldName: T,
        newValue: ISpaceReq[T],
    ) => {
        setWorkspaceInfo((prevSpaceInfo: any) => ({
            ...prevSpaceInfo,
            [fieldName]: newValue,
        }));
    };

    useEffect(() => {
        if (purpose === 'edit') {
            setWorkspaceInfo({
                id: getWorkspace?.id,
                name: getWorkspace?.name || '',
                description: getWorkspace?.description || '',
                is_active: getWorkspace?.is_active || true,
                logo: getWorkspace?.logo || '',
            })
        }
        else {
            setWorkspaceInfo(DEFAULT_VALUES)
        }
    }, [purpose])


    const openDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const saveChanges = () => {
        const updatedData = {
            id: getWorkspace.workspace_detail_id,
            workspace_id: getWorkspace?.id.toString(),
            locale_id: locale,
            description: workspaceInfo?.description,
            name: workspaceInfo?.name,
            logo: workspaceInfo?.logo,
        }

        const createData = {
            workspace_id: getWorkspace.id,
            name: workspaceInfo?.name,
            description: workspaceInfo?.description,
            logo: workspaceInfo?.logo,
            is_active: getWorkspace.is_active,
            locale_id: locale
        }

        if (isAddDetail) {
            createWorkspaceDetails(createData)
                .then(() => {
                    onClose();
                    setWorkspaceInfo(DEFAULT_VALUES)
                })
                .catch(() => {});
        }
        else {
            updateWorkspaceDetails(updatedData)
                .then(() => {
                    onClose();
                    setWorkspaceInfo(DEFAULT_VALUES)
                })
                .catch(() => {});
        }
    };

    const handleDeleteWorkspaceDetail = () => {
        deleteWorkspaceDetails(getWorkspace?.workspace_detail_id)
            .then(() => {
                closeDeleteModal();
                onClose();
            })
            .catch(() => {});
    };


    return (
        <Drawer open={open} onClose={ignoreChanges}>
            <DeleteModal
                open={showDeleteModal}
                onConfirm={handleDeleteWorkspaceDetail}
                onClose={closeDeleteModal}
                title='Delete Workspace Detail'
                description='Are you sure to delete workspace detail?'
            />
            <DrawerHeader>
                <StyledFlexBox>
                    <ModalTitle title={TRANSLATIONS[purpose].title}/>
                    <StyledBadge
                        is_active={workspaceInfo.is_active}
                        style={{ marginBottom: 0 }}
                    />
                </StyledFlexBox>
                <StyledFlexBox>
                    {/*<StyledSwitch*/}
                    {/*    checked={workspaceInfo.is_active}*/}
                    {/*    onChange={() => handleFieldChange('is_active', !workspaceInfo.is_active)}*/}
                    {/*    className='switch'*/}
                    {/*/>*/}
                    <CloseIcon onClick={ignoreChanges} />
                </StyledFlexBox>
            </DrawerHeader>

            <DrawerFormBody>
                <StyledFlexBox>
                    <ImageInput
                        inputId='workspaceLogo'
                        title='Add a logo'
                        descriptionWithoutImage='Загрузите изображение (512x512 px, до 1 мб)'
                        withDescription
                        image={workspaceInfo.logo}
                        setImage={newImg => handleFieldChange('logo', newImg)}
                        mediaType={MEDIA_TYPES.WORKSPACE_LOGO}
                        imageFixedWidth={512}
                        imageFixedHeight={512}
                    />
                </StyledFlexBox>

                <h2>About the workspace</h2>
                <FormItem label='Name'>
                    <TextField
                        className={'textField'}
                        variant='standard'
                        color={'primary'}
                        placeholder='Enter the name'
                        value={workspaceInfo.name}
                        onChange={e => handleFieldChange('name', e.target.value)}
                    />
                </FormItem>
                <FormItem label={'Description'}>
                    <TextField
                        className={'textField'}
                        variant='standard'
                        color={'primary'}
                        placeholder='Enter the description'
                        value={workspaceInfo.description}
                        onChange={e => handleFieldChange('description', e.target.value)}
                    />
                </FormItem>

                <StyledFlexBox className='buttons'>
                    <StyledFlexBox>
                        <Button
                            startIcon={<EyeIcon />}
                            onClick={() => ('')}
                            color='customDarkerGray'
                            size='large'
                        >
                            Preview
                        </Button>
                    </StyledFlexBox>

                    <StyledFlexBox >
                        {!isAddDetail && (
                            <Button
                                onClick={openDeleteModal}
                                variant='contained'
                                size='large'
                                color='secondary'
                                startIcon={<DeleteIcon color={COLORS.WHITE} />}
                                sx={{
                                    color: COLORS.WHITE,
                                }}
                            >
                                Delete
                            </Button>
                        )}
                        {purpose === 'add' && (
                            <Button
                                onClick={ignoreChanges}
                                variant='contained'
                                size='large'
                                color='customDarkerGray'
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            variant='contained'
                            size='large'
                            color='primary'
                            onClick={saveChanges}
                        >
                            {!isAddDetail  ? 'Save' : 'Create'}
                        </Button>
                    </StyledFlexBox>
                </StyledFlexBox>
            </DrawerFormBody>
        </Drawer>
    )
}