import { FC, MouseEvent, useMemo } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { COLORS } from '../../../utils';
import { FilterCategory } from './FilterCategory';

const headCells = [
    {
        id: 'title',
        label: 'Name',
        sortable: true,
    },
    {
        id: 'workspaces',
        label: 'AllWorkspaces',
        sortable: true,
    },
    {
        id: 'publication_at',
        label: 'Status',
        sortable: true,
    },
    {
        id: 'reading_time',
        label: 'Read Time',
        sortable: true,
    },
    {
        id: 'rating',
        label: 'Rating',
        sortable: true,
    },
    {
        id: 'actions',
        label: 'Control',
        sortable: false,
    },
];

interface IInsights {
    title: string,
    description: string,
    image: string,
    chapters: any,
    cover: string,
    cover_hex: string,
    reading_time: number,
    publication_at: string,
    rating: number,
    id: any,
    workspace: any
}

export const BookInsightsTableHead: FC<any> = ({
    onRequestSort,
    order,
    orderBy,
}) => {
    const createSortHandler: any =
      (property: keyof IInsights | 'actions') =>
        (event: MouseEvent<unknown>) => {
            if (property === 'actions') return;
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                { headCells.map((headCell) => {
                    if (!headCell.sortable)
                        return (
                            <TableCell key={ headCell.id } align='center' padding='normal'>
                                { headCell.label }
                            </TableCell>
                        );
                    // if (headCell.id === 'space')
                    //   return (
                    //     <TableCell
                    //       key={headCell.id}
                    //       align='center'
                    //       padding='normal'
                    //       sortDirection={orderBy === headCell.id ? order : false}
                    //     >
                    //       <FilterSpace className='filterAtTable' />
                    //     </TableCell>
                    //   );
                    if (headCell.id === 'workspaces')
                        return (
                            <TableCell
                                key={ headCell.id }
                                align='center'
                                padding='normal'
                                sortDirection={ orderBy === headCell.id ? order : false }
                                className={'head__category'}
                            >
                                <FilterCategory />
                            </TableCell>
                        );
                    return (
                        <TableCell
                            key={ headCell.id }
                            align='center'
                            padding='normal'
                            sortDirection={ orderBy === headCell.id ? order : false }
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                sx={ {
                                    '&.Mui-active': {
                                        '& .MuiTableSortLabel-icon': {
                                            color: orderBy === headCell.id ? COLORS.SKY_BLUE : undefined,
                                        },
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        opacity: 0.3,
                                    },
                                } }
                            >
                                { headCell.label }
                            </TableSortLabel>
                        </TableCell>
                    );
                }) }
            </TableRow>
        </TableHead>
    );
};
