import { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useDrawer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showAddDrawer, setShowAddDrawer] = useState<boolean>(false);

  const openAddDrawer = () => {
    setShowAddDrawer(true);
  };

  const closeAddDrawer = () => {
    setShowAddDrawer(false);
  };

  const viewDrawerId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('view') || '';
  }, [location.search]);

  const showViewDrawer = useMemo(() => {
    return Boolean(viewDrawerId);
  }, [viewDrawerId]);

  const changeViewDrawer = (id: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('view', id);
    navigate(`?${searchParams.toString()}`);
  };

  const hideViewDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('view');
    navigate(`?${searchParams.toString()}`);
  };

  const editDrawerId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('edit') || searchParams.get('edit2') || '';
  }, [location.search]);

  const showEditDrawer = useMemo(() => {
    return Boolean(editDrawerId);
  }, [editDrawerId]);

  const changeEditDrawer = (id: number | string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('edit', id.toString());
    navigate(`?${searchParams.toString()}`);
  };

  const hideEditDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('edit')
    searchParams.delete('edit2')
    navigate(`?${searchParams.toString()}`);
  };

  const purpose: 'add' | 'edit' = useMemo(() => {
    return editDrawerId ? 'edit' : 'add';
  }, [editDrawerId]);

  const hideAddEditDrawer = () => {
    if (editDrawerId) hideEditDrawer();
    else closeAddDrawer();
  };

  const showAddEditDrawer = useMemo(() => {
    return showEditDrawer || showAddDrawer;
  }, [showEditDrawer, showAddDrawer]);

  const drawerStep = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('step') || '';
  }, [location]);

  const changeDrawerStep = (step: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('step', step);
    navigate(`?${searchParams.toString()}`);
  };

  const hideDrawerStep = () => {
    closeAddDrawer();
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('step');
    searchParams.delete('edit');
    navigate(`?${searchParams.toString()}`);
  };

  const subDrawer = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('sub') || '';
  }, [location]);

  const changeSubDrawer = (sub: string, id?: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('sub', sub);
    if (id) searchParams.set('sub-edit', id);
    navigate(`?${searchParams.toString()}`);
  };

  const hideSubDrawer = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('sub');
    searchParams.delete('sub-edit');
    navigate(`?${searchParams.toString()}`);
  };

  const subEditDrawerId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('sub-edit') || '';
  }, [location.search]);

  const showSubEditDrawer = useMemo(() => {
    return Boolean(subEditDrawerId);
  }, [subEditDrawerId]);

  const subPurpose: 'add' | 'edit' = useMemo(() => {
    return subEditDrawerId ? 'edit' : 'add';
  }, [subEditDrawerId]);

  return {
    showAddDrawer,
    openAddDrawer,
    closeAddDrawer,
    viewDrawerId,
    showViewDrawer,
    changeViewDrawer,
    hideViewDrawer,
    editDrawerId,
    showEditDrawer,
    changeEditDrawer,
    hideEditDrawer,
    purpose,
    hideAddEditDrawer,
    showAddEditDrawer,
    subDrawer,
    changeSubDrawer,
    hideSubDrawer,
    drawerStep,
    changeDrawerStep,
    hideDrawerStep,
    subEditDrawerId,
    showSubEditDrawer,
    subPurpose,
  };
};
