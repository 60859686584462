import { useMutation, useQuery, useQueryClient } from "react-query";
import { AdminsResponseData, IBookInsights } from "../types";
import { createAdmins, getAdminsList, updateAdmins, deleteAdmins, getAdminItem } from "../api/admins";
import { deleteWorkspace, updateWorkspace } from "../api";


export const useGetAdminsList = () => {
    return useQuery<AdminsResponseData, []>(
        [ 'admins' ],
        async () => {
            const res = await getAdminsList();
            return res;
        },
        {
            staleTime: 60 * 60 * 1000,
            retry: false,
        },
    );
};

export const useCreateAdmins = () => {
    const queryClient = useQueryClient();
    return useMutation(createAdmins, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['admins'] });
        },
        onError: () => {},
    });

};

export const useUpdateAdmin = () => {
    const queryClient = useQueryClient();
    return useMutation(updateAdmins, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['admins'] });
        },
        onError: () => {},
    });
};

export const useDeleteAdmin = () => {
    const queryClient = useQueryClient();
    return useMutation(deleteAdmins, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['admins'] });
        },
        onError: () => {},
    });
};

export const useGetAdminItem = (id: string | number) => {
    return useQuery<any, []>(
        [ 'admins', id],
        async () => {
            const res = await getAdminItem(id);
            return res;
        },
        {
            staleTime: 60 * 60 * 1000,
            retry: false,
        },
    );
};