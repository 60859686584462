import { FC } from 'react';
import { PageHeader, Button } from '../../../UI';
import { PlusIcon } from '../../../assets';
import { ROUTES } from '../../../app';

interface INewsHeaderProps {
    openCategoriesDrawer: () => void;
}

export const BookInsightHeader: FC<INewsHeaderProps> = ({ openCategoriesDrawer }) => {
    return (
        <PageHeader title='Book Insights'>
            {/*<GlobalSearch size='medium' />*/}
            {/*<Button*/}
            {/*    variant='contained'*/}
            {/*    color='customDarkerGray'*/}
            {/*    onClick={openCategoriesDrawer}*/}
            {/*    className='withFixedWidth'*/}
            {/*>*/}
            {/*    Категории*/}
            {/*</Button>*/}
            <Button
                variant='contained'
                href={ROUTES.createBookInsights}
                startIcon={<PlusIcon />}
                className='withFixedWidth'
            >
                Add
            </Button>
        </PageHeader>
    );
};
