import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialState: any = {
    data: [],
    isLoading: true,
}

const workspacesReducer = createSlice({
    name: 'workspaces',
    initialState: initialState,
    reducers: {
        changeWorkspaces: (state, action: PayloadAction<any>) => {
            state.data = action.payload.data;
            state.isLoading = action.payload.isLoading
        },
    },
})

export const { changeWorkspaces } = workspacesReducer.actions

export default workspacesReducer.reducer