import { FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from "../../utils";

const StyledContent = styled(Box)({
  display: 'flex',
  gap: '1.875rem',
  width: '100%',
  color: COLORS.WHITE,
  '& .MuiFormControl-root ': {
    color: 'red',
    background: 'red'
  },
});

interface IDrawerBodyProps {
  className?: string;
  children?: React.ReactNode;
}

export const DrawerBody: FC<IDrawerBodyProps> = ({ className, children }) => {
  return <StyledContent className={className}>{children}</StyledContent>;
};
