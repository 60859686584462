import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { COLORS } from "../../utils";
import ContentLoader from "react-content-loader";

const StyledCardSkeleton = styled(Box)({
  // width: 'calc(100% / 4 - 40px - (10px / 4))',
  width: '8rem',
  display: 'flex',
  alignItems: 'center',
  height: '50px',
  justifyContent: 'center',
  // backgroundColor: COLORS.GRAY,
  // boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  padding: '20px',
})

export const TableCardSkeleton = () => {
  const isMobile = window.innerWidth < 965

  return (
    <StyledCardSkeleton>
      <ContentLoader
        title=''
        backgroundColor={COLORS.LIGHT_BLUE}
        foregroundColor={COLORS.SKY_BLUE}
        gradientRatio={ 1 }
      >
        <rect
          x={ isMobile ? 'calc(50px + 16px)' : '0' }
          y={ isMobile ? '6px' : 'calc(35px + 24px)' }
          rx='4'
          ry='4'
          width='100%'
          height={ isMobile ? '10' : '12' }
        />

        <rect
          x={ isMobile ? 'calc(50px + 16px)' : '0' }
          y={ isMobile ? 'calc(6px + 10px + 8px)' : 'calc(35px + 24px + 12px + 8px)' }
          rx='4'
          ry='4'
          width='50%'
          height={ isMobile ? '10' : '12' }
        />
      </ContentLoader>
    </StyledCardSkeleton>
  )
}