import {
    Button,
    Drawer,
    DrawerFormBody,
    DrawerHeader, FormItem, ImageInput,
    ModalTitle,
    StyledBadge,
    StyledFlexBox,
    StyledSwitch
} from "../../../UI";
import { CloseIcon, DeleteIcon } from "../../../assets";
import { COLORS, MEDIA_TYPES } from "../../../utils";
import { TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { LocalesProps } from "../../../types";
import { useCreateLocale, useDeleteLocale, useUpdateLocale } from "../../../hooks/useLocales";
import { DeleteModal } from "../../DeleteModal";

const DEFAULT_VALUES: LocalesProps= {
    image: '',
    name: '',
    slug: '',
    is_active: true,
};

interface IAddEditGroupDrawerProps {
    locales: LocalesProps[],
    open: boolean;
    onClose: () => void;
    purpose?: 'add' | 'edit';
    id?: number | string;
}

export const AddEditLocalesDrawer: FC<IAddEditGroupDrawerProps> = ({
    open,
    locales,
    onClose,
    id = '',
    purpose = 'add',
}) => {
    const [parameters, setParameters] = useState<any>(DEFAULT_VALUES);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const { mutateAsync: createLocale } = useCreateLocale();
    const { mutateAsync: updateLocale } = useUpdateLocale();
    const { mutateAsync: deleteLocale } = useDeleteLocale();

    const localeData = locales && locales.find((item) => item.id == id)

    const handleFieldChange = <T extends keyof LocalesProps>(
        fieldName: T,
        newValue: LocalesProps[T],
    ) => {
        setParameters((prevSpaceInfo: LocalesProps) => ({
            ...prevSpaceInfo,
            [fieldName]: newValue,
        }));
    };

    const ignoreChanges = () => {
        onClose();
    };

    const openDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };

    useEffect(() => {
        if (purpose === 'edit') {
            setParameters({
                id: localeData?.id,
                name: localeData?.name || '',
                slug: localeData?.slug || '',
                is_active: localeData?.is_active || true,
                image: localeData?.image || ''
            })
        }
        else {
            setParameters(DEFAULT_VALUES)
        }
    }, [purpose])

    const saveChanges = () => {
        if (purpose === 'add') {
            createLocale(parameters)
                .then(() => {
                    onClose();
                    setParameters(DEFAULT_VALUES)
                })
                .catch(() => {});
        }
        else {
            updateLocale(parameters)
                .then(() => {
                    onClose();
                    setParameters(DEFAULT_VALUES)
                })
                .catch(() => {});
        }
    };

    const handleDeleteWorkspace = () => {
        deleteLocale(parameters.id!)
            .then(() => {
                closeDeleteModal();
                onClose();
            })
            .catch(() => {});
    };

    return (
        <Drawer open={open} onClose={ignoreChanges}>
            <DeleteModal
                open={showDeleteModal}
                onConfirm={handleDeleteWorkspace}
                onClose={closeDeleteModal}
                title='Delete Workspace Detail'
                description='Are you sure to delete workspace detail?'
            />

            <DrawerHeader>
                <StyledFlexBox>
                    <ModalTitle title={'Locale Creation'}/>
                    <StyledBadge
                        is_active={parameters.is_active}
                        style={{ marginBottom: 0 }}
                    />
                </StyledFlexBox>
                <StyledFlexBox>
                    <StyledSwitch
                        checked={parameters.is_active}
                        onChange={() => handleFieldChange('is_active', !parameters.is_active)}
                        className='switch'
                    />
                    <CloseIcon onClick={ignoreChanges} />
                </StyledFlexBox>
            </DrawerHeader>

            <DrawerFormBody>
                <StyledFlexBox>
                    <ImageInput
                        inputId='workspaceLogo'
                        title='Add a logo'
                        descriptionWithoutImage='Upload an image (512x512 px, up to 1 MB)'
                        withDescription
                        image={parameters.image}
                        setImage={newImg => handleFieldChange('image', newImg)}
                        mediaType={MEDIA_TYPES.WORKSPACE_LOGO}
                        imageFixedWidth={512}
                        imageFixedHeight={512}
                    />
                </StyledFlexBox>

                <h2 style={{marginBottom: '1.25rem'}}>About the locale</h2>

                <FormItem label='Name'>
                    <TextField
                        className={'textField'}
                        variant='standard'
                        color={'primary'}
                        placeholder='Enter the name'
                        value={parameters?.name}
                        onChange={e => handleFieldChange('name', e.target.value)}
                    />
                </FormItem>

                <FormItem label='Code'>
                    <TextField
                        className={'textField'}
                        variant='standard'
                        color={'primary'}
                        placeholder='Enter the code'
                        value={parameters?.slug}
                        onChange={e => handleFieldChange('slug', e.target.value)}
                    />
                </FormItem>

                <StyledFlexBox className='buttons'>
                    <div>

                    </div>
                    <StyledFlexBox >
                        {purpose === 'edit' && (
                            <Button
                                onClick={openDeleteModal}
                                variant='contained'
                                size='large'
                                color='secondary'
                                startIcon={<DeleteIcon color={COLORS.WHITE} />}
                                sx={{
                                    color: COLORS.WHITE,
                                }}
                            >
                                Delete
                            </Button>
                        )}
                        {purpose === 'add' && (
                            <Button
                                onClick={ignoreChanges}
                                variant='contained'
                                size='large'
                                color='customDarkerGray'
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            variant='contained'
                            size='large'
                            color='primary'
                            onClick={saveChanges}
                        >
                            {purpose === 'edit' ? 'Save' : 'Create'}
                        </Button>
                    </StyledFlexBox>
                </StyledFlexBox>
            </DrawerFormBody>
        </Drawer>
    )
}