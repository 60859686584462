import { FC, RefObject, MouseEvent } from 'react';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';


const StyledButton: any = styled(MuiButton)<any>(({circleColor}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.625rem 3.3rem 0.625rem 3.3rem',
    fontFamily: '"SF Pro Display", sans-serif',
    minWidth: 'max-content',
    boxShadow: 'none',
    textTransform: 'none',
    textAlign: 'center',
    minHeight: '50px',
    whiteSpace: 'nowrap',
    position: 'relative',
    margin: '0',
    '&:hover': {
        boxShadow: 'none',
    },
    '&.withFixedWidth': {
        width: '12.25rem',
    },

    '& .spinner': {

        top: 'calc(50% - 2.2rem / 2) !important',
        left: 'calc(50% - 2.2rem / 2) !important',
        width: '2rem',
        height: '2rem',

        '& circle': {
            stroke: circleColor
        }
    }
}));

// Define a type for the color prop
export type ButtonColor =
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'customBlack'
    | 'customGray'
    | 'customWhite'
    | 'customDarkerGray'
    | 'customLightBlue'
    | 'customDarkBlue';

interface ICustomButtonProps {
    color?: ButtonColor;
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    href?: string;
    circleColor?: any,
    onClick?: (_e: MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    sx?: any;
    ref?: RefObject<HTMLButtonElement>;
    className?: string;
}

export const Button: FC<ICustomButtonProps> = ({
    variant = 'contained',
    size = 'medium',
    color = 'primary',
    circleColor = '#fff',
    href,
    onClick,
    children,
    disabled,
    startIcon,
    endIcon,
    sx,
    ref,
    className,
}) => {
    return (
        <StyledButton
            color={color}
            variant={variant}
            circleColor={circleColor}
            size={size}
            onClick={onClick}
            disabled={disabled}
            startIcon={startIcon}
            endIcon={endIcon}
            {...(href ? { component: Link, to: href } : {})}
            sx={sx}
            ref={ref}
            className={className}
        >
            {children}
        </StyledButton>
    );
};
