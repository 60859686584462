import { Switch } from '@mui/material';
import { styled } from '@mui/system';
import { FC, CSSProperties } from 'react';
import { COLORS } from "../../utils";

const COLOR_PALETTE = {
    primary: {
        track: COLORS.LIGHT_BLUE,
        thumb: COLORS.SKY_BLUE,
    },
    customGray: {
        track: COLORS.LIGHT_BLUE,
        thumb: COLORS.WHITE,
    },
};

const CustomSwitch = styled(Switch)(
    ({
        variant,
        checked,
    }: {
        variant: 'primary' | 'customGray';
        checked: boolean;
    }) => ({
        '&.MuiSwitch-root': {
            width: '68px',
            height: '48px'
        },
        '& .MuiSwitch-switchBase': {
            // marginTop: '2px',
            padding: '16px  ',
            zIndex: 100,
            // marginRight: '30px',

            '&.Mui-checked': {
                '& + .MuiSwitch-track': {
                    backgroundColor: COLOR_PALETTE[variant].track,
                    opacity: 100,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 16,
            height: 16,
            backgroundColor: checked
                ? COLOR_PALETTE[variant].thumb
                : COLORS.WHITE,
            opacity: 1,
        },
        '& .MuiSwitch-track': {
            padding: '0.6rem 1.2rem',
            borderRadius: '1.5rem',
            backgroundColor: COLOR_PALETTE[variant].track,
            border: `2px solid ${
                checked ? COLOR_PALETTE[variant].thumb : COLORS.WHITE
            }`,
            opacity: 1,
        },
    }),
);

interface IStyledSwitchProps {
    checked: boolean;
    onChange: () => void;
    disabled?: boolean;
    variant?: 'primary' | 'customGray';
    style?: CSSProperties;
    className?: string;
}

export const StyledSwitch: FC<IStyledSwitchProps> = ({
    checked,
    onChange,
    variant = 'primary',
    disabled = false,
    style,
    className,
}) => {
    return (
        <CustomSwitch
            checked={ checked }
            onChange={ onChange }
            disabled={ disabled }
            variant={ variant }
            style={ style }
            className={ className }
        />
    );
};
