import { useQuery } from "react-query";
import { getUser, getWorkspaces } from "../api";

export interface IUserItem {
    id: number;
    text: string;
}

export interface IUserSpace {
    id: number;
    name: string;
    logo: string;
    cover: string;
    description: string;
    role: IUserItem;
    is_active: boolean;
}

export interface IUser {
    id: number;
    state: number;
    role: IUserItem;
    photo: null | string;
    firstname: string;
    lastname: string;
    middlename: null | string;
    token: {
        type: string;
        body: string;
        expires_at: number;
    };
    spaces: Array<IUserSpace>;
}

export const useGetUser = () => {
    return useQuery<any, unknown>(
        ['user'],
        async () => {
            const res = await getUser();
            return res;
        },
        {
            staleTime: Infinity,
            retry: false,
        },
    );
};