import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { COLORS } from '../../../utils';

export const StyledBody = styled(Box)({
    width: '100%',
    padding: '0 2rem',
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'flex-start',
});

export const StyledSidebarBody = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2.5rem',

    '.chapters': {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px 0',
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',

        '&__block': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            opacity: '.5',

            'p': {
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '26px',
            },

            'svg': {
                cursor: 'pointer'
            }
        }
    }
});

export const StyledEditorWrapper = styled(Box)({
    width: 'calc(100% - 23rem)',
    height: '100%',
    minHeight: '20rem',
    backgroundColor: COLORS.LIGHT_BLUE,
    borderRadius: '0.5rem',
    '& .container': {
        position: 'relative',

        '.codeBoxTextArea': {
            position: 'relative',
            minHeight: '20rem'
        },

        '.codeBoxSelectDiv': {
            background: '#333F49',
            position: 'absolute',
            right: '6%',
            top: '10%',
            padding: '10px',
            borderRadius: '8px'
        },

        '.codeBoxSelectInput': {
            width: '150px',
            borderRadius: '0',
            background: '#333F49',
            padding: '0',
            textAlign: 'center',
        },

        '.dark' : {
            color: '#2CB5B4'
        },

        ".codeBoxSelectDropIcon": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px !important',
            textAlign: 'center',
            top: '50%',
            width: '5px',
            transform: 'translateY(-60%)',
            right: '10%',
            left: 'unset !important',
            background: 'none',
        },

        '.ce-conversion-tool': {
            color: COLORS.SKY_BLUE
        },

        '.ce-conversion-tool--focused': {
            color: COLORS.SKY_BLUE
        },

        '.ce-inline-toolbar--showed': {
            border: 'none',
            background: COLORS.DARK_BLUE
        },
        '.ce-inline-toolbar__dropdown:hover': {
            background: 'none'
        },
        '.ce-conversion-toolbar--showed': {
            border: 'none',
            background: COLORS.DARK_BLUE
        },

        '.ce-inline-tool:hover': {
            background: 'none'
        },
        '& .ce-block--selected .ce-block__content': {
            background: 'rgba(255, 255, 255, 0.10)'
        },

        '& .ce-toolbar__plus': {
            '&:hover': {
                background: 'none',
            },

            path: {
                stroke: `${ COLORS.SKY_BLUE } `,
                color: `${ COLORS.SKY_BLUE } `,
            }
        },
        '& .ce-toolbar__content': {
            padding: '0 20px',
            maxWidth: 'unset',
        },

        '.ce-toolbar__actions': {
            right: 'unset'
        },

        '.ce-block__content': {
            padding: '15px 50px',
            maxWidth: '100%'
        },

        '& .ce-popover__search': {
            display: 'none',
        },

        '& .codex-editor': {
            '& path, rect, line, circle': {
                stroke: `${ COLORS.SKY_BLUE }`,
                color: `${ COLORS.SKY_BLUE } `,
            },

            '& .colorPlugin': {
                '& svg': {
                    fill: `${ COLORS.SKY_BLUE } !important`,
                },
            },
        },
        '& .ce-editorjsColumns_col': {
            border: '1px solid #c6d2e1',
            borderRadius: '8px',

            '& svg': {
                fill: COLORS.SKY_BLUE,
            },
        },
        '& .ce-toolbar__plus svg, .ce-toolbar__settings-btn svg': {
            width: 'auto',
            height: 'auto',
        },

        '& .ce-toolbar': {
            display: 'block',
            zIndex: 1,
        },

        '& .ce-block': {
            wordWrap: 'break-word',
            paddingTop: '35px',
        },

        '& .ce-editorjsColumns_col .ce-toolbar__actions': {
            right: '-5px',
        },

        '& .cdx-search-field__icon svg': {
            width: '14px',
            height: '14px',
        },

        '& .image-tool__tune': {
            display: 'none',
        },

        '& .cdx-attaches--with-file .cdx-attaches__file-icon-background': {
            width: '42px',
        },

        '& .cdx-attaches--with-file .cdx-attaches__file-icon-label': {
            top: '10px',
        },

        '& .cdxcarousel-wrapper': {
            marginBottom: '70px',
        },

        '& .image-tool': {
            maxWidth: '400px',

            '&.image-tool--stretched': {
                maxWidth: '100%',
            },
        },

        '& .cdxcarousel-block': {
            minWidth: 'calc(100% / 3) !important',
        },

        '& .cdxcarousel-list': {
            background: 'rgba(0, 0, 0, 0.03)',
            padding: '20px',
            position: 'relative',

            '& .column__setting__wrapper': {
                top: '100%',
                left: 0,
                width: '100%',
                minHeight: '54px',
                background: 'f',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                padding: '8px',

                '& .column__setting': {
                    position: 'relative',
                    top: 'auto !important',
                    bottom: 'auto !important',
                    left: 'auto !important',
                    right: 'auto !important',
                },

                '& .cdxcarousel-rightBtn': {
                    marginLeft: '10px',
                },
            },
        },

        '& .ce-toolbar__settings-btn': {
            '& path, rect, line, circle': {
                stroke: `${ COLORS.WHITE }`,
                color: `${ COLORS.WHITE } `,
            },

            '&:hover': {
                background: 'none'
            }
        },

        '& .ce-popover__item .ce-popover__item-icon': {
            '& svg': {
                fill: 'transparent',
            },
        },

        '& .ce-popover': {
            padding: 0,
            background: COLORS.DARK_BLUE,
            borderColor: COLORS.DARK_BLUE,
            color: COLORS.WHITE,
            maxHeight: 'unset',

            '& .ce-popover-item': {
                padding: '12px 20px',
                borderRadius: 'unset',
                borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                color: COLORS.WHITE,

                '&:hover': {
                    background: 'rgba(44, 181, 180, 0.08);'
                },

                '&__icon': {
                    boxShadow: 'none',
                    border: `none`,
                    background: 'none'
                }
            },
        },

        '& .editor-columns-blocks': {
            borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'space-around',
            padding: '0',
        },

        '& .editor-columns-block': {
            border: 0,
            borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
            width: '100%',
            height: '100%',
            minHeight: '100px',
            transition: 'all 0.3s',
            padding: '11px',

            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.03m)',
            },

            '& > div': {
                height: '100%',
                minHeight: '100px',
            },

            '&.w-50': {
                maxWidth: '50%',
            },

            '&.w-33': {
                maxWidth: '33.3333%',
            },

            '&.w-25': {
                maxWidth: '25%',
            },

            '& .ce-block__content': {
                maxWidth: '90%',
                '& > div': {
                    wordBreak: 'break-word',
                },
            },
        },

        '& .codex-editor__redactor': {
            marginRight: 0,
        },

        '& .tc-wrap': {
            overflow: 'auto',
        },

        '& .tc-table': {
            minWidth: '700px',
        },

        '& .tc-add-row, .tc-add-column': {
            zIndex: 1,
            '& svg': {
                background: COLORS.WHITE,
            },
        },

        '& .ck-reset_all, .ck-widget__selection-handle': {
            display: 'none',
        },

        '& .ck-editor__editable.ck-focused': {
            border: 'none !important',
            boxShadow: 'none !important',
        },

        '& .table.ck-widget.ck-widget_with-selection-handle.ck-widget_selected': {
            margin: '0 !important',
        },

        '& .table.ck-widget': {
            overflow: 'auto',
        },

        '& [data-item-name=withBorder], [data-item-name=withBackground]': {
            display: 'none',
        },

        '& .ck-content .table table td, .ck-content .table table th': {
            minWidth: '200px',
        },

        '& .cdx-collapse-toggle::before': {
            borderLeft: `6px solid ${ COLORS.SKY_BLUE }`,
        },

        '& .cdx-collapse-title': {
            width: '100%',
            textOverflow: 'ellipsis',
        },

        '& .cdx-collapse-content': {
            overflowY: 'auto',
        },

        '& custom-section': {
            position: 'relative',
            height: 'auto',
            inset: 'auto',
        },
    },
});

export const StyledPreview = styled(Box)({
    width: '40rem',
    margin: '0 auto',
    height: '100%',
    minHeight: '40rem',
    backgroundColor: COLORS.WHITE,
    borderRadius: '0.5rem',
    '& .header': {
        borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
        padding: '0 0.625rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '0.75rem',
        lineHeight: '1.25rem',
        fontFamily: '"SF Pro Display", sans-serif',
        color: 'rgba(0, 0, 0, 0.5)',
        '& .title': {
            width: '100%',
        },
        '& .buttons': {
            display: 'flex',
            alignItems: 'center',
            '& .button': {
                borderLeft: `1px solid rgba(0, 0, 0, 0.2)`,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                fontSize: '0.75rem',
                lineHeight: '1.25rem',
                fontFamily: '"SF Pro Display", sans-serif',
                color: 'rgba(0, 0, 0, 0.5)',
                width: '7rem',
                paddingLeft: '1rem',
            },
        },
    },
    '& .body': {
        padding: '1.5rem 1.25rem',
    },
});

export const StyledNewsContent = styled(Box)({
    padding: '1.25rem',
    '& blockquote + *': {
        marginBottom: '1.25rem',
    },
    '& h1, h2, h3, h4, h5, p, ol, ul, table, img, .columns, blockquote': {
        marginBottom: '1.25rem',
    },
    '& .table-container': {
        width: '100%',
        overflow: 'auto',
    },

    '& figure': {
        overflow: 'auto',
    },

    '& li, p': {
        '& a': {
            display: 'inline',
        },
    },
    '& table': {
        tableLayout: 'auto',
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    '& .ck-editor__editable': {
        minWidth: 'auto',
    },
    '& .gallery': {
        display: 'flex',
        overflow: 'auto',
        margin: '0 -1rem 1rem',

        '&::-webkit-scrollbar': {
            height: '0.625rem',
        },

        '&-row': {
            margin: 0,
            minWidth: '750px',
            flexWrap: 'nowrap',

            '& a': {
                display: 'block',
                height: '100%',

                '& img': {
                    maxWidth: '250px',
                    minWidth: '250px',
                    minHeight: '250px',
                    maxHeight: '250px',
                    display: 'block',
                    objectFit: 'cover',
                },
            },
        },
    },
    '& a': {
        display: 'block',
        textDecoration: 'none',
    },
    '& table td, th': {
        border: '1px solid #e8e8eb',
        borderRadius: '0.5rem',
        padding: '0.25rem',
    },
    '& blockquote': {
        padding: '0.25rem 0 0.25rem 1.25rem',
        borderLeft: `3px solid ${ COLORS.SKY_BLUE }`,
    },
    '& .columns': {
        display: 'flex',

        '&-2': {
            '& > div': {
                width: '50%',
            },
        },

        '&-3': {
            '& > div': {
                width: '33.3333%',
            },
        },

        '&-4': {
            '& > div': {
                width: '25%',
            },
        },
    },
    '& .image': {
        width: '100%',

        '&.stretched': {
            maxWidth: '100%',
        },
        '&.big': {
            maxWidth: '75%',
        },
        '&.medium': {
            maxWidth: '50%',
        },
        '&.small': {
            maxWidth: '25%',
        },
        '&.with-border': {
            border: '1px solid #e8e8eb',
        },
        '&.with-background': {
            backgroundColor: COLORS.LIGHT_BLUE,
        },
    },
    '& .ck-widget.table td.ck-editor__nested-editable.ck-editor__nested-editable_focused, .ck-widget.table td.ck-editor__nested-editable:focus, .ck-widget.table th.ck-editor__nested-editable.ck-editor__nested-editable_focused, .ck-widget.table th.ck-editor__nested-editable:focus':
        {
            outline: 'none',
            backgroundColor: 'transparent',
            borderStyle: 'solid',
        },
    '& details': {
        '& summary::marker': {
            color: COLORS.LIGHT_BLUE,
        },

        '& p': {
            paddingLeft: '1.25rem',
        },
    },
    '& .cdx-attaches': {
        position: 'relative',
        marginBottom: '15px',

        '&--with-file': {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 12px',
            border: '1px solid #EFF0F1',
            borderRadius: '7px',
            background: '#fff',

            '& .cdx-attaches__file-info': {
                display: 'grid',
                gridGap: '4px',
                maxWidth: 'calc(100% - 80px)',
                margin: 'auto 0',
                flexGrow: '2',
            },

            '& .cdx-attaches__title': {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                outline: 'none',
                maxWidth: '90%',
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '1em',
            },

            '& .cdx-attaches__size': {
                color: '#707684',
                fontSize: '12px',
                lineHeight: '1em',

                '&:after': {
                    content: 'attr(data-size)',
                    marginLeft: '0.2em',
                },
            },
        },

        '& .cdx-attaches__title': {
            maxWidth: '100%',
        },

        '&__file-icon': {
            padding: '9px 15px 9px 5px',
            backgroundColor: 'rgb(170, 34, 132)',
            borderRadius: '6px',
            marginRight: '12px',
            fontSize: '10px',
            fontWeight: '700',
            color: '#ffffff',
            textTransform: 'uppercase',
            lineHeight: '1em',

            '& p': {
                margin: 0,
            },
        },

        '& .cdx-attaches__download-button': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',

            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
});

export const StyledPreviewCover = styled(Box)({
    width: '100%',
    height: 'auto',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
    },
});

export const StyledPreviewTitle = styled(Box)({
    fontSize: '1.5rem',
    fontWeight: 800,
    lineHeight: '2rem',
    fontFamily: '"SF Pro Display", sans-serif',
    margin: '1.5rem 1.25rem',
    textAlign: 'center',
});

export const StyledPreviewDescription = styled('p')({
    fontSize: '1rem',
    fontWeight: 300,
    lineHeight: '1.25rem',
    fontFamily: '"SF Pro Display", sans-serif',
    margin: '0 1.25rem 1rem',
    textAlign: 'center',
});

export const StyledPreviewMetaBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 1.25rem',
    fontSize: '0.875rem',
    fontFamily: '"SF Pro Display", sans-serif',
    lineHeight: '1.375rem',
});

export const StyledPreviewCategoryBadge = styled(Box)({
    backgroundColor: COLORS.SKY_BLUE,
    color: COLORS.WHITE,
    padding: '0.25rem 0.75rem',
    borderRadius: '6.25rem',
    fontWeight: 600,
});

export const StyledPreviewTagsBox = styled(Box)({
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    maxWidth: '70%',
    flexWrap: 'wrap',
});

export const StyledPreviewTagBadge = styled(Box)({
    color: COLORS.SKY_BLUE,
    backgroundColor: COLORS.LIGHT_BLUE,
    padding: '0.25rem 0.75rem',
    borderRadius: '6.25rem',
    fontWeight: 500,
});

export const EditorHeader = styled(Box)({
    borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
    padding: '0.5rem 0.625rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontFamily: '"SF Pro Display", sans-serif',
    color: 'rgba(255, 255, 255, 0.5)',
    '& .title': {
        width: '100%',
        borderRight: `1px solid rgba(0, 0, 0, 0.2)`,
    },
    '& .button': {
        fontSize: '0.75rem',
        lineHeight: '1.25rem',
        fontFamily: '"SF Pro Display", sans-serif',
        color: 'rgba(0, 0, 0, 0.5)',
        width: '7rem',
        marginLeft: '0.5rem',
    },
});

export const StyledEmptyWrapper = styled(Box)({
    position: 'relative',
    display: 'flex',
    alignItem: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    textAlign: 'center',

    'svg': {
        position: 'absolute',
        top: '37%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },

    'h1': {
        fontSize: '36px',
        fontWeight: '700',
        opacity: '.5',
        lineHeight: '48px',
    }
})