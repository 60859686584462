import { http, methods } from './config';
import { ISpaceReq, IPaginatedReqParams, SpaceReqProps } from "../types";

export const  getWorkspaces = (locale: string) => {
    const config = {
        method: methods.get,
        url: `/workspaces`,
        headers: {
            'Content-Language': locale
        }
    };
    return http(config);
}

export const  getWorkspaceDetails = () => {
    const config = {
        method: methods.get,
        url: `/workspace-details`,

    };
    return http(config);
}

export const createWorkspace = (data: SpaceReqProps) => {
    const config = {
        method: methods.post,
        url: `/workspaces`,
        data,
    };
    return http(config);
};

export const createWorkspaceDetails = (data: any) => {
    const config = {
        method: methods.post,
        url: `/workspace-details`,
        data,
    };
    return http(config);
};

export const updateWorkspace = (data: any) => {
    const config = {
        method: methods.put,
        url: `/workspaces/${data.id}`,
        data,
    };
    return http(config);
};

export const updateWorkspaceDetail = (data: any) => {
    const config = {
        method: methods.put,
        url: `/workspace-details/${data.workspace_id}`,
        data,
    };
    return http(config);
};

export const deleteWorkspace = (spaceId: string | number) => {
    const config = {
        method: methods.delete,
        url: `/workspaces/${spaceId}`,
    };
    return http(config);
};

export const deleteWorkspaceDetails = (spaceId: string | number) => {
    const config = {
        method: methods.delete,
        url: `/workspace-details/${spaceId}`,
    };
    return http(config);
};