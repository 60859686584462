import { styled } from '@mui/system';
import { Box, FormControlLabel, Chip } from '@mui/material';
import { COLORS } from '../../../utils';

export const StyledLayout = styled(Box)({
    width: '28.5rem',
    padding: '1.875rem',
    backgroundColor: COLORS.LIGHT_BLUE,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.875rem',
    borderRadius: '0.5rem',
    '& h2': {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: '2rem',
        fontFamily: '"SF Pro Display", sans-serif',
        margin: 0,
        color: COLORS.WHITE,
    },
    '& .chapters__block__active': {
        background: '#FFFFFF1A',
        padding: '0.625rem',
        borderRadius: '0.5rem',
        opacity: '1',
        '& p': {
            color: '#ffffff',
        }
    },


    '.chapter__button': {
        background: 'none',
        border: '1px dashed rgba(255, 255, 255, 0.5) ',
        borderRadius: '4px'
    },

    '.MuiInput-underline': {
        height: '50px'
    },

    '.delete': {
        cursor: 'pointer',
    },

    '.color': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px !important',
        marginRight: '10px !important',
        width: '32px',
        height: '32px'
    },

    '.colorBlock': {
        position: 'relative'
    },
    '.colorSelector': {
        width: '100% !important',
        background: `${COLORS.DARK_BLUE} !important`,
        zIndex: '1000',
        position: 'absolute'
    },

    '.icon': {
        padding: '7px',
        borderRadius: '50%',
        marginRight: '0.2rem',

        svg: {
            width: '20px',
            height: '20px',
        }
    },
    '.lamp': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        background: COLORS.GRAY,
        svg: {
            width: '15px',
            height: '15px',
        }
    }
});

export const MainFormItems = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    '& .MuiTextField-root': {
        width: '100%',
    },
});

export const StyledContainerBox = styled(Box)({
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'column',
});

export const StyledSwitchFormControl = styled(FormControlLabel)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: 0,
    '& .MuiFormControlLabel-label': {
        marginTop: '0.375rem',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontFamily: '"SF Pro Display", sans-serif',
    },
});

export const ChipsBox = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    margin: '0.5rem 0',
});

export const StyledChip = styled(Chip)({
    padding: '0.25rem 0.5rem',
    backgroundColor: COLORS.BLACK,
    color: COLORS.WHITE,
    '& .MuiChip-deleteIcon': {
        color: COLORS.WHITE,
        opacity: 0.5,
        transition: 'all 0.3s ease',
        '&:hover': {
            color: COLORS.WHITE,
            opacity: 1,
        },
    },
});
