import { FC, ReactNode } from 'react';
import classNames from "classnames";

import classes from './Circle.module.scss';

interface ICircle {
  children?: ReactNode;
}

export const Circle: FC<ICircle> = ({ children }) => {
  return (
    <div className={classes.auth__circle}>
      <div
        className={classNames(
          classes.auth__circle__inner,
          classes.auth__circle__inner_1,
        )}
      >
        <div
          className={classNames(
            classes.auth__circle__inner,
            classes.auth__circle__inner_2,
          )}
        >
          <div
            className={classNames(
              classes.auth__circle__inner,
              classes.auth__circle__inner_3,
            )}
          >
            <div
              className={classNames(
                classes.auth__circle__inner,
                classes.auth__circle__inner_4,
              )}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
