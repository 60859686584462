import { Box } from "@mui/material";
import { styled } from "@mui/system";

import { COLORS } from "../../utils";

const MENU_HEIGHT = `calc(100% - 8rem)`;

export const StyledSidebarBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100vh',
    width: '20rem',
    background: COLORS.DARK_BLUE,
    padding: '2rem 1.25rem',

    '& .MuiButtonBase-root': {
        borderRadius: '4px',
        padding: '0.8125rem, 0.625rem',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.05)',
        }
    }
})

export const StyledMenuBox = styled(Box)`
    height: ${MENU_HEIGHT};
    margin-top: 1.25rem;
    overflow: auto;
`;

export const StyledLogoutBox = styled(Box)({
    paddingTop: '2rem',
    width: '100%',
    height: '4rem',
    display: 'flex',
    alignItems: 'end',
    '& > button': {
        width: '100%',
        border: `1px solid rgba(255,255,255, 0.2)`,
    },
})