import { TableCell, TableHead, TableRow } from "@mui/material";

const headCells = [
    {
        id: 'name',
        label: 'Name',
        sortable: true,
    },
    {
        id: 'actions',
        label: '',
        sortable: false,
    },
];

export const LocalesTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell  key={ headCell.id } align='center' padding='normal'>
                        { headCell.label }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}