import { FC, ReactNode } from 'react';

import { Sidebar } from "../index";

import classes from './Layout.module.scss';

interface ILayout {
    children?: ReactNode;
}

export const Layout: FC<ILayout> = ({ children }) => {
    return (
        <div className={classes.layout}>
            <Sidebar />
            <div className={classes.layout__content}>{children}</div>
        </div>
    );
};