import { FC, ChangeEvent, useState } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { notify, COLORS } from '../../utils';
import { ImageUploadIcon } from '../../assets';
import { useUploadMedia } from '../../hooks';

const StyledContainer = styled(Box)({
  borderRadius: '0.25rem',
  border: `1px solid ${COLORS.SKY_BLUE}`,
  width: '100%',
  overflow: 'hidden',
  height: '12rem',
  position: 'relative',
  backgroundColor: 'rgba(0,128,255,0.05)',
  '& .photo': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    cursor: 'pointer',
  },
});

const StyledContentBox = styled(Box)({
  width: '45%',
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontFamily: '"SF Pro Display", sans-serif',
  cursor: 'pointer',
  '& .title': {
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
  },
  '& .description': {
    fontSize: '0.625rem',
    fontWeight: 400,
    lineHeight: '0.875rem',
  },
});

export interface INewsImageUploadProps {
  image: string | null;
  setImage: (_image: string) => void;
  inputId: string;
  imageSize?: number;
  imageFixedWidth?: number;
  imageFixedHeight?: number;
}

export const BookInsightsImageUpload: FC<INewsImageUploadProps> = ({
  image,
  setImage,
  inputId,
  imageSize = 1 * 1024 * 1024,
  imageFixedWidth = 512,
  imageFixedHeight = 513,
}) => {
  const { mutateAsync: uploadMedia } = useUploadMedia();
  const [imageName, setImageName] = useState<string>('');

  const handleOpenFileUploadModal = () => {
    document.getElementById(inputId)?.click();
  };

  const convertImageSizeToText = (): string => {
    if (imageSize >= 1000000) {
      const sizeInMB = imageSize / 1000000;
      return `${sizeInMB.toFixed(1)} МБ`;
    }
    const sizeInKB = imageSize / 1000;
    return `${sizeInKB.toFixed(0)} КБ`;
  };

  const handleUploadAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    // max limit
    if (file.size > imageSize) {
      notify(
          `Размер файла превышает максимальный предел в ${convertImageSizeToText()}.`,
          'error',
      );
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result as string;

      img.onload = () => {
        // if (img.width !== imageFixedWidth || img.height !== imageFixedHeight) {
        //   notify(
        //       `Высота или ширина картинки не соответствуют требуемому стандарту (${imageFixedWidth}x${imageFixedHeight})`,
        //       'error',
        //   );
        //   return;
        // }
        const formData = new FormData();
        formData.append('image', file, file.name);
        uploadMedia(formData)
            .then((res: any) => {
              setImage(res);
            })
            .catch(() => {});
      };
    };
    reader.readAsDataURL(file);
    setImageName(file.name);
  };

  return (
      <StyledContainer>
        {image ? (
            <img
                src={image}
                alt={imageName}
                onClick={handleOpenFileUploadModal}
                className='photo'
            />
        ) : (
            <StyledContentBox onClick={handleOpenFileUploadModal}>
              <ImageUploadIcon color={COLORS.SKY_BLUE} />
              <Box className='title'>Добавить обложку</Box>
              <Box className='description'>
                Загружайте картинки в формате .jpg / .png
              </Box>
            </StyledContentBox>
        )}
        <input
            id={inputId}
            type='file'
            style={{ display: 'none' }}
            onChange={e => handleUploadAvatar(e)}
        />
      </StyledContainer>
  );
};
