import { FC } from 'react';
import { Drawer, Box } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from '../../utils';

interface IDrawer {
  open: boolean;
  onClose: () => void;
  style?: React.CSSProperties;
  variant?: 'permanent' | 'persistent' | 'temporary';
  children?: React.ReactNode;
  width?: string | number;
  maxWidth?: string | number;
}

const StyledDrawerBox = styled(Box)(
  ({
    width,
    maxWidth,
  }: {
    width?: string | number;
    maxWidth: string | number;
  }) => ({
    width,
    height: '100%',
    padding: '1.875rem',
    borderRadius: '1rem',
    maxWidth,
  }),
);

export const DrawerComponent: FC<IDrawer> = ({
  open,
  onClose,
  children,
  style,
  maxWidth = '45rem',
  width = '50vw',
  variant = 'temporary',
}) => {
  return (
    <Drawer style={style} open={open} variant={variant} anchor='right'  onClose={onClose}>
      <StyledDrawerBox width={width} maxWidth={maxWidth} style={style}>
        {children}
      </StyledDrawerBox>
    </Drawer>
  );
};
