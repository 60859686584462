import * as Pages from '../../../pages'
import { IRoutes } from '../types';

export const Auth: IRoutes = {
    auth: {
        path: '/auth',
        title: 'auth',
        element: <Pages.Auth />,
        openRoute: true,
    }
}
