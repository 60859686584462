import { FC, useEffect, useState } from "react";
import { StyledHr, WorkspaceCardWrapper } from "./styledComponents";
import { IWorkspace } from "../../../types/workspace";
import { StyledSwitch, StyledBadge, Button, StyledFlexBox } from "../../../UI";
import { DraftIcon, EditIcon, Spinner } from "../../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteWorkspace, useUpdateWorkspace } from "../../../hooks";
import { width } from "@mui/system";
import { useSelector } from "react-redux";

interface IWorkspaceCard {
    workspace: any
    onClose: () => void;
    isFetching: boolean;
}

export const WorkspaceCard: FC<IWorkspaceCard> = ({
    workspace,
    onClose,
    isFetching,
}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)

    const { mutateAsync: updateWorkspace } = useUpdateWorkspace();
    const { mutateAsync: deleteWorkspace } = useDeleteWorkspace();

    const {
        id,
        is_active,
        name,
        logo,
        description,
    } = workspace;

    const [isActive, setIsActive] = useState(is_active)


    const changeActiveStatus = () => {
        setIsActive(!isActive)

    };

    useEffect(() => {
        updateWorkspace({
            id,
            name,
            logo,
            description,
            is_active: isActive,
        }).catch(() => {});
    }, [isActive])

    useEffect(() => {
        if (!isFetching) {
            setIsLoading(false)
        }
    }, [isFetching])

    const openEditDrawer = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(name ? 'edit' : 'edit2', id.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const handleDeleteWorkspace = () => {
        setIsLoading(true)
        deleteWorkspace(id)
            .then(() => {
                setIsLoading(true)
                onClose();
            })
            .catch(() => {});
    }

    return (
        <WorkspaceCardWrapper>
            <div className='logoBox'>
                <div style={{background: logo ? 'none' : '#283239'}} className={`logo ${!name ? 'drafted' : ''}`}>
                    {logo && <img src={logo} alt='' />}
                    {!logo && name && <p>{name[0]}</p>}
                    {!name && !logo && <DraftIcon/>}
                </div>

                <div  className={'toggle'}>
                    <StyledSwitch
                        checked={isActive}
                        onChange={changeActiveStatus}
                        disabled={id === 1}
                    />
                </div>
            </div>

            <h2>{name ? name : 'Draft'}</h2>

            <StyledBadge style={{width: 'fit-content'}} is_active={is_active} />

            <StyledHr/>

            <p>{description}</p>

            <div className={'separator'}>

            </div>

            <div className='buttons'>
                {name ? (
                    <Button
                        className='button'
                        size='large'
                        onClick={openEditDrawer}
                    >
                        Edit
                    </Button>
                ) : (
                    <Button
                        className='button addDetail'
                        size='large'
                        onClick={openEditDrawer}
                    >
                       Add Details
                    </Button>
                )}

                <Button
                    onClick={ handleDeleteWorkspace }
                    variant='contained'
                    size='large'
                    color='secondary'
                >
                    <div style={{width: '3rem'}}> {isLoading ? <Spinner/> : 'Delete'}</div>
                </Button>
            </div>
        </WorkspaceCardWrapper>
    )
}