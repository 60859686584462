import { styled } from '@mui/system';
import { Grid } from '@mui/material';

export const CardsContainer = styled(Grid)({
  marginTop: '1rem',
  overflowY: 'auto',
  position: 'relative',
});

export const PaginationContainer = styled(Grid)({
  padding: '1rem 0',
});
