import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: '',
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static getDerivedStateFromError(error: any) {
    return { hasError: true, errorMessage: error.message };
  }

  render() {
    const {
      state: { hasError, errorMessage },
      // @ts-ignore
      // eslint-disable-next-line react/prop-types
      props: { children },
    } = this;

    if (hasError) {
      return (
        <div className={classNames('container', Styles.errorBoundary)}>
          <p>Something went wrong</p>
          <span>The error was handled in your ErrorBoundary component</span>
          <code>{errorMessage}</code>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
