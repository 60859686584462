import { FC, useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { WorkspacesHeader, Layout, WorkspacesBody } from "../../components";
import { AddEditWorkspaceDrawer } from "../../components/Workspaces/AddEditWorkspaceDrawer";
import { useDrawer } from "../../hooks/useDrawer";
import { useGetSpaces } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { changeWorkspaces } from "../../redux";
export const Workspaces: FC = () => {
    const dispatch = useDispatch();

    const {locale, slug} = useSelector((state: any) => state.locales);
    const [localSearchValue, setLocalSearchValue] = useState<string>('');
    const [isAddLoading, setIsAddLoading] = useState(false)

    const {data: workspaces, isLoading, isFetching}: any = useGetSpaces(slug)


    useEffect(() => {

        dispatch(changeWorkspaces( {data: workspaces, isLoading}))

    }, [workspaces, locale])

    const visibleWorkspaces = useMemo(() => {
        if (!localSearchValue) return workspaces;
        return workspaces && workspaces.filter(
            (workspace: any) =>
                workspace.name
                    .toLowerCase()
                    .includes(localSearchValue.toLowerCase()) ||
                workspace.name.toLowerCase()?.includes(localSearchValue.toLowerCase()),
        );
    }, [localSearchValue, workspaces]);

    const {
        openAddDrawer,
        viewDrawerId,
        showViewDrawer,
        hideViewDrawer,
        editDrawerId,
        purpose,
        hideAddEditDrawer,
        showAddEditDrawer,
    } = useDrawer();

    return (
       <Layout>
           <AddEditWorkspaceDrawer
               open={showAddEditDrawer}
               onClose={hideAddEditDrawer}
               purpose={purpose}
               id={editDrawerId}
           />

           <WorkspacesHeader
               purpose={purpose}
               openAddSpaceModal={openAddDrawer}
               setLocalSearch={setLocalSearchValue}
               localSearchValue={localSearchValue}
               setIsAddLoading={setIsAddLoading}
               isFetching={isFetching}
           />

           <WorkspacesBody
               onClose={hideAddEditDrawer}
               workspaces={visibleWorkspaces}
               isLoading={isLoading}
               isAddLoading={isAddLoading}
               isFetching={isFetching}
           />
       </Layout>
    )
}