import * as Pages from '../../../pages'
import { IRoutes } from '../types';

export const Workspaces: IRoutes = {
    workspaces: {
        path: '/workspaces',
        title: 'workspaces',
        element: <Pages.Workspaces />,
    }
}
