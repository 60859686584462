import { FC, useEffect } from "react";
import { PageHeader, Button, SearchInput} from "../../../UI";


import { PlusIcon } from "../../../assets";
import { useCreateWorkspace, useManageWorkspaces } from "../../../hooks";
import { useSelector } from "react-redux";

interface IWorkspaceHeader {
    openAddSpaceModal: () => void
    setLocalSearch: any,
    localSearchValue: any
    purpose: string,
    setIsAddLoading: any,
    isFetching: boolean,
}

export const WorkspacesHeader: FC<IWorkspaceHeader> = (
    {
        openAddSpaceModal,
        setLocalSearch,
        localSearchValue,
        purpose,
        setIsAddLoading,
        isFetching,
    }
) => {
    const { mutateAsync: createWorkspace, isLoading } = useCreateWorkspace();

    const handleSearch = (value: string) => {
        setLocalSearch(value)
    }

    useEffect(() => {
        if (!isFetching) {
            setIsAddLoading(false)
        }
    }, [isFetching])

    const saveChanges = () => {
        setIsAddLoading(true)

        if (purpose === 'add') {
            createWorkspace({is_active: true})
                .then(() => {
                    setIsAddLoading(true)
                })
                .catch(() => {

                })
        }
        else {
            openAddSpaceModal()
        }

    };

    return (
        <PageHeader
            hasMiddleComponent
            title={'Workspaces'}
        >
            <SearchInput handleSearch={value=> handleSearch(value)}/>

            <Button
                startIcon={<PlusIcon/>}
                color='primary'
                size='large'
                onClick={saveChanges}
            >
                    Add
            </Button>

        </PageHeader>
    )
}