import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { COLORS } from "../../../utils";

export const UserAnalyticsCardWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.6rem',
    borderRadius: '0.5rem',
    backgroundColor: COLORS.GRAY,
    padding: '1.875rem',
    zIndex: -1,
    width: '100%',
    '& .logo': {
        width: '5rem',
        height: '5rem',
        borderRadius: '50%',
        overflow: 'hidden',
        backgroundColor: COLORS.SKY_BLUE,
        opacity: '0.05',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        },
    },
    '& h2': {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
        fontWeight: 600,
        margin: '0 0 0.25rem',
        cursor: 'pointer',
    },

    '& p': {
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: '1rem',
        lineHeight: '1.375rem',
        fontWeight: 400,
    },

    '& .button':{
        marginTop: '1rem',
        background: 'linear-gradient(91deg, #2CB5B4 0%, #6ABD78 100%)'
    },

    '& .toggle': {
        position: 'relative',
        zIndex: 1,
        marginRight: -20,

        "& .MuiSwitch-root": {
            width: '68px',
            height: '50px'
        }
    }
});

export const UserAnalyticsCardBottom = styled(Box) ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .amountChanged': {

    },

    '& .percent': {
        fontSize: '1rem',
        fontWeight: '600',
        opacity: '0.5',
        lineHeight: '1.5rem'
    }
})