export const ROUTES = {
    initialRoute: '/',
    noMatch: '*',
    home: '/workspaces',
    auth: '/auth',
    analytics: {
        get: '/analytics',
        spaces: '/analytics/workspaces',
        users: '/analytics/users',
    },
    workspaces: '/workspaces',
    bookInsights: '/book-insights',
    createBookInsights: '/book-insights/create',
    editBookInsights:  '/book-insights/edit/:id',
    cheats: '/cheats',
    createCheats: '/cheats/create',
    editCheats: '/cheats/edit/:id'
}