import { FC, useEffect, useState, } from "react";
import { CardsContainer, CardSkeleton } from "../../../UI";
import { Grid } from "@mui/material";
import { WorkspaceCard } from "../WorkspaceCard";

interface IWorkspacesBody {
    workspaces: any,
    isLoading: boolean,
    onClose: () => void;
    isAddLoading: boolean
    isFetching: boolean;
}
export const WorkspacesBody: FC<IWorkspacesBody> = ({workspaces, isLoading, onClose, isAddLoading, isFetching}) => {
    return (
        <CardsContainer container spacing={3}>
            {!isLoading && workspaces?.map((workspace: any) => (
                <Grid item xs={4} key={workspace.id}>
                    <WorkspaceCard workspace={workspace} onClose={onClose} isFetching={isFetching}/>
                </Grid>
            ))}

            {isAddLoading && (
                <Grid item xs={4} style={{height: 'auto'}}>
                    <CardSkeleton/>
                </Grid>
            )}

            {isLoading && [1,2,3].map((skeleton, idx) => (
                <Grid item xs={4} key={idx}>
                    <CardSkeleton/>
                </Grid>
            ))}
        </CardsContainer>
    )
}