import { Box } from "@mui/material";
import { styled } from "@mui/system";

import { COLORS } from "../../../utils";
import { FC } from "react";

interface IStyledAnalyticsSubHeaderItems {
    isActive: boolean,
    onClick: () => void,
    children: React.ReactNode;
}

export const StyledAnalyticsSubHeader = styled(Box) ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '0.375rem',
    background: COLORS.GRAY
})

export const StyledAnalyticsSubHeaderItems: FC<IStyledAnalyticsSubHeaderItems> = styled(Box)<IStyledAnalyticsSubHeaderItems>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 1rem 0;
  width: 10rem;
  background: ${({ isActive }) => (isActive && 'rgba(44, 181, 180, 0.10)')};
  color: ${({ isActive }) => (isActive && COLORS.SKY_BLUE)};
`;

