import { FC } from "react";
import {
    AchievementsIcon,
    AnalyticsIcon,
    InsightIcon,
    SpaceIcon,
    IIconProps, CheatsIcon
} from "./icons";
import { AdminsIcon } from "./icons/AdminsIcon";

export interface IMenuItem {
    title: string;
    path: string;
    icon: FC<IIconProps>;
    adminOnly?: boolean;
    userOnly?: boolean;
}

export const MenuItems: IMenuItem[] = [
    // {
    //     title: 'Analytics',
    //     path: '/analytics',
    //     icon: AnalyticsIcon,
    // },
    {
        title: "Workspaces",
        path: '/workspaces',
        icon: SpaceIcon
    },
    {
        title: "Book Insight",
        path: '/book-insights',
        icon: InsightIcon
    },
    {
        title: "Cheats",
        path: '/cheats',
        icon: CheatsIcon
    },
    {
        title: 'Admins',
        path: '/admins',
        icon: AdminsIcon
    },
    {
        title: 'Locales',
        path: '/locales',
        icon: AdminsIcon
    },
]