import { http, methods } from "./config";


export const getCheatsList = (locale: string) => {
    const config = {
        method: methods.get,
        url: `/cheats`,
        headers: {
            'Content-Language': locale
        }
    };
    return http(config);
}

export const getCheat = (cheatId: string | number) => {
    const config = {
        method: methods.get,
        url: `/cheats/${cheatId}`,
    };
    return http(config);
};

export const createCheat = (data: any) => {
    const config = {
        method: methods.post,
        url: `/cheats`,
        data,
    };
    return http(config);
};

export const updateCheat = (data: any) => {
    const config = {
        method: methods.put,
        url: `/cheats/${data.id}`,
        data,
    };
    return http(config);
};

export const deleteCheat = (adminId: string | number | undefined) => {
    const config = {
        method: methods.delete,
        url: `/cheats/${adminId}`,
    };
    return http(config);
};

export const getCheatSectionList = () => {
    const config = {
        method: methods.get,
        url: `/cheat_sections`,
    };
    return http(config);
}

export const createCheatSection = (data: any) => {
    const config = {
        method: methods.post,
        url: `/cheat_sections`,
        data,
    };
    return http(config);
};

export const updateCheatSection = (data: any) => {
    const config = {
        method: methods.put,
        url: `/cheat_sections/${data.id}`,
        data,
    };
    return http(config);
};

export const deleteCheatSection = (adminId: string | number | undefined) => {
    const config = {
        method: methods.delete,
        url: `/cheat_sections/${adminId}`,
    };
    return http(config);
};

export const createCheatSectionContent = (data: any) => {
    const config = {
        method: methods.post,
        url: `/cheat_sections/single/${data.id}/content`,
        data,
    };
    return http(config);
};

export const getCheatSectionContent = (id: string | number) => {
    const config = {
        method: methods.get,
        url: `/cheat_sections/single/${id}/content`,
    };
    return http(config);
}