import { MainFormItems, StyledLayout } from "../../BookInsights/CreateBookInsightSidebar/styledComponents";
import { Button, FormItem, StyledFlexBox } from "../../../UI";
import { TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
    useCreateCheatSection,
    useCreateCheatSectionContent,
    useDeleteCheat,
    useDeleteCheatSection, useGetCheatSectionContent, useGetCheatSections,
    useUpdateCheatSection
} from "../../../hooks";
import { createCheat } from "../../../api";
import { generateBlockHtml } from "../../BookInsights/CreateBookInsightsBody/utils";
import { ROUTES } from "../../../app";

interface ICreateCheatsChapters {
    parameters: any,
    setParameters: any,
    setIsChapterBlock: any
    isEditChapterBlock: any,
    purpose?: string,
    setIsEditChapterBlock?: any,
    id?: number | string,
    rawData: any
    setRawData?: any
    setIsRawData?: any
    setRawDataSecond?: any
    isChapterBlock?: any
}

export const CreateCheatsChapters: FC<ICreateCheatsChapters> = ({
    parameters,
    setParameters,
    setIsChapterBlock,
    isEditChapterBlock,
    purpose,
    id,
    rawData,
    setRawData,
    setIsEditChapterBlock,
    setRawDataSecond,
    setIsRawData
}) => {
    const [ items, setItems ] = useState<any>([])
    const {data: chapterContent} = useGetCheatSectionContent(isEditChapterBlock?.id)
    const {mutateAsync: createCheatSection} = useCreateCheatSection();
    const {mutateAsync: updateCheatSection} = useUpdateCheatSection();
    const {mutateAsync: deleteCheatSection} = useDeleteCheatSection();
    const {mutateAsync: createCheatSectionContent} = useCreateCheatSectionContent();

    const handleFieldChange = (
        fieldName: any,
        newValue: any
    ) => {
        setItems((prevInfo: any) => ({
            ...prevInfo,
            [fieldName]: newValue,
        }));
    };
    const removeChapterBlock = (itemToDelete: any) => {
        if (purpose === 'edit') {
            const updatedList = parameters.chapters.filter((item: any) => item !== itemToDelete)

            setParameters({
                ...parameters,
                chapters: updatedList
            })
        }
        setIsChapterBlock(false)
    }

    const addChapter = () => {
        const updatedList = {...items, cheat_id: id, editor: ''}

        createCheatSection(updatedList)
            .then(() => {
                setItems([])
                setIsChapterBlock(false)
            })
            .catch(() => {
            });
    }

    const updateChapter = () => {
        updateCheatSection(items)
            .then(() => {
                setItems([])
                setIsChapterBlock(false)
                setIsEditChapterBlock({})
                setRawDataSecond(0)
            })
            .catch(() => {
            });

        createCheatSectionContent({
            id: items.id,
            content: rawData?.blocks?.map((
                raw: any,
                idx: any
            ) => ({
                id: idx,
                language: raw.type === 'codeBox' ? raw.data.language : null,
                data: generateBlockHtml(raw),
                type: raw.type
            })),
            editor: rawData

        })
            .then(() => {
                setRawData([])
            })
            .catch(() => {
            });
    }

    const deleteChapter = () => {
        deleteCheatSection(items.id)
            .then(() => {
                setItems([])
                setIsChapterBlock(false)
            })
            .catch(() => {
            });
    }

    useEffect(() => {
        if (purpose === 'edit') {
            setItems(isEditChapterBlock)
        }

    }, [ isEditChapterBlock ])

    useEffect(() => {
        if (chapterContent) {
            setRawData({time: rawData.time, blocks: chapterContent?.editor?.blocks, version: '2.82.2'})
            setIsRawData(true)
        }
    }, [ chapterContent ])


    function handeCancel() {
        setIsChapterBlock(false)
        setIsEditChapterBlock({})
    }

    return (
        <StyledLayout>
            <div style={ {display: 'flex', justifyContent: 'space-between', alignItems: "center"} }>
                <h2>Selected Chapter</h2>

                { purpose === 'edit' && (
                    <div style={ {cursor: 'pointer', fontSize: '1.5rem'} } onClick={ handeCancel }>
                        x
                    </div>
                ) }
            </div>

            <MainFormItems>
                <FormItem label='Title'>
                    <TextField
                        variant='standard'
                        color='primary'
                        placeholder='Enter the text'
                        value={ items.title }
                        className={ 'textField' }
                        onChange={ e => handleFieldChange('title', e.target.value) }
                    />
                </FormItem>

                <FormItem label='Description'>
                    <TextField
                        variant='standard'
                        color='primary'
                        placeholder='Enter the text'
                        value={ items.description }
                        className={ 'textField' }
                        onChange={ e => handleFieldChange('description', e.target.value) }
                    />
                </FormItem>
            </MainFormItems>

            { purpose === 'add' ? (
                    <StyledFlexBox>
                        <Button
                            onClick={ () => removeChapterBlock(isEditChapterBlock) }
                            variant='contained'
                            size='large'
                            color='customDarkerGray'
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            size='large'
                            color='primary'
                            onClick={ addChapter }
                        >
                            Publish
                        </Button>
                    </StyledFlexBox>
                ) :
                (
                    <StyledFlexBox>
                        <Button
                            onClick={ deleteChapter }
                            variant='contained'
                            size='large'
                            color='secondary'
                        >
                            Delete
                        </Button>
                        <Button
                            variant='contained'
                            size='large'
                            color='primary'
                            onClick={ updateChapter }
                        >
                            Update
                        </Button>
                    </StyledFlexBox>
                ) }
        </StyledLayout>
    )
}