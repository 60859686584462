import { FC, useState, useMemo, MouseEvent, useContext, useEffect } from 'react';
import { getComparator } from "../../../utils/tableUtils";
import { TableBody, TableCell } from '@mui/material';
import { createLink } from "../../../utils";
import { EditIcon, DeleteIcon } from '../../../assets';
import { DeleteModal } from "../../DeleteModal";
import { ROUTES } from "../../../app";

import {
    StyledBox,
    StyledPaper,
    StyledTableContainer,
    StyledTable,
    StyledTableRow,
    StyledNewsInfo,
    StyledAvatar,
    StyledNewsName,
    StyledNewsInfoAtModal,
} from './styledComponents';
import { BookInsightsTableHead } from './BookInsightsTableHead';
import { StatusBadge } from './StatusBadge';
import { StyledSwitch, TableCardSkeleton } from "../../../UI";
import {
    useDeleteBookInsightsItem,
    useGetBookInsights,
    useUpdateBookInsightsItem
} from "../../../hooks/useBookInsights";
import { IBookInsights } from "../../../types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocales } from "../../../hooks/useLocales";

export const BookInsightsTable: FC = () => {
    const {locale, slug} = useSelector((state: any) => state.locales);

    const navigate = useNavigate()

    const [data, setData] = useState([]);
    const [ order, setOrder ] = useState<any>('asc');
    const [ orderBy, setOrderBy ] = useState<keyof any>('name');
    const [ openDeleteModal, setOpenDeleteModal ] = useState<boolean>(false);
    const [ selectedNewsItem, setSelectedNewsItem ] = useState<
        any | undefined
    >(undefined);
    const {data: insightsList, isLoading, isFetching}: any = useGetBookInsights(slug);
    const { mutateAsync: deleteBookInsights,} = useDeleteBookInsightsItem();
    const { mutateAsync: updateBookInsight,} = useUpdateBookInsightsItem();

    const [isActiveList, setIsActiveList] = useState<any[]>([])

    const deleteButtonHandler = (row: any) => {
        setSelectedNewsItem(row);
        setOpenDeleteModal(true);
    };

    const handleRequestSort = (
        _event: MouseEvent<unknown>,
        property: keyof IBookInsights,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(() => {
        return data?.sort(getComparator(order, orderBy));
    }, [data, order, orderBy]);

    const openEditDrawer = (
        id: string | number,
    ) => {
        navigate(
            createLink(ROUTES.editBookInsights, id, 'id', ),
        );
    };

    const getTime = (time: any) => {
        if (Number.isInteger(time / 60)){
            return `${time / 60} min`
        }
        else if (time < 60) {
            return `${time} seconds`
        }
        else {
            return `${parseInt((time / 60).toString())} min ${time % 60 } sek`
        }
    }

    const deleteInsights = (id: number) => {
        deleteBookInsights(id)
          .then(() => {
              setOpenDeleteModal(false)
          })
          .catch(() => {
          });
    };


    const changeActiveStatus = (id: number) => {
        const updatedList = isActiveList?.map((
            item,
            idx
          ) =>
            idx == id ? {...item, is_active: item.is_active === 1 ? 0 : 1} : item
        )

        const requestData: any = insightsList.map((item: any, idx: number) =>
          idx == id ? {...item, is_active: item.is_active === 1 ? 0 : 1 } : item
        )

        setIsActiveList(updatedList)

        updateBookInsight(requestData[id])
          .then(() => {
          })
          .catch(() => {
          });
    }

    useEffect(() => {
        const sortedList = insightsList?.filter((item: any) => item.locale_id == locale)

        setData(sortedList)
    }, [locale, insightsList])

    useEffect(() => {
        const updatedList = insightsList?.map((
          item: any,
          idx: number,
        ) => {
            return {
                number: idx,
                is_active: item?.is_active
            }
        })

        setIsActiveList(updatedList)

    }, [insightsList])

    return (
        <StyledBox>
            <DeleteModal
                open={ openDeleteModal }
                title='Delete Book Insight'
                description='Are you sure to delete book insight?'
                onConfirm={ () => deleteInsights(selectedNewsItem.id) }
                onClose={ () => setOpenDeleteModal(false) }
            >
                {/*<StyledNewsInfo>*/}
                {/*    <StyledAvatar*/}
                {/*      onClick={ () =>*/}
                {/*        ''*/}
                {/*      }*/}
                {/*    >*/}
                {/*        <div style={ {backgroundColor: selectedNewsItem.cover_hex} }*/}
                {/*             className={ 'image' }>*/}

                {/*            <img src={selectedNewsItem.cover} alt="" />*/}
                {/*        </div>*/}
                {/*    </StyledAvatar>*/}
                {/*    <StyledNewsName*/}
                {/*      onClick={ () =>*/}
                {/*        ''*/}
                {/*      }*/}
                {/*    >*/}
                {/*        { selectedNewsItem.title }*/}
                {/*    </StyledNewsName>*/}
                {/*</StyledNewsInfo>*/}
            </DeleteModal>
            <StyledPaper>
                <StyledTableContainer>
                    <StyledTable aria-labelledby='newsTable' size='medium' stickyHeader>
                        <BookInsightsTableHead
                            order={ order }
                            orderBy={ orderBy }
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody
                        >
                            {isLoading && [1,2,3, 5, 6, 7, 8, 9, 10].map((item, idx) => (
                              <StyledTableRow  hover key={ idx }>
                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>

                                  <TableCell>
                                      <TableCardSkeleton/>
                                  </TableCell>
                              </StyledTableRow>
                            ))}

                            {!isLoading && visibleRows && visibleRows?.map((row: any, idx: number) => {
                                const is_active_value = isActiveList?.find((item) => item.number == idx)?.is_active

                                return (
                                  <StyledTableRow
                                    hover key={ row.id }
                                  >
                                      <TableCell
                                        component='th'
                                        id={ row.id.toString() }
                                        scope='row'
                                      >
                                          <StyledNewsInfo>
                                              <StyledAvatar
                                                onClick={ () =>
                                                  ''
                                                }
                                              >
                                                  <div style={ {backgroundColor: row.cover_hex} }
                                                       className={ 'image' }>

                                                      <img src={row.cover} alt="" />
                                                  </div>
                                              </StyledAvatar>
                                              <StyledNewsName
                                                onClick={ () =>
                                                  ''
                                                }
                                              >
                                                  { row.title }
                                              </StyledNewsName>
                                          </StyledNewsInfo>
                                      </TableCell>
                                      <TableCell>
                                          <p>{ row.workspace.name }</p>
                                      </TableCell>
                                      <TableCell>
                                          <StatusBadge is_active={ true } />
                                          <p>{ row.publication_at }</p>
                                      </TableCell>
                                      <TableCell>
                                          <p> {row.reading_time} min</p>
                                      </TableCell>
                                      <TableCell>
                                          <p>{ row.rating }</p>
                                      </TableCell>
                                      <TableCell>
                                          <div className='editDeleteIcons'>
                                              <div  className={'toggle'}>
                                                  <StyledSwitch
                                                    checked={is_active_value}
                                                    onChange={ () => changeActiveStatus(idx) }
                                                  />
                                              </div>
                                              {/*<StyledSwitch*/}
                                              {/*    variant={ 'customGray' }*/}
                                              {/*    checked={ true }*/}
                                              {/*    onChange={ () => ('') }*/}
                                              {/*/>*/}

                                              <EditIcon
                                                style={ {marginRight: '12px'} }
                                                color={ '#ffffff' }
                                                onClick={() =>
                                                  openEditDrawer(row.id)
                                                }
                                              />
                                              <DeleteIcon
                                                color={ '#ffffff' }
                                                onClick={ () => deleteButtonHandler(row) }
                                              />
                                          </div>
                                      </TableCell>
                                  </StyledTableRow>
                                );
                            }) }

                        </TableBody>
                    </StyledTable>
                </StyledTableContainer>
                {/*<Pagination count={pageCount} />*/ }
            </StyledPaper>
        </StyledBox>
    );
};
