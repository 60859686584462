import { AddEditLocalesDrawer, Layout, LocalesTable } from "../../components";
import { useDrawer } from "../../hooks/useDrawer";
import { LocalesHeader } from "../../components/Locales/LocalesHeader/LocalesHeader";
import { useMemo, useState } from "react";
import { useLocales } from "../../hooks/useLocales";

export const Locales = () => {
    const [localSearchValue, setLocalSearchValue] = useState<string>('');
    const {data: locales, isLoading} = useLocales();

    const visibleLocales: any= useMemo(() => {
        if (!localSearchValue) return locales;
        return locales && locales.filter(
            (workspace: any) =>

                workspace.name
                    .toLowerCase()
                    .includes(localSearchValue.toLowerCase()) ||
                workspace.name.toLowerCase().includes(localSearchValue.toLowerCase()),
        );
    }, [localSearchValue, locales]);

    const {
        openAddDrawer,
        editDrawerId,
        purpose,
        hideAddEditDrawer,
        showAddEditDrawer,
    } = useDrawer();

    return (
        <Layout>
            <AddEditLocalesDrawer
                locales={visibleLocales}
                open={showAddEditDrawer}
                onClose={hideAddEditDrawer}
                purpose={purpose}
                id={editDrawerId}
            />
            <LocalesHeader openAddSpaceModal={openAddDrawer} setLocalSearch={setLocalSearchValue} localSearchValue={localSearchValue}/>

            <LocalesTable  locales={visibleLocales} isLoading={isLoading}/>
        </Layout>
    )
}