import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { COLORS } from "../../../utils";

export const StyledBox = styled(Box) ({
    padding: '0 1rem 2rem',
})



export const StyledUserInfo = styled(Box) ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
    '& img': {
        width: '5rem',
        height: '5rem',
        borderRadius: '50%',
        marginBottom: '0.5rem',
        background: COLORS.WHITE,
        objectFit: 'cover',
        objectPosition: 'center',
    },

    '& .noneImage': {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        width: '5rem',
        height: '5rem',
        borderRadius: '50%',
        marginBottom: '0.5rem',
        background: COLORS.LIGHT_BLUE,
        textTransform: "uppercase",
        fontSize: '28px',
        fontWeight: 700
    }
})

export const StyledUserName = styled(Box)({
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
});

export const StyledUserRole = styled(Box)({
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    opacity: 0.5,
});