import { FC } from "react";
import { Button, PageHeader } from "../../../UI";
import { ROUTES } from "../../../app";
import { PlusIcon } from "../../../assets";
import { useDrawer } from "../../../hooks/useDrawer";

interface IAdminsHeader {
    openAddSpaceModal: () => void
}

export const AdminsHeader: FC<IAdminsHeader> = ( {
    openAddSpaceModal
}) => {

    return (
        <PageHeader title={'Admins'}>
            <Button
                startIcon={<PlusIcon/>}
                color='primary'
                size='large'
                onClick={openAddSpaceModal}
            >
                Add
            </Button>
        </PageHeader>
    )
}