import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { PageTitle } from '../index';

import classes from './PageHeader.module.scss';

interface IPageHeader {
  title: string;
  children?: ReactNode;
  hasMiddleComponent?: boolean;
  middleComponent?: ReactNode;
}

export const PageHeader: FC<IPageHeader> = ({
  title,
  hasMiddleComponent,
  middleComponent,
  children,
}) => {
  return (
    <Box className={classes.pageHeader}>
      <PageTitle title={title} />
      {hasMiddleComponent && <Box>{middleComponent}</Box>}
      <Box className={classes.pageHeader__controls}>{children}</Box>
    </Box>
  );
};
