import { FC, ChangeEvent } from 'react';
// @ts-ignore
import InputMask from "react-input-mask";
import { TextField, Box } from '@mui/material';
import { Label } from "react-query/types/devtools/Explorer";

interface IUsernameInput {
    value: string;
    onChange: (_e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    isError?: boolean;
    errorMessage?: string;
}


const styles = {
    marginBottom: '1rem',
    border: 'none',

    '& .label': {
        fontWeight: 400,
        fontSize: '0.75rem',
        marginBottom: '0.3rem'
    },

    '.MuiInputLabel-shrink': {
        display: 'none'
    },

    '& .textField': {
        background: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '0.5rem',

        '&:hover' : {
            background: 'rgba(0, 0, 0, 0.05)'
        }
    },

    fieldset: {
        border: 'none',
    },

    label: {
        color: '#000',
        lineHeight: '1.75rem',
        opacity: '0.5',
        fontWeight: '400',
    },

    input: {
        color: '#000',
        lineHeight: '1.75rem',
        fontWeight: '400',
    }
}

export const UsernameInput: FC<IUsernameInput> = ({
    value,
    onChange,
    disabled,
    isError,
    errorMessage,
}) => {
    const renderTextField = () => (
        <TextField
            className={'textField'}
            label= {'Enter the username'}
            variant='outlined'
            fullWidth
            error={isError}
            helperText={errorMessage}
            disabled={disabled}
            onChange={onChange}
        />
    );
    return (
        <Box className={'textField'} sx={styles}>
            <div className={'label'}>
                Login
            </div>
            <InputMask
                maskPlaceholder=''
                disabled={disabled}
                value={value}
                onChange={onChange}
            >
                {() => renderTextField()}
            </InputMask>
        </Box>
    );
};
