import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { COLORS } from "../../../utils";

export const WorkspaceCardWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.5rem',
    backgroundColor: COLORS.GRAY,
    padding: '1.875rem',
    zIndex: -1,
    width: '100%',
    height: '100%',
    '& .logoBox': {
        width: '100%',
        marginBottom: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        '& .logo': {
            position: 'relative',
            width: '5rem',
            height: '5rem',
            borderRadius: '50%',
            overflow: 'hidden',
            backgroundColor: COLORS.DARK_BLUE,
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
            },
            '& p': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '1.75rem',
                lineHeight: '1.75rem',
                fontWeight: 600,
                color: '#2CB5B4'
            }
        },

        '& .logo.drafted': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#505558 !important',
        }
    },
    '& h2': {
        fontSize: '1.75rem',
        lineHeight: '1.75rem',
        fontWeight: 600,
        margin: '0 0 1rem',
        cursor: 'pointer',
    },

    '& p': {
        color: 'rgba(255, 255, 255, 0.80)',
        fontSize: '1rem',
        lineHeight: '1.375rem',
        fontWeight: 400,
    },

    '& .button':{
        width: 'fit-content',
        background: 'linear-gradient(91deg, #2CB5B4 0%, #6ABD78 100%)'
    },

    '& .addDetail': {
        background: '#C69854 !important',
    },

    '& .buttons': {
        display: 'flex',
        alignItems: 'center',
        gap: '1.25rem',
        marginTop: '1rem',
    },

    '& .separator': {
        marginTop: 'auto'
    },

     '& .toggle': {
        position: 'relative',
        zIndex: 1,
        marginRight: -20,

        // "& .MuiSwitch-root": {
        //     width: '67px',
        //     height: '45px'
        // }
    }
});

export const StyledHr = styled(Box)({
    width: '100%',
    height: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginBottom: '1rem',
});
