import { FC, ChangeEvent } from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/system';

import { COLORS } from '../../utils';

const StyledFormControl = styled(FormControl)(
  ({
    size,
    formControlStyles,
  }: {
    size: 'small' | 'medium';
    formControlStyles: any;
  }) => ({
    borderRadius: '0.5rem',
    backgroundColor: COLORS.WHITE,
    width: size === 'medium' ? '20rem' : '15rem',
    border: `1px solid ${COLORS.GRAY}`,
    ...formControlStyles,
  }),
);

const StyledTextField = styled(TextField)(
  ({ textFieldStyles }: { textFieldStyles?: any }) => ({
    ...textFieldStyles,
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',
      backgroundColor: textFieldStyles?.backgroundColor || COLORS.WHITE,
      '& fieldset': {
        border: `1px solid ${COLORS.WHITE}`,
      },
      '&:hover fieldset': {
        border: `1px solid ${COLORS.WHITE}`,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${COLORS.SKY_BLUE}`,
      },
    },
    '& .MuiInputBase-root': {
      height: textFieldStyles?.height || 44,
    },
    '& .MuiInputBase-input': {
      fontSize: textFieldStyles?.fontSize || 'inherit',
    },
  }),
);

interface ISelectInput<T> {
  options: T[];
  value: string | number;
  onChange: (_value: string | number) => void;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
  size?: 'small' | 'medium';
  textFieldStyles?: any;
  formControlStyles?: any;
  disabled?: boolean;
}

export interface IOption {
  label: string;
  value: string | number;
}

export const SelectInput: FC<ISelectInput<IOption>> = ({
  options,
  value,
  onChange,
  label,
  placeholder,
  fullWidth,
  variant = 'outlined',
  size = 'medium',
  textFieldStyles,
  formControlStyles,
  disabled = false,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };
  return (
    <StyledFormControl
      fullWidth
      size={size}
      formControlStyles={formControlStyles}
    >
      <StyledTextField
        value={value}
        select
        onChange={handleChange}
        textFieldStyles={textFieldStyles}
        label={label}
        placeholder={placeholder}
        variant={variant}
        fullWidth={fullWidth}
        disabled={disabled}
      >
        {options.map(option => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledTextField>
    </StyledFormControl>
  );
};
